import Vue from 'vue'

// Meta-tags
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  keyName: 'page',
})

// Analytics
import '@src/metrics'
import GAPlugin from './plugins/ga'
import TMRPlugin from './plugins/tmr'

Vue.use(GAPlugin)
Vue.use(TMRPlugin)

// Setup
Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      loadingState: {
        initial: true,
      },
      componentMounted: false,
    }
  },
  computed: {
    ...mapState('application', {
      $timestamp: 'timestamp',
      $appWidth: 'appWidth',
      $scrollTop: 'scrollTop',
      $availableLocales: 'locales',
    }),
    ...mapGetters('application', {
      $appSize: 'appSize',
      $appIsSM: 'appIsSM',
      $appIsMD: 'appIsMD',
      $appIsLG: 'appIsLG',
      $appIsXL: 'appIsXL',
      $appLocale: 'appLocale',
      $appLocalesList: 'appLocalesList',
      $getLocale: 'getLocale',
    }),
  },
  methods: {
    $asAppAsset(url) {
      return `${process.env.VUE_APP_ASSETS_PATH}${url}`
    },
    $tooltip(content, placement = 'top', show = false, trigger) {
      return {
        content,
        placement,
        show,
        trigger,
      }
    },
  },
})
