<template>
  <div class="data-grid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataGrid'
}

</script>

<style scoped lang="scss">
.data-grid {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: $color-dark;

  @media (min-width: 720px) {
    display: table;
  }

  ::v-deep {
    .data-grid__row:nth-child(even) {
      background-color: $color-light-gray;
    }
  }
}
</style>
