<style lang="scss" scoped>
  .tabs {
    text-align: center;
    overflow: hidden;
    @include margin-x(-$gutter);

    @include media(max, md) {
      @include margin-x(-$gutter);
    }

    .tabs-wrapper {
      margin-bottom: -20px;
      display: flex;
      white-space: nowrap;
      overflow: auto;
      cursor: grab;
      padding-bottom: 20px;
      padding-left: $gutter;

      &:active {
        cursor: grabbing;
      }

      &:after {
        content: '';
        display: block;
        width: $gutter;
        flex: 0 0 auto;

        @include media(max, md) {
          width: $gutter;
        }
      }

      @include media(max, md) {
        padding-left: $gutter;
      }
    }

    .tab + .tab {
      margin-left: 20px;
    }

    .tab {
      display: flex;
      align-items: center;
      position: relative;
      @include padding-y(6px);
      cursor: pointer;
      white-space: nowrap;
      color: #838383;

      .tab-icon {
        font-size: 16px;
      }

      .tab-icon + .tab-title {
        margin-left: 10px;
      }

      .tab-title {
        font-weight: 600;
        text-transform: uppercase;
      }

      &:hover {
        .tab-title {
          color: var(--theme-color);
        }
      }

      &:after {
        position: absolute;
        z-index: 2;
        content: '';
        bottom: -1px;
        left: 0;
        right: 0;
        height: 3px;
        opacity: 0;
        transition: opacity .2s;
      }

      &.active {
        color: #191919;

        &:after {
          opacity: 1;
          @include theme-gradient;
        }
      }
    }
  }
</style>

<template>
  <div class="tabs">
    <div class="tabs-wrapper hidden-scroll">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="tab.classes" @click="onTabClick(tab.key)">
        <icon v-if="tab.icon" class="tab-icon" :name="tab.icon" theme gradient/>
        <div v-if="tab.title" class="tab-title">{{ tab.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tabs',
  props: {
    value: {
      type: [Number, String],
      default: -1,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tabs() {
      return this.list.map((tab, index) => {
        const key = tab.key || index

        return {
          ...tab,
          key,
          classes: {
            active: this.value === key,
          },
        }
      })
    },
  },
  methods: {
    onTabClick(key) {
      this.$emit('input', key)
    },
  },
}
</script>
