import Vue from 'vue'
import VueI18n from 'vue-i18n'
import languages from './languages'

Vue.use(VueI18n)

VueI18n.prototype.supportedLanguages = languages

const i18n = new VueI18n({
  fallbackLocale: 'en_US',
  silentFallbackWarn: PRODUCTION_MODE,
  pluralizationRules: {
    ru_RU: function(choice, choicesLength) {
      const n = choice
      return (n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
    }
  }
})

export { i18n, languages }
