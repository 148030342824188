<style scoped lang="scss">
  .success-sub-page {
    .message-section {
      padding-top: 90px;
      padding-bottom: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media(max, sm) {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      .message-image {
        img {
          max-width: 250px;
          max-height: 250px;
        }
      }

      .message-icon {
        .icon {
          height: 60px;
          width: 60px;
        }
      }

      .message-text {
        margin-top: 30px;
        text-align: center;

        .title-text {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .info-text {
          font-size: 13px;
          line-height: 16px;
          color: #7E8999;
          max-width: 75%;
          margin: 0 auto;
        }
      }

      .message-links {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .button + .button {
          margin-left: 15px;
        }
      }
    }
  }
</style>

<template>
  <div class="success-sub-page">
    <div class="content-block">
      <content-block-controls/>
      <div class="content-block-body">
        <div class="message-section">
          <div class="message-icon">
            <icon name="check-circle" theme gradient :inline="false"/>
          </div>
          <div class="message-text">
            <div class="title-text">{{ $t('askMe.success.thanks') }}</div>
            <div class="info-text">{{
                $t('askMe.success.questionSent', { name: getCreatorName }) 
              }}
            </div>
          </div>
          <div class="message-links">
            <btn
              variant="primary"
              size="small"
              @click="backLink"
            >{{ $t('askMe.success.sendAnother') }}
            </btn>
          </div>
        </div>
      </div>
      <content-block-footer/>
    </div>
  </div>
</template>

<script>

import ContentBlockControls from '@components/AskMePage/ContentBlockControls'
import ContentBlockFooter from '@components/AskMePage/ContentBlockFooter'

export default {
  name: 'SuccessSubPage',
  components: {
    ContentBlockFooter,
    ContentBlockControls,
  },
  mounted() {
    this.$ga.event({
      category: 'AskMeForm',
      action: 'question_sent',
    })

    this.$tmr.goal({ goal: 'event_question_sent' })
  },
  computed: {
    ...mapGetters('creator', ['getCreatorName']),
  },

  methods: {
    backLink() {
      this.$router.push({
        name: 'askme-page',
      })
    },
  },
}
</script>
