<style lang="scss">
  @import '~@assets/scss/fantalks';

  #fantalks-app {
    height: 100%;

    .system-svg {
      height: 0;
      width: 0;
      position: fixed;
    }

    .cookies-warning {
      @include position(fixed, auto, 0, 0, 0);
      z-index: 1000;
    }

    .container {
      width: 100%;
      @include margin-x(auto);
      min-width: map_get($container, min);
      max-width: map_get($container, max);

      @include padding-x($gutter-mobile);

      @include media(min, md) {
        @include padding-x($gutter);
      }
    }
  }

  #_1337block-authorized-apps,
  #_1337block-connections,
  #_1337block-black-list,
  #_1337block-token-container,
  #delete-account {
    display: none !important;
  }
</style>

<template>
  <div id="fantalks-app">
    <router-view/>

    <svg class="system-svg">
      <defs>
        <linearGradient id="_system_gradient_1" x1="0" x2="0.9055797876726389" y1="0" y2="1.079227965339569">
          <stop offset="0%" stop-color="#F57507"/>
          <stop offset="100%" stop-color="#F59C07"/>
        </linearGradient>
        <linearGradient id="_theme_gradient" x1="0" x2="0.9055797876726389" y1="0" y2="1.079227965339569">
          <stop offset="0%" :stop-color="gradient.from"/>
          <stop offset="100%" :stop-color="gradient.to"/>
        </linearGradient>
        <linearGradient id="base-gradient" x1="0" x2="0.9055797876726389" y1="0" y2="1.079227965339569">
          <stop offset="0%" stop-color="#6C71D3"/>
          <stop offset="100%" stop-color="#6C71D3"/>
        </linearGradient>
      </defs>
      <rect fill="url(#_system_gradient_a)" width="100%" height="100%"/>
    </svg>

  </div>
</template>

<script>

export default {
  metaInfo() {
    return {
      titleTemplate: `%s - FanTalks.io`,
    }
  },
  computed: {
    ...mapState('application', ['themeColors']),

    gradient() {
      return {
        from: this.themeColors['--theme-gradient-color-from'],
        to: this.themeColors['--theme-gradient-color-to'],
      }
    },
  },
}
</script>
