<style scoped lang="scss">
  .questions {
    position: relative;
    display: flex;
    flex-direction: column;

    .questions-list {
      @include media(max, sm) {
        @include margin-x(-20px);
      }

      .question + .question {
        margin-top: 20px;
      }
    }

    .pagination {
      margin-top: 20px;
      @include margin-x(auto);
    }

    .loading-container {
      @include position-all(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(white, .5);
    }
  }
</style>

<template>
  <div class="questions">
    <div v-if="loadingState.questions" class="loading-container">
      <loader size="lg"/>
    </div>
    <template v-if="questionsTotal">
      <div class="questions-list">
        <question
          v-for="(questionId, index) in questions"
          :key="questionId"
          :id="questionId"
          :position="index"
          :top="top"/>
      </div>

      <pagination
        v-if="paginationVisible"
        v-model="page"
        @input="changePage"
        :disabled="loadingState.questions"
        :current-page="page"
        :pages-count="pagesCount"/>
    </template>
  </div>
</template>

<script>

import Question from './Question'

export default {
  name: 'Questions',
  components: { Question },
  props: {
    initialPage: {
      type: [Number, String],
      default: 1,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingState: {
        questions: true,
      },

      page: 1,
      pagesCount: 0,
      perPage: 10,

      questionsIds: [],
    }
  },
  computed: {
    ...mapState('askmePublic', ['questionsTotal', 'topQuestionsIds', 'filterCategoryId']),

    questions() {
      return this.top ? this.topQuestionsIds : this.questionsIds
    },

    paginationVisible() {
      return !this.top && this.pagesCount > 1
    },
  },
  methods: {
    ...mapActions('askmePublic', ['fetchQuestions']),

    scrollSliderTo(position = 'start') {
      this.$refs.slider.scrollLeft = {
        start: 0,
        end: 1000,
      }[position]
    },

    changePage() {
      this.$emit('pageChange', this.page)
    },

    loadQuestions() {
      if (this.top) {
        this.loadingState.questions = false
        return
      }

      this.loadingState.questions = true

      const perPage = this.top ? 3 : this.perPage
      const sort = this.top ? ['cost,desc'] : null

      this.fetchQuestions({ page: this.page, perPage, sort })
        .then(({ questions, pagination }) => {
          this.questionsIds = questions
          this.page = pagination.page
          this.pagesCount = pagination.pagesCount
        })
        .finally(() => {
          this.loadingState.questions = false
        })
    },
  },
  watch: {
    page: 'loadQuestions',
    filterCategoryId() {
      if (this.page !== 1) {
        this.page = 1
      } else {
        this.loadQuestions()
      }
    },
  },
  created() {
    this.page = this.initialPage
    this.loadQuestions()
  },
  mounted() {
    this.$ga.view({
      category: 'AskMeQuestions',
      action: 'questions',
    })
  },
}
</script>
