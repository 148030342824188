<style scoped lang="scss">
  .askme-public-page {
    flex: 1 0 auto;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $background-color;

    @include media(max, sm) {
      @include padding-y(0px);
    }

    @include media(max, lg) {
      padding-bottom: 80px;
    }

    .page-wrapper {
      @include padding-y(20px);
      position: relative;

      .question-form-button {
        position: fixed;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: none;
        z-index: 5;

        @include media(max, lg) {
          display: block;
        }

        .button {
          width: 100%;
        }
      }

      .page-content {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;

        @include media(max, sm) {
          margin-top: 10px;
        }

        .view-wrapper {
          flex: 1 0 auto;
          width: 750px;

          .ask-me-tutorial {
            margin-bottom: 20px;
          }

          @include media(max, xl) {
            width: 644px;

            &.short {
              width: 750px;
            }
          }

          @include media(max, lg) {
            width: 100%;

            &.short {
              width: 100%;
            }
          }
        }

        .view-column {
          align-self: stretch;
          flex: 0 0 auto;
          margin-left: 20px;
          width: 320px;

          @include media(max, lg) {
            display: none;
          }

          .question-form-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;

            .question-form {
              position: sticky;
              top: 0;
            }

            &:after {
              content: '';
              flex-grow: 1;
            }
          }
        }
      }

      &.short {
        @include margin-x(auto);
        max-width: 750px;
      }

      .not-available {
        margin-top: 20px;
        padding-top: 90px;
        padding-bottom: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(max, sm) {
          padding-top: 40px;
          padding-bottom: 40px;
        }

        .not-available-image {
          img {
            max-width: 250px;
            max-height: 250px;
          }
        }

        .not-available-text {
          margin-top: 30px;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .mobile-tutorial {
      padding: 10px 0 14px 20px;
      margin-top: 10px;
      background-color: white;
      box-shadow: 0 5px 20px 0 rgba(black, .05);
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 113px;
        height: 113px;
        position: absolute;
        right: 10px;
      }
    }

    .question-form-modal {
      @include media(min, lg) {
        display: none;
      }

      .question-form {
        width: 100%;

        @include media(min, sm) {
          width: 320px;
        }
      }
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    .icon {
      position: absolute;
      font-size: 50px;
    }

    .loader {
      position: absolute;
    }
  }
</style>

<template>
  <div class="askme-public-page">
    <shutdown-message/>
    <container>
      <transition name="fade-up">
        <div class="page-wrapper" v-if="!loadingState.initial" :class="{short: !showQuestionForm}">
          <page-header/>

          <template v-if="isAvailable">
            <template v-if="mobileTutorialVisible">
              <div class="mobile-tutorial" @click="showMobileTutorial">
                <span class="text-link dashed">{{ $t('askMe.tutorial.title') }}</span>
                <img :src="$asAppAsset('/images/mobile-tutorial.svg')"/>
              </div>
            </template>

            <div class="page-content" :class="{short: !showQuestionForm}">
              <div class="view-wrapper" :class="{short: !showQuestionForm}">
                <template v-if="desktopTutorialVisible">
                  <ask-me-tutorial @close="onTutorialClose"/>
                </template>
                <router-view/>
              </div>
              <div v-if="desktopQuestionFormVisible" class="view-column">
                <div class="question-form-wrapper">
                  <question-form/>
                </div>
              </div>
            </div>

            <div class="question-form-button" v-if="mobileButtonIsVisible && !isSuccessPage()">
              <btn
                variant="primary"
                @click="showQuestionFormModal">{{ $t('askMe.questionForm.send') }}
              </btn>
            </div>
          </template>
          <template v-else>
            <div class="not-available content-block">
              <div class="not-available-image">
                <img :src="$asAppAsset('/images/da-wtf.png')"/>
              </div>
              <div class="not-available-text">
                {{ $t('askMe.app.notAvailable') }}
              </div>
            </div>
          </template>
        </div>
      </transition>
    </container>

    <div v-if="loadingState.initial" class="loading-container">
      <loader size="xl" system gradient/>
      <icon name="social-ft" system gradient class="animated pulse infinite"/>
    </div>

    <template v-if="isAvailable">
      <template v-if="mobileQuestionFormVisible">
        <modal class="question-form-modal" v-model="mobileFormOpened">
          <template slot="body">
            <question-form class="transparent"/>
          </template>
        </modal>
      </template>

      <template v-if="mobileTutorialVisible">
        <modal class="tutorial-modal" v-model="mobileTutorialOpened">
          <template slot="body">
            <ask-me-tutorial class="transparent"/>
          </template>
        </modal>
      </template>
    </template>
  </div>
</template>

<script>

import PageHeader from '@components/AskMePage/PageHeader'
import QuestionForm from '@components/AskMePage/QuestionForm/QuestionForm'
import AskMeTutorial from '@components/AskMePage/AskMeTutorial'
import storage from '@utils/storage'
import ShutdownMessage from '@components/ShutdownMessage'

const STORAGE_APP_TUTORIAL = 'da.askme.tutorial'

export default {
  name: 'AskmePublicPage',
  components: {
    ShutdownMessage,
    AskMeTutorial,
    QuestionForm,
    PageHeader,
  },
  props: {
    slug: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      mobileFormOpened: false,

      mobileTutorialOpened: false,

      // desktopTutorialViewed: !!storage.getItem(STORAGE_APP_TUTORIAL),
      desktopTutorialViewed: true,

      // mobileButtonIsVisible: true,
      mobileButtonIsVisible: false,
    }
  },
  computed: {
    ...mapState('askmePublic', ['isAvailable']),
    ...mapFields('viewer', ['isLandingPage']),

    showTutorial() {
      return this.$route.meta.tutorial
    },

    mobileTutorialVisible() {
      return this.showTutorial && this.$appIsSM
    },

    desktopTutorialVisible() {
      return this.showTutorial && !this.$appIsSM && !this.desktopTutorialViewed
    },

    showQuestionForm() {
      return this.$route.meta.questionForm
    },

    mobileQuestionFormVisible() {
      return this.showQuestionForm && (!this.$appIsXL)
    },

    desktopQuestionFormVisible() {
      // return this.showQuestionForm && this.$appIsXL
      return false
    },
  },
  methods: {
    ...mapActions('creator', ['fetchAskMePageSettings']),
    ...mapActions('currencies', ['fetchCurrencies']),
    ...mapActions('askmePublic', ['fetchTopQuestions', 'fetchCategories']),
    ...mapActions('viewer', ['login']),

    onTutorialClose() {
      storage.setItem(STORAGE_APP_TUTORIAL, 1)
      this.desktopTutorialViewed = true
    },

    showMobileTutorial() {
      this.mobileTutorialOpened = true
    },

    showQuestionFormModal() {
      // this.mobileFormOpened = true
    },

    hideQuestionFormModal() {
      // this.mobileFormOpened = false
    },

    showMobileButton() {
      // this.mobileButtonIsVisible = true
    },

    hideMobileButton() {
      // this.mobileButtonIsVisible = false
    },

    isSuccessPage() {
      return this.$router.history.current.name === 'success-sub-page'
    },
  },
  async created() {
    this.isLandingPage = false

    // await this.login()

    const promises = [
      this.fetchAskMePageSettings(this.slug),
      this.fetchCurrencies(),
    ]

    Promise.all(promises)
      .then(() => {
        Promise.all([
          this.fetchCategories(),
          this.fetchTopQuestions(),
        ])
          .then(() => {
            this.loadingState.initial = false
          })
      })
  },
  mounted() {
    this.$ga.event('event_askme_init')

    this.$tmr.goal({ goal: 'event_askme_init' })
  },

  watch:{
    '$route' (to, from) {
      if (from.name === 'askme-page' && to.name === 'payment-sub-page') {
        this.hideQuestionFormModal()
        this.hideMobileButton()
      } else if (from.name === 'payment-sub-page' && to.name === 'askme-page') {
        this.showQuestionFormModal()
        this.showMobileButton()
      }
    },
  }
}
</script>
