import * as Sentry from '@sentry/browser'

const STORAGE_AUTH_TOKEN = 'da.auth.token'

export const state = {
  token: null,

  socketToken: window.token_centrifugo_connect,

  profile: null,

  profileTimezone: window.document_timezone,
}

export const getters = {
  token: state => state.token,

  getCurrentProfile: state => state.profile,

  getUserCurrency: state => _.get(state.profile, 'main_currency', 'RUB'),

  getUserRoles: state => _.get(state.profile, 'roles', []),
  getUserHasRole: (state, getters) => role => getters.getUserRoles.includes(role),
  getUserIsStreamer: (state, getters) => getters.getUserHasRole('streamer'),
  getUserIsViewer: (state, getters) => getters.getUserHasRole('viewer'),

  getUserBalance: (state, getters, rootState, rootGetters) => {
    const balanceAmount = _.get(state.profile, 'balance_in_main_currency', 0)

    return rootGetters['currencies/getAmountWithCurrency'](balanceAmount, getters.getUserCurrency)
  },

  getCredentials: state => ({
    userId: state.profile.id,
    token: state.token,
    centrifugoToken: state.socketToken,
  }),

  getBlackListWords: state => state.profile.black_list_words ?? [],

  getTimezone: state => state.profileTimezone || dates.DEFAULT_TIME_ZONE,
}

export const mutations = {
  SET_PROFILE(state, data) {
    state.profile = data

    state.profileTimezone = data.timezone
  },

  SET_USER_TOKEN(state, token) {
    state.token = token
  },
}

export const actions = {
  // login({
  //   commit,
  //   dispatch,
  // }) {
  //   return dispatch('authenticate')
  //     .then(() => {
  //       return dispatch('fetchCurrentUser')
  //     })
  //     .catch(() => {
  //       commit('SET_USER_TOKEN', null)
  //       commit('SET_VIEWER_INFO', null)
  //
  //       return false
  //     })
  // },

  authenticate({ commit }) {
    return api
      .get('/session/token', {
        spa_page: 'askme',
      }, { proxyRequest: false })
      .then(({ data }) => {
        return commit('SET_USER_TOKEN', data.api_token)
      })
  },

  fetchWidgetProfile({ commit }) {
    return api.get('/user/widget')
      .then(({ data }) => {
        commit('SET_PROFILE', data)

        Sentry.setUser({ username: data.code, id: data.id })

        return data
      })
  },

  fetchCurrentProfile({ commit, dispatch }) {
    return api.get('/user')
      .then(({ data }) => {
        commit('SET_PROFILE', data)

        dispatch('application/setLocale', data.language, { root: true })

        Sentry.setUser({ username: data.code, id: data.id })

        if (!!window.append1LinkAnalytics && data && data.id) {
          window.append1LinkAnalytics(data.id)
        }

        return data
      })
  },
}
