<style scoped lang="scss">
  .login-modal {
    .auth-form {
      max-width: 640px;
      @include padding-y(40px);
    }
  }
</style>

<template>
  <modal class="login-modal" v-model="loginModalOpened">
    <template slot="body">
      <auth-form/>
    </template>
  </modal>
</template>

<script>

import AuthForm from '@components/AuthForm'

export default {
  name: 'LoginModal',
  components: { AuthForm },
  computed: {
    ...mapFields('viewer', ['loginModalOpened']),
  },
}
</script>
