<style scoped lang="scss">
  .content-block-controls {
    padding: 20px 30px 10px 30px;
    display: flex;
    align-items: center;

    .title {
      margin-left: 17px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }

    @include media(max, sm) {
      flex-direction: column;
      align-items: flex-start;
      @include padding-x(20px);

      .title {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
</style>

<template>
  <div class="content-block-controls">
    <btn
      icon-left="angle-left"
      size="sm"
      @click="goToCreatorPage">
      {{ $t('global.app.toMainPage') }}
    </btn>
    <div v-if="title" class="title">
      {{title}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentBlockControls',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    goToCreatorPage() {
      this.$router.replace({
        name: 'askme-page',
      })
    },
  },
}
</script>
