<style scoped lang="scss">
  @mixin tag() {
    position: absolute;
    top: 0;
    margin-left: 5px;
    color: $orange;
    font-size: 8px;
    line-height: 1em;
    font-weight: 500;
  }

  .dashboard-menu {
    user-select: none;

    .menu-item {
      .menu-line {
        @include margin-y(5px);
        @include margin-x(8px);
        border-top: 1px solid rgba(white, .05);
        border-bottom: 1px solid rgba(black, .1);
      }

      .menu-item-link {
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        color: #7E8999;

        .menu-item-icon {
          flex-shrink: 0;
          font-size: 16px;
          color: $color-gray;
        }

        &.router-link-active {
          background-color: rgba(108, 113, 211, 0.2);
          color: $color-purple;

          .menu-item-icon {
            color: $color-purple;
          }
        }

        &:hover {
          text-decoration: none;
        }

        &:hover:not(.router-link-active) {
          color: #6C71D3;
        }

        .menu-item-text {
          flex-shrink: 0;
          margin-left: 12px;
          font-weight: 500;
          line-height: 16px;
          position: relative;
        }

        &.tag-beta .menu-item-text:after {
          content: "BETA";
          @include tag();
        }

        &.tag-new .menu-item-text:after {
          content: "NEW";
          @include tag();
        }
      }
    }
  }
</style>

<template>
  <div class="dashboard-menu">
    <div
      v-for="(menuItem, itemIndex) in menu"
      :key="itemIndex"
      class="menu-item">
      <template v-if="menuItem.type === 'line'">
        <div class="menu-line"/>
      </template>
      <template v-else-if="menuItem.href">
        <a :href="menuItem.href" target="_blank" class="menu-item-link">
          <icon
            class="menu-item-icon"
            :name="menuItem.icon"/>
          <span class="menu-item-text">{{ menuItem.title }}</span>
        </a>
      </template>
      <template v-else>
        <router-link
          :to="{name: menuItem.route, params: menuItem.params}"
          class="menu-item-link"
          :class="menuItem.classes"
          :exact="menuItem.exact"
          @click.native="onMenuItemClick({name: menuItem.route, params: menuItem.params})">
          <icon
            v-if="menuItem.icon"
            class="menu-item-icon"
            :name="menuItem.icon"/>
          <span class="menu-item-text">{{ menuItem.title }}</span>
        </router-link>
      </template>

    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardMenu',
  data() {
    return {}
  },
  computed: {
    ...mapState('dashboard', ['menuSettings']),

    menu() {
      return [
        {
          title: 'askMe.questions',
          route: 'dashboard-askme-questions',
          icon: 'fantalks/inbox',
        },
        {
          title: 'askMe.archive',
          route: 'dashboard-askme-archive',
          icon: 'fantalks/answered',
        },
        {
          title: 'askMe.categories',
          route: 'dashboard-askme-categories',
          icon: 'fantalks/message',
        },
        {
          title: 'askMe.settings',
          route: 'dashboard-askme-settings',
          icon: 'fantalks/settings',
        },
        {
          title: 'payouts',
          route: 'dashboard-payments',
          icon: 'fantalks/wallet',
        },
      ]
        .map(item => {
          if (item.title) {
            const translate = i => ({
              ...i,
              title: this.$t(`dashboard.pages.${i.title}`),
            })

            return translate(item)
          }

          return item
        })
    },
  },
  methods: {
    onMenuItemClick(route) {
      const nextRoute = this.$router.resolve(route)

      this.$ga.event({
        eventCategory: 'sidebar',
        eventAction: 'nav_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })

      this.$tmr.goal({
        goal: 'nav_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })
    },
  },
}
</script>
