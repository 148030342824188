<style scoped lang="scss">
  .balance-history-page {
    .pagination {
      margin-top: 30px;
      justify-content: center;
      display: flex;
    }

    .grid_disabled {
      opacity: .5;
    }

    .link {
      color: $color-purple;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>

<template>
  <div class="balance-history-page">
    <Title>{{$t('balanceHistory.balanceHistory')}}</Title>

    <loader
      size="md"
      v-if="isLoading && data.length === 0"
      style="margin: 10vh auto;"
    />

    <div
      v-if="data.length > 0"
      :class="{ 'grid_disabled': isLoading }"
    >
      <data-grid>
        <data-grid-header>
          <data-grid-col size="20">#</data-grid-col>
          <data-grid-col size="20">{{$t('balanceHistory.amount')}}</data-grid-col>
          <data-grid-col size="40">{{$t('balanceHistory.details')}}</data-grid-col>
          <data-grid-col size="20">
              <span
                class="link"
                @click="toggleSort"
              >{{ $t('balanceHistory.datetime') + (sort === 'desc' ? ' ↓' : ' ↑') }}</span>
          </data-grid-col>
        </data-grid-header>

        <data-grid-row
          v-for="(item, i) in data"
          :key="i"
        >
          <data-grid-col>
            <span style="opacity: .5">{{ item.model_id }}</span>
          </data-grid-col>
          <data-grid-col>
              <span
                :style="{color: item.amount > 0 ? '#2abd6a' : '#ff6161'}"
              >{{ (item.amount > 0 ? '+ ' : '- ') + getAmountWithCurrency(item.amount, item.currency) }}</span>
          </data-grid-col>
          <data-grid-col>
            <event-label :text="item.event" />
          </data-grid-col>
          <data-grid-col>
            <span style="opacity: .5">{{ transformDate(item.created_at_ts) }}</span>
          </data-grid-col>
        </data-grid-row>
      </data-grid>

      <pagination
        v-if="pagination.pagesCount && pagination.pagesCount > 1"
        v-model="page"
        :disabled="pastGoalsLoading"
        :current-page="page"
        :pages-count="pagination.pagesCount"
      />
    </div>
  </div>
</template>

<script>
import fromUnixTime from 'date-fns/fromUnixTime'
import Title from '@components/_BaseComponents/Title'
import DG from '@components/Dashboard/DataGrid'
import Event from '@components/Dashboard/BalanceHistory/Event'
import Pagination from '@components/_BaseComponents/Pagination'

export default {
  name: 'BalanceHistoryPage',
  components: {
    Title,
    EventLabel: Event,
    DataGrid: DG.DataGrid,
    DataGridHeader: DG.DataGridHeader,
    DataGridRow: DG.DataGridRow,
    DataGridCol: DG.DataGridCol,
    Pagination,
  },
  data() {
    return {
      page: 1,
      pastGoalsLoading: false,
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.balanceHistory'),
    }
  },
  watch: {
    page: {
      handler(newVal, oldVal) {
        if (oldVal) this.updateBalanceHistory()
      },
      immediate: true,
    },
  },

  created() {
    this.fetchBalanceHistory()
  },

  methods: {
    ...mapActions('balanceHistory', [
      'fetchBalanceHistory',
      'toggleSort',
    ]),

    async updateBalanceHistory() {
      const res = await this.fetchBalanceHistory({ page: this.page })
    },

    transformDate(val) {
      const timeZone = this.getTimezone

      const result = dates.unixToFormat(dates.utcToZonedTime(fromUnixTime(val), timeZone) / 1000, dates.DEFAULT_TIME_FORMAT)

      return result
    },
  },

  computed: {
    ...mapGetters('profile', [
      'getTimezone',
    ]),

    ...mapGetters('balanceHistory', [
      'sort',
      'isLoading',
      'data',
      'pagination',
    ]),

    ...mapGetters('currencies', [
      'getAmountWithCurrency',
    ]),
  },
}
</script>
