import axios from 'axios'
import store from '@state/store'

import frontConfig from '@services/frontConfig'

const mergeOptions = (options) => {
  const token = _.get(store, 'getters.profile/token', null)
  const locale = _.get(store, 'getters.application/appLocale', window.document_language)

  options = {
    crossdomain: true,
    baseURL: options.proxyRequest === false ? '/api/v1' : `${frontConfig.getFTHost()}/api/v1`,
    withCredentials: true,
    ...options,
    headers: {
      'Accept-Language': locale,
      ...options.headers,
    },
  }

  if (token) {
    _.set(options, 'headers.Authorization', `Bearer ${token}`)
  }

  return options
}

const transform = (response) => {
  const {
    code,
    message,
    errors,
  } = response.data

  if (code && message) {
    return Promise.reject({
      code,
      message,
      errors,
    })
  }

  return response.data
}

const parseError = (err) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('Request error: ', JSON.stringify(err))
  }

  throw err
}

const boolify = (data) => {
  const b = v => typeof v === 'boolean' ? Number(v) : v

  if (_.isObject(data)) {
    const newData = _.cloneDeep(data)

    Object.keys(newData).forEach(key => {
      newData[key] = b(newData[key])
    })

    return newData
  }

  return b(data)
}

class Api {
  get(url, params, options = {}) {
    return axios
      .get(url, mergeOptions({
        ...options,
        params: params ? boolify(params) : null,
      }))
      .then(transform)
      .catch(parseError)
  }

  post(url, data = {}, options = {}) {
    return axios
      .post(url, boolify(data), mergeOptions(options))
      .then(transform)
      .catch(parseError)
  }

  postFormData(url, data = {}, options = {}) {
    const formData = new FormData()

    data = boolify(data)

    Object.keys(data).forEach(key => {
      const dataField = data[key]

      if (dataField) {
        if (_.isObject(dataField) && !(dataField instanceof Blob)) {
          formData.append(key, JSON.stringify(dataField))
        } else {
          formData.append(key, dataField)
        }
      }
    })

    return axios
      .post(url, formData, mergeOptions({
        ...options,
        headers: {
          ...options.headers,
          'Content-Type': 'multipart/form-data',
        },
      }))
      .then(transform)
      .catch(parseError)
  }

  put(url, data = {}, options = {}) {
    return axios
      .put(url, boolify(data), mergeOptions(options))
      .then(transform)
      .catch(parseError)
  }

  delete(url, options = {}) {
    return axios
      .delete(url, mergeOptions(options))
      .then(transform)
      .catch(parseError)
  }
}

export default new Api()
