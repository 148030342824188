<style scoped lang="scss">
  .questions-page {
    .content-block + .content-block {
      margin-top: 20px;
    }

    .questions-category-filter + .questions {
      margin-top: 13px;
    }
  }
</style>

<template>
  <div class="questions-page">

    <template v-if="tabsVariant">
      <div class="content-block">
        <template v-if="questionsTotal || topQuestionsTotal">
          <div class="content-block-title">
            <tabs v-model="currentTab" :list="tabsOptions"/>
          </div>
          <div class="content-block-body">
            <questions-category-filter v-if="currentTab === 'latestQuestions'"/>

            <template v-if="currentTab === 'latestQuestions'">
              <questions
                v-show="!!questionsTotal"
                @pageChange="onQuestionsPageChange"/>
              <questions-placeholder v-show="!questionsTotal"/>
            </template>

            <questions
              v-if="currentTab === 'topQuestions'"
              top/>
          </div>
        </template>
        <template v-else>
          <questions-placeholder/>
        </template>
        <content-block-footer/>
      </div>
    </template>
    <template v-else>
      <div v-if="topQuestionsTotal" class="content-block">
        <div class="content-block-title">
          {{ $t('askMe.questionList.topQuestions') }}
        </div>
        <div class="content-block-body">
          <top-questions/>
        </div>
      </div>
      <div class="content-block">
        <template v-if="questionsTotal || topQuestionsTotal">
          <div class="content-block-title">
            {{ $t('askMe.questionList.latestQuestions') }}
            <div class="content-block-addon">
              <questions-category-filter/>
            </div>
          </div>
          <div class="content-block-body">
            <questions
              v-show="!!questionsTotal"
              @pageChange="onQuestionsPageChange"/>
            <questions-placeholder v-show="!questionsTotal"/>
          </div>
        </template>
        <content-block-footer/>
      </div>
    </template>
  </div>
</template>

<script>

import ContentBlockFooter from '@components/AskMePage/ContentBlockFooter'
import Questions from '@components/AskMePage/Questions/Questions'
import QuestionsPlaceholder from '@components/AskMePage/Questions/QuestionsPlaceholder'
import TopQuestions from '@components/AskMePage/Questions/TopQuestions'
import QuestionsCategoryFilter from '@components/AskMePage/Questions/QuestionsCategoryFilter'

export default {
  name: 'QuestionsPage',
  components: {
    QuestionsCategoryFilter,
    TopQuestions,
    QuestionsPlaceholder,
    Questions,
    ContentBlockFooter,
  },
  data() {
    return {
      currentTab: 'latestQuestions',
    }
  },
  computed: {
    ...mapState('askmePublic', ['questionsTotal', 'topQuestionsIds']),
    ...mapGetters('creator', ['getCreatorName']),

    tabsOptions() {
      const tabs = [
        'latestQuestions',
      ]

      if (this.topQuestionsTotal) {
        tabs.push('topQuestions')
      }

      return tabs.map(option => ({
        title: this.$t(`askMe.questionList.${ option }`),
        key: option,
      }))
    },

    tabsVariant() {
      return this.$appIsMD || this.$appIsSM
    },

    topQuestionsTotal() {
      return this.topQuestionsIds.length
    },
  },
  methods: {
    onQuestionsPageChange(page) {
      if (Number(page) > 1) {
        this.$router.push({ name: 'latest-questions-page', query: { p: page } })
      }
    },
  },

  mounted() {
    this.$tmr.goal({ goal: 'view_questions' })
  },
}
</script>
