<template>
  <modal v-model="modalOpened">
    <template slot="title">
      <template v-if="!!data">{{$t('askMe.categories.modalTitleEdit')}}</template>
      <template v-else>{{$t('askMe.categories.modalTitleNew')}}</template>
    </template>

    <template slot="body-content">
      <div>
        <form-group
          :label="$t('askMe.categories.nameLabel') + '*'"
          inline
        >
          <base-input v-model="title" :placeholder="$t('askMe.categories.namePlaceholder')" />
          <p class="input-description">Например: <span class="short-word" v-for="(word, i) in ['о работе', 'личное', 'про деньги', 'детство']" :key="i" @click="() => fillName(word)">{{ word }}</span></p>
        </form-group>

        <form-group
          :label="$t('askMe.categories.descriptionLabel') + '*'"
          inline
        >
          <base-textarea
            v-model="description"
            rows="3"
            :placeholder="$t('askMe.categories.descriptionPlaceholder')"
          />
          <span
            v-if="description"
            class="ta-counter"
            :class="{ 'ta-counter_red': description.length > 1000 }"
          >{{ description.length }} / 1000</span>
        </form-group>

        <form-group
          :label="$t('askMe.categories.colorLabel') + '*'"
          inline
        >
          <div class="tag-list">
            <span
              class="tag-item"
              v-for="(tag, i) in [
                'D72F18',
                'EE9033',
                'EDB01B',
                '90BF53',
                '559440',
                '94CFCA',
                '3D9FB4',
                '80C2E9',
                'B664BF',
                'DC3283',
                '60449A',
                '4853CA',
                'E0B285',
                '794A28',
                'C1C1C1',
                '464646',
              ]"
              :key="i"
            >
              <input
                type="radio"
                :value="tag"
                v-model="color"
                :id="i"
              >
              <label :for="i" :style="{ backgroundColor: `#${ tag }` }" />
            </span>
          </div>
        </form-group>

        <form-group
          :label="$t('askMe.categories.minAmountLabel')"
          inline
        >
          <input-addon
            v-model="minAmount"
            :addon="(`${ minAmount }`.length) ? currency : ''"
          />
        </form-group>

        <div class="debug" v-if="false">
          <div>title: <span>{{ title }}</span></div>
          <div>description: <span>{{ description }}</span></div>
          <div>color: <span>{{ color }}</span></div>
          <div>minAmount: <span>{{ minAmount }}</span></div>
          <div>currency: <span>{{ currency }}</span></div>
        </div>

        <div class="button-group">
          <base-button
            variant="primary"
            iconLeft="check"
            @click="!!data ? saveHandler() : addHandler()"
            :disabled="title.length < 1 || description.length < 1 || color.length < 1"
            :isLoading="addCategoryLoading"
          >{{ !!data ? $t('askMe.categories.save') : $t('askMe.categories.add') }}</base-button>

          <base-button
            variant="secondary"
            @click="undoClickHandler"
          >{{$t('askMe.categories.undo')}}</base-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/_BaseComponents/Modal'
import FormGroup from '@components/_BaseComponents/Form/FormGroup'
import BaseButton from '@components/_BaseComponents/BaseButton'
import BaseInput from '@components/_BaseComponents/Form/BaseInput'
import InputAddon from '@components/_BaseComponents/Form/InputAddon'
import BaseTextarea from '@components/_BaseComponents/Form/BaseTextarea'

export default {
  components: {
    Modal,
    FormGroup,
    BaseButton,
    BaseInput,
    InputAddon,
    BaseTextarea,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      title: '',
      description: '',
      color: '',
      minAmount: '',
    }
  },

  watch: {
    data: function (newState, prevState) {
      if (newState) {
        this.title = newState.title
        this.description = newState.description || ''
        this.color = newState.color || ''
        this.minAmount = newState.minAmount || ''
      }
    }
  },

  computed: {
    ...mapGetters('profile', {
      profile: 'getCurrentProfile',
      currency: 'getUserCurrency',
    }),

    ...mapGetters('askMe/questions', [
      'addCategoryLoading',
    ]),

    modalOpened: {
      get() { return this.isShow },
      set(value) { return !value && this.undoClickHandler() },
    },
  },

  methods: {
    ...mapActions('askMe/questions', ['addCategory', 'updateCategory']),

    undoClickHandler() {
      this.title = ''
      this.description = ''
      this.color = ''
      this.minAmount = ''

      this.$emit('toggleModal') ;
    },

    addHandler() {
      this.addCategory({
        title: this.title,
        description: this.description,
        color: this.color,
        min_amount: this.minAmount,
        currency: this.currency,
      }).then(() => {
        this.undoClickHandler()
      }, error => {
        // console.log(error)
      })
    },

    saveHandler() {
      this.updateCategory({
        id: this.data.id,
        title: this.title,
        description: this.description,
        color: this.color,
        min_amount: this.minAmount,
        currency: this.currency,
      })

      this.undoClickHandler()
    },

    fillName(str) {
      this.title = str
    },
  },
}
</script>

<style scoped lang="scss">
.button-group {
  padding-bottom: 20px;
  margin-top: 40px;
  display: flex;

  button {
    margin-right: 10px;
    border-radius: 8px;
  }
}
.ta-counter {
  display: block;
  text-align: right;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
  padding-top: 5px;

  &_red {
    color: #D63A3A;
  }
}
.debug {
  margin: 20px 0 10px 0;
  padding: 10px;
  border-radius: 8px;
  border: 1px dashed #838383;
  color: #838383;

  & > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: #f1f1f1;
    font-weight: bold;
  }
}

.input-description {
  margin-top: 5px;
  font-size: 12px;
  color: #838383;
}

.tag-list {
  border-radius: 5px;
  display: flex;
}

.tag-item {
  display: block;
  margin: 0 2px;

  label {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border .15s;

    &:hover {
      outline: 1px solid rgba($color-dark, .75);
    }
  }

  input {
    display: none;

    &:checked + label {
      outline: 1px solid rgba($color-dark, .75);
      border: 1px solid white;
    }
  }
}

.short-word {
  color: $color-purple;
  cursor: pointer;

  &:after {
    content: ', ';
    color: #838383;
  }

  &:last-child:after {
    content: '';
  }
}
</style>
