<style scoped lang="scss">
  .landing-header {
    display: flex;
    align-items: center;

    @include media(max, lg) {
      @include padding-x($gutter);
    }

    .logo {
      color: #282C32;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;

      img {
        display: block;
        margin-right: 5px;
      }

      .highlight {
        color: $color-purple;
      }
    }

    .controls {
      margin-left: auto;
      display: flex;
      align-items: center;

      .locale-select + .button {
        margin-left: 25px;
      }

      .button + .button {
        margin-left: 15px;
      }

      .registration-button {
        @include media(max, lg) {
          display: none;
        }
      }

      .locale-select {
        position: relative;

        .locale-select-button {
          padding: 10px;
          display: flex;
          align-items: center;
          color: $color-gray;
          cursor: pointer;

          .current-language {
            margin-left: 8px;
            margin-right: 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }

          .down-icon {
            font-size: 18px;
          }
        }

        .locale-select-dropdown {
          position: absolute;
          right: 10px;
          z-index: 5;
          background: #FFFFFF;
          border: 1px solid #D3DCE2;
          box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          padding: 10px;

          .locale-select-item {
            padding: 10px;
            cursor: pointer;

            &:hover {
              color: $color-purple;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="landing-header">
    <div class="logo">
      <img :src="$asAppAsset('/images/logo.svg')"/>
      <span class="highlight">Fan</span>Talks
    </div>
    <div class="controls">
      <div class="locale-select" v-click-outside="closeLocaleDropdown">
        <div class="locale-select-button" @click="openLocaleDropdown">
          <icon name="new/world"/>
          <div class="current-language">{{ currentLanguage }}</div>
          <icon class="down-icon" name="new/angle-down"/>
        </div>
        <div v-if="localeDropdownOpened" class="locale-select-dropdown">
          <div
            v-for="locale in localeOptions"
            :key="locale.value"
            class="locale-select-item"
            @click="selectLocale(locale.value)">
            {{ locale.label }}
          </div>
        </div>
      </div>
      <btn
        class="registration-button"
        variant="secondary-new"
        size="small"
        @click="register">{{ $t('global.login.registration') }}
      </btn>
      <btn
        variant="primary-new"
        size="small"
        @click="authorize">{{ $t('global.login.login') }}
      </btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LandingHeader',
  data() {
    return {
      localeDropdownOpened: false,
    }
  },
  computed: {
    ...mapGetters('application', ['appLocalesList', 'getAppLocale']),

    localeOptions() {
      return this.appLocalesList.map(l => ({
        label: {
          ru_RU: 'RU',
          en_US: 'EN',
        }[l.code],
        value: l.code,
      }))
    },

    currentLanguage() {
      return {
        ru_RU: 'RU',
        en_US: 'EN',
      }[this.getAppLocale.code]
    },
  },
  methods: {
    ...mapActions('application', ['setLocale']),
    ...mapActions('viewer', ['showLoginModal', 'showRegisterModal']),

    authorize() {
      this.$tmr.goal({ goal: 'signIn_click' }, { url: '/' })
      this.$ga.view({ category: 'FTAuth', action: 'action_bt_login' })

      this.$ga.event('action_bt_login')

      this.showLoginModal()
    },

    register() {
      this.$tmr.goal({ goal: 'signUp_click' }, { url: '/' })
      this.$ga.view({ category: 'FTAuth', action: 'action_bt_reg' })

      this.$ga.event('action_bt_reg')

      this.showRegisterModal()
    },

    closeLocaleDropdown() {
      this.localeDropdownOpened = false
    },

    openLocaleDropdown() {
      this.localeDropdownOpened = true
    },

    selectLocale(locale) {
      this.setLocale(locale)

      this.closeLocaleDropdown()
    },
  },
}
</script>
