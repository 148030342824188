<style scoped lang="scss">
  .questions-category-filter {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #191919;
    text-transform: none;

    .filter-button {
      display: inline-flex;
      align-items: center;
      padding: 7px 13px;
      border: 1px solid #E2E2E2;
      border-radius: 8px;
      cursor: pointer;
      align-self: start;

      .button-color {
        flex-shrink: 0;
        width: 13px;
        height: 13px;
        border-radius: 4px;
      }

      .button-color + .button-text {
        margin-left: 10px;
      }

      .button-text {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .button-icon {
        flex-shrink: 0;
        margin-left: 10px;
        font-size: 10px;
      }
    }

    .filter-dropdown {
      width: 320px;
      margin-top: 14px;
      position: absolute;
      right: 0;
      z-index: 2;
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      max-height: 400px;
      display: flex;
      flex-direction: column;

      @include media(max, md) {
        right: auto;
        left: 0;
      }

      @include media(max, sm) {
        @include position-all(fixed, 0);
        z-index: 10;
        width: 100%;
        height: 100%;
        max-height: 100%;
      }

      .dropdown-title {
        font-weight: 600;
        color: #191919;
        text-transform: uppercase;
        padding: 20px 0 0 20px;
      }

      .categories-list {
        padding: 20px;

        overflow-y: auto;

        .category + .category {
          margin-top: 15px;
        }

        .category {
          border: 1px solid #E2E2E2;
          border-radius: 8px;
          cursor: pointer;
          padding: 12px;

          &.selected {
            outline: 2px solid #6C71D3;
          }

          .category-title {
            display: flex;
            align-items: flex-start;

            .category-color {
              flex-shrink: 0;
              width: 13px;
              height: 13px;
              border-radius: 4px;
              margin-top: 2px;
            }

            .category-color + .category-title-text {
              margin-left: 10px;
            }

            .category-questions-number {
              margin-left: auto;
              color: #838383;
            }
          }

          .category-description {
            margin-top: 6px;
            color: #838383;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="questions-category-filter" v-click-outside="hideDropdown">
    <div class="filter-button" @click="toggleDropdown">
      <div v-if="button.color" class="button-color" :style="{backgroundColor: button.color}"/>
      <div class="button-text">{{ button.text }}</div>
      <icon name="angle-solid-down" theme gradient class="button-icon"/>
    </div>
    <transition name="fade">
      <div v-if="dropdownVisible" class="filter-dropdown">
        <div class="dropdown-title">Категории</div>
        <div class="categories-list">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="category"
            :class="{selected: filterCategoryId === category.id}"
            @click="selectCategory(category.id)">
            <div class="category-title">
              <div v-if="category.color" class="category-color" :style="{backgroundColor: category.color}"/>
              <div class="category-title-text">{{ category.title }}</div>
              <div class="category-questions-number">{{ category.questionsNumber }}</div>
            </div>
            <div v-if="category.description" class="category-description">
              {{ category.description }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'QuestionsCategoryFilter',
  data() {
    return {
      dropdownVisible: false,
    }
  },
  computed: {
    ...mapGetters('askmePublic', ['getCategoriesList', 'getCategory']),
    ...mapFields('askmePublic', ['filterCategoryId']),
    ...mapState('askmePublic', ['noCategoryQuestionsNumber']),

    categories() {
      return [
        {
          id: 'all',
          title: this.$t('askMe.questions.allCategories'),
          questionsNumber: this.noCategoryQuestionsNumber + _.sum(_.map(this.getCategoriesList, 'questionsNumber')),
        },
        {
          id: null,
          title: this.$t('askMe.questions.uncategorized'),
          questionsNumber: this.noCategoryQuestionsNumber,
        },
        ..._.sortBy(this.getCategoriesList, c => -c.questionsNumber),
      ]
    },

    currentCategory() {
      return this.getCategory(this.filterCategoryId)
    },

    buttonText() {
      if (this.filterCategoryId === 'all') {
        return this.$t('askMe.questions.allCategories')
      }

      if (this.filterCategoryId === null) {
        return this.$t('askMe.questions.uncategorized')
      }

      return this.currentCategory.title
    },

    button() {
      return {
        color: this.currentCategory ? this.currentCategory.color : null,
        text: this.buttonText,
      }
    },
  },
  methods: {
    selectCategory(id) {
      this.filterCategoryId = id

      this.hideDropdown()
    },

    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible

      this.$tmr.goal({ goal: 'action_category_click' })
    },

    hideDropdown() {
      this.dropdownVisible = false
    },
  },
}
</script>
