<style scoped lang="scss">
  .payment-form {
    position: relative;

    .loading-container {
      @include position-all(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(white, .5);
    }

    .payment-title {
      font-weight: 600;
      text-transform: uppercase;
    }

    .payment-methods {
      margin-top: 20px;
    }

    .payment-inputs {
      margin-top: 20px;

      .form-group + .form-group {
        margin-top: 20px;
      }

      .commission-section {
        margin-top: 20px;
        display: flex;
        align-items: center;
        user-select: none;

        .commission-checkbox {
          align-self: flex-start;
          flex-shrink: 0;
          flex-grow: 0;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--theme-color);
          background-color: rgba(var(--theme-color-rgb), .2);
          font-size: 20px;
          border-radius: 100%;
          cursor: pointer;

          &:hover {
            background-color: rgba(var(--theme-color-rgb), .3);
          }
        }

        .commission-text {
          margin-left: 12px;
          max-width: 470px;
        }
      }
    }

    .payment-total {
      margin-top: 30px;

      .total-text {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0;
      }

      .total-amount {
        margin-top: 10px;
        font-size: 24px;
        line-height: 36px;
      }
    }

    .missing-amount {
      margin-top: 20px;
    }

    .payment-error {
      margin-top: 10px;
    }

    .payment-controls {
      margin-top: 20px;
    }

    .payment-legal-text {
      margin-top: 15px;
      font-size: 12px;
      color: #838383;
    }

    .success-message {
      margin-top: 15px;
    }
  }
</style>

<template>
  <div class="payment-form">
    <template v-if="successText">
      <div class="payment-title">{{ $t('payment.additionalActions') }}</div>

      <info-box class="success-message">
        {{ successText }}
      </info-box>
    </template>
    <template v-else>
      <div class="payment-title">{{ $t('payment.choosePaymentMethod') }}</div>

      <payment-methods/>

      <div v-if="displayForm" class="payment-inputs">
        <form-group
          :state="!errors.email"
          :invalid-feedback="errors.email">
          <advanced-input
            v-model="viewerEmail"
            @input="onFieldInput('email')"
            icon-left="email"
            :placeholder="$t('payment.form.email')"/>
        </form-group>

        <form-group
          v-if="displayQiwiWalletInput"
          :state="!errors.phone"
          :invalid-feedback="errors.phone">
          <advanced-input
            v-model="viewerQiwiWallet"
            @input="onFieldInput('qiwi')"
            icon-left="wallet"
            :placeholder="$t('payment.form.qiwiWallet')"/>
        </form-group>

        <form-group
          v-if="displayPhoneInput"
          :state="!errors.phone"
          :invalid-feedback="errors.phone">
          <advanced-input
            v-model="viewerPhone"
            @input="onFieldInput('phone')"
            icon-left="phone"
            :placeholder="$t('payment.form.phone')"/>
        </form-group>

        <div class="commission-section">
          <div class="commission-checkbox" @click="toggleCommission">
            <icon :name="commissionCheckboxIcon"/>
          </div>
          <div class="commission-text">
            {{ $t('payment.form.commissionCovered', { channel: creatorInfo.name }) }}
          </div>
        </div>
      </div>

      <info-box v-if="missingMethodAmount" class="missing-amount">
        <amount-link
          :template="methodAddUpAmountTemplate"
          :amount="missingMethodAmount"
          inline additive/>
      </info-box>

      <template v-if="displayTotal">
        <div class="payment-total">
          <div class="total-text">{{ $t('payment.total') }}</div>
          <div class="total-amount">{{ totalAmountText }}</div>
        </div>

        <info-box v-if="errors.request" class="payment-error" variant="error">
          {{ errors.request }}
        </info-box>
      </template>

      <div v-if="paymentMethod" class="payment-controls">
        <btn
          v-if="displayDefaultPayButton"
          icon-left="gift"
          variant="primary"
          size="lg"
          :disabled="loaderVisible || !!missingMethodAmount"
          @click="processDefaultPay">{{ $t('global.common.send') }}
        </btn>
        <apple-pay-button
          v-if="displayApplePayButton"
          @click="processApplePay"/>
        <google-pay-button
          v-if="displayGooglePayButton"
          @click="processGooglePay"/>
      </div>
    </template>

    <div class="payment-legal-text">{{ $t('payment.legal') }}</div>

    <div v-if="loaderVisible" class="loading-container">
      <loader size="lg"/>
    </div>
  </div>
</template>

<script>

import applePay from '@services/apple-pay'
import googlePay from '@services/google-pay'

import PaymentMethods from './PaymentMethods'
import GooglePayButton from './GooglePayButton'
import ApplePayButton from './ApplePayButton'

export default {
  name: 'PaymentForm',
  components: {
    ApplePayButton,
    GooglePayButton,
    PaymentMethods,
  },
  data() {
    return {
      loadingState: {
        finalAmount: false,
        invoice: false,
      },

      totalAmount: 0,
      totalAmountCurrency: 'RUB',

      errors: {},

      successText: null,

      // Invoice
      pollingInvoice: false,
      pollingInvoiceTimeout: null,

      pollingAttemptsLeft: 40,
    }
  },
  computed: {
    ...mapState('creator', ['creatorInfo']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    ...mapState('donation', ['donationAmount']),

    ...mapState('payment', ['paymentMethod']),
    ...mapState('invoice', ['invoiceId', 'invoiceHash']),

    ...mapGetters('payment', ['selectedPaymentMethod']),
    ...mapFields('payment', ['commissionCovered']),

    ...mapFields('viewer', ['viewerPhone', 'viewerEmail', 'viewerQiwiWallet']),

    loaderVisible() {
      return this.loadingState.finalAmount || this.loadingState.invoice
    },

    totalAmountText() {
      return this.selectedPaymentMethod
        ? this.getAmountWithCurrency(this.totalAmount, this.totalAmountCurrency)
        : null
    },

    commissionCheckboxIcon() {
      return this.commissionCovered ? 'heart' : 'heart-stroke'
    },

    displayForm() {
      return !!this.selectedPaymentMethod
    },

    displayTotal() {
      return !!this.selectedPaymentMethod && !!this.totalAmount
    },

    displayPhoneInput() {
      return ['fakeMobile', 'sberPay'].includes(this.paymentMethod)
    },

    displayQiwiWalletInput() {
      return this.paymentMethod === 'qiwiMyCom'
    },

    displayApplePayButton() {
      return this.paymentMethod === 'applePay'
    },

    displayGooglePayButton() {
      return this.paymentMethod === 'googlePay'
    },

    displayDefaultPayButton() {
      return !this.displayApplePayButton && !this.displayGooglePayButton
    },

    missingMethodAmount() {
      if (!this.selectedPaymentMethod) {
        return 0
      }

      const missingAmount = this.selectedPaymentMethod.minAmount - this.donationAmount

      return missingAmount < 0 ? 0 : missingAmount
    },

    methodAddUpAmountTemplate() {
      return this.selectedPaymentMethod
        ? this.$t('payment.methodAddUpAmountTemplate', {
          method: this.selectedPaymentMethod.title,
          amount: '{amount}',
        })
        : null
    },
  },
  methods: {
    ...mapActions('invoice', ['createAskmeInvoice', 'checkAskmeInvoice']),
    ...mapActions('payment', ['fetchFinalAmount']),

    onFieldInput(field) {
      this.$set(this.errors, field, null)
    },

    processGooglePay() {
      this.loadingState.invoice = true

      this.errors = {}

      googlePay.pay(this.totalAmountCurrency, this.totalAmount)
        .then(paymentToken => {
          this.processInvoice(paymentToken)
        })
        .catch(() => {
          console.log('[Google Pay] Cancelled')
        })
        .finally(() => {
          this.loadingState.invoice = false
        })
    },

    processApplePay() {
      this.loadingState.invoice = true

      this.errors = {}

      applePay.pay(this.totalAmountCurrency, this.totalAmount, this.creatorInfo.isDobro)
        .then(paymentToken => {
          this.processInvoice(paymentToken)
        })
        .catch(() => {
          console.log('[Apple Pay] Cancelled')
        })
        .finally(() => {
          this.loadingState.invoice = false
        })
    },

    processDefaultPay() {
      this.processInvoice()

      this.$ga.action({
        category: 'AskMePayment',
        action: 'btn_checkout',
      })
    },

    processInvoice(paymentToken = null) {
      this.loadingState.invoice = true

      this.errors = {}

      return this.createAskmeInvoice(paymentToken ? { paymentToken } : null)
        .then(({
          action,
          redirect_url,
          text,
        }) => {
          if (action === 'redirect') {
            window.location.href = redirect_url
          }

          if (action === 'wait') {
            this.startInvoicePolling()
          }

          if (action === 'close') {
            this.successText = text

            this.loadingState.invoice = false
          }
        })
        .catch(({ errors }) => {
          this.loadingState.invoice = false

          if (errors) {
            errors.forEach(e => {
              this.$set(this.errors, e.param, e.message)
            })
          }
        })
    },

    startInvoicePolling() {
      this.pollingAttemptsLeft = this.pollingAttemptsLeft - 1

      if (this.pollingAttemptsLeft <= 0) {
        this.loadingState.invoice = false

        this.$set(this.errors, 'request', this.$t('payment.form.timeoutError'))

        return
      }

      this.pollingInvoiceTimeout = setTimeout(() => {
        this.checkAskmeInvoice({
          id: this.invoiceId,
          hash: this.invoiceHash,
        })
          .then(({
            action,
            redirect_url,
            text,
          }) => {
            if (action === 'redirect') {
              window.location.href = redirect_url
            }

            if (action === 'wait') {
              this.startInvoicePolling()
            }

            if (action === 'close') {
              this.successText = text

              this.loadingState.invoice = false
            }
          })
      }, 2000)
    },

    toggleCommission() {
      this.commissionCovered = !this.commissionCovered

      this.$ga.action({
        category: 'AskMePayment',
        action: 'btn_commission',
      })
    },

    calculateFinalAmount() {
      this.totalAmount = 0

      if (this.paymentMethod && !this.missingMethodAmount) {
        this.loadingState.finalAmount = true

        this.fetchFinalAmount()
          .then(({
            amount,
            currencyCode,
          }) => {
            this.totalAmount = amount
            this.totalAmountCurrency = currencyCode
          })
          .finally(() => {
            this.loadingState.finalAmount = false
          })
      }
    },
  },
  created() {
    this.calculateFinalAmount()
  },
  watch: {
    donationAmount: 'calculateFinalAmount',
    paymentMethod: 'calculateFinalAmount',
    commissionCovered: 'calculateFinalAmount',
  },
}
</script>
