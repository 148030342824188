<style scoped lang="scss">
  .viewer-section {
    .viewer-input + .viewer-input {
      margin-top: 15px;
    }

    .email-helper {
      margin-top: 8px;
    }
  }
</style>

<template>
  <div class="viewer-section">
    <advanced-input
      v-model="viewerAlias"
      :placeholder="$t('askMe.viewer.alias') + '*'"
      class="viewer-input"
      icon-left="user"/>
    <advanced-input
      v-model="viewerEmail"
      :placeholder="$t('askMe.viewer.email')"
      class="viewer-input"
      icon-left="email"/>
    <helper-box variant="helper" class="email-helper">
      {{ $t('askMe.viewer.emailHelper') }}
    </helper-box>
  </div>
</template>

<script>

export default {
  name: 'ViewerSection',
  computed: {
    ...mapFields('viewer', ['viewerAlias', 'viewerEmail']),
  },
}
</script>
