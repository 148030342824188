<style scoped lang="scss">
  .login-modal {
    .closed {
      max-width: 620px;
      width: 100%;
      padding: 50px;

      .closed-image {
        text-align: center;
        img {
          width: 160px;
        }
      }

      .closed-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #282C32;
      }

      .closed-text {
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #7E8999;
      }
    }
  }
</style>

<template>
  <modal class="login-modal" v-model="registerModalOpened">
    <template slot="body">
      <div class="closed">
        <div class="closed-image">
          <img :src="$asAppAsset('/images/closed.svg')"/>
        </div>
        <div class="closed-title">{{ $t('closed.title') }}</div>
        <div class="closed-text">{{ $t('closed.text') }}</div>
      </div>
    </template>
  </modal>
</template>

<script>

import AuthForm from '@components/AuthForm'

export default {
  name: 'RegisterModal',
  components: { AuthForm },
  computed: {
    ...mapFields('viewer', ['registerModalOpened']),
  },
}
</script>
