<template>
  <div class="stats">
    <div class="stats__item">
      <div class="stats__res">{{ !stats ? '&nbsp;' : stats.total_amount }}</div>
      <div class="stats__sign">{{ $t('askMe.stats.questions') }}</div>
    </div>

    <div class="stats__item">
      <div class="stats__res">{{ !stats ? '&nbsp;' : stats.answered_amount }}</div>
      <div class="stats__sign">{{ $t('askMe.stats.answers') }}</div>
    </div>

    <div class="stats__item">
      <div class="stats__res">{{ !stats ? '&nbsp;' : getAmountWithCurrency(stats.total_profit.amount, stats.total_profit.currency) }}</div>
      <div class="stats__sign">{{ $t('askMe.stats.amount') }}</div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'Stats',

  data() {
    return {}
  },

  watch: {
    '$route' (to, from) {
      this.getStats()
    }
  },

  created() {
    this.getStats()
  },

  computed: {
    ...mapGetters('askMe/questions', [
      'isStatsLoading',
      'stats',
    ]),

    ...mapGetters('currencies', [
      'getAmountWithCurrency',
    ]),
  },

  methods: {
    ...mapActions('askMe/questions', ['getStats']),
  },

}

</script>

<style scoped lang="scss">
.stats {
  background: $color-light-gray;
  border-radius: 8px;
  padding: 5px 15px;

   @media (min-width: 720px) {
    // max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 15px 5px;
  }

  &__item {
    padding: 15px 0;
    border-top: 1px solid $color-space-gray;

    &:first-child {
      padding-top: 10px;
      border-top: 0;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    @media (min-width: 720px) {
      padding: 0 15px;
      border-top: 0;
      border-left: 1px solid $color-space-gray;
      flex: 1 1 auto;

      &:first-child {
        padding-top: 0;
        padding-left: 10px;
        border-left: 0;
      }

      &:last-child {
        padding-bottom: 0;
        padding-right: 10px;
      }
    }
  }

  &__res {
    color: $color-dark;
    font-size: 20px;
    line-height: 1.5;
  }

  &__sign {
    color: $color-dark;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
