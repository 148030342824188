import applePay from '@services/apple-pay'
import googlePay from '@services/google-pay'

import storage from '@utils/storage'

const STORAGE_PAYMENT_SETTINGS = 'da.donation.paymentSettings'

const paymentMethodsIcons = {
  bankCardEur: 'payment-cards',
  bankCardRub: 'payment-cards',
  bankCardUsd: 'payment-cards',
  applePay: 'payment-apple-pay',
  googlePay: 'payment-google-pay',
  boaCompraBankUsd: 'payment-br-bank',
  boaCompraMasterCardUsd: 'payment-mastercard',
  boaCompraCardsUsd: 'payment-br-card',
  boaCompraVisaUsd: 'payment-visa',
  fakeMobile: 'payment-sms',
  adyenSofortEur: 'payment-klarna',
  cryptocurrencies: 'payment-cryptocurrencies',
  sberPay: 'payment-sberpay',
  sberMyCom: 'payment-sberbank',
  vkPay: 'payment-vkpay',
  payPalEur: 'payment-paypal',
  payPalRub: 'payment-paypal',
  payPalUsd: 'payment-paypal',
  paySaferCardEur: 'payment-paysafe',
  paySaferCardUsd: 'payment-paysafe',
  qiwiMyCom: 'payment-qiwi',
  webMoneyUsd: 'payment-webmoney',
  webMoneyEur: 'payment-webmoney',
  yandexMoneyMyCom: 'payment-y-money',
  fasterPaymentsSystem: 'payment-faster-payments-system',
  boletoFlashBrl: 'payment-boleto-flash',
  pix: 'payment-pix',
  trustly: 'payment-trustly',
}

export const state = {
  paymentMethods: {},

  paymentMethod: _.get(storage.getItem(STORAGE_PAYMENT_SETTINGS), 'paymentMethod', null),

  finalAmount: null,

  commissionCovered: _.get(storage.getItem(STORAGE_PAYMENT_SETTINGS), 'commissionCovered', false),
}

export const getters = {
  getField,

  getPaymentMethod: state => code => state.paymentMethods[code],

  selectedPaymentMethod: (state, getters) => getters.getPaymentMethod(state.paymentMethod),

  paymentMethodsList: state => _.sortBy(Object.values(state.paymentMethods), 'order'),
}

export const mutations = {
  updateField,

  SET_PAYMENT_METHODS(state, methods) {
    state.paymentMethods = _.keyBy(methods, 'code')
  },

  SET_PAYMENT_METHOD(state, method) {
    state.paymentMethod = method
  },

  SAVE_PAYMENT_SETTINGS(state) {
    storage.setItem(STORAGE_PAYMENT_SETTINGS, {
      paymentMethod: state.paymentMethod,
      commissionCovered: state.commissionCovered,
    })
  },
}

export const actions = {
  fetchPaymentMethods({
    commit,
    state,
    rootGetters,
    rootState,
  }) {
    return api
      .get('/payin/systems', {
        user_id: rootGetters['creator/getCreatorId'],
        group: true,
        // amount: rootState['donation'].donationAmount,
        currency: rootState['currencies'].currentCurrency,
      })
      .then(({ data }) => {
        const restrictedMethods = []

        if (applePay.available) {
          if (_.map(data, 'key').includes('applePay')) {
            commit('SET_PAYMENT_METHOD', 'applePay')
          }
        } else {
          restrictedMethods.push('applePay')
        }

        if (!googlePay.available) {
          restrictedMethods.push('googlePay')
        }

        commit('SET_PAYMENT_METHODS', Object.values(data)
          .filter(method => !restrictedMethods.includes(method.key))
          .map((method, index) => ({
            code: method.key,
            title: method.title,
            group: method.group,
            order: {
              primary: 100,
              secondary: 200,
            }[method.group] + index,
            additional: method.addition,
            icon: paymentMethodsIcons[method.key],
            minAmount: method.min_amount_converted,
          })))

        if (!state.paymentMethods[state.paymentMethod]) {
          commit('SET_PAYMENT_METHOD', null)
        }
      })
  },

  fetchFinalAmount({
    state,
    rootGetters,
    rootState,
  }, { commissionCovered = null } = {}) {
    const payload = {
      amount: rootState['donation'].donationAmount,
      currency: rootState['currencies'].currentCurrency,
      system: state.paymentMethod,
      commission_covered: commissionCovered ?? state.commissionCovered,
      user_id: rootGetters['creator/getCreatorId'],
    }

    const {
      donationMessageType,
      speechSynthesisVoice,
      speechSynthesisEnabled,
    } = rootState['message'].message

    if (donationMessageType === 'text') {
      if (speechSynthesisEnabled) {
        const [voiceLocale, voiceId] = speechSynthesisVoice.split(':')
        payload.donation_tts_voice_id = voiceId == 'random' ? voiceId : Number(voiceId)
      }
    }

    return api
      .get('/payin/get_final_amount', payload)
      .then(({ data }) => ({
        amount: data.amount,
        currencyCode: data.currency_code,
      }))
  },
}
