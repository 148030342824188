<style scoped lang="scss">
  .page-header {
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 5px 20px 0 rgba(black, .05);
    border-radius: var(--block-border-radius);

    @include media(max, sm) {
      border-radius: 0;
    }

    .header-wallpaper {
      height: 85px;
      background: #6C71D3 appAsset('/images/header-background.png');
      background-position: center;
      background-size: cover;
      border-radius: var(--block-border-radius) var(--block-border-radius) 0 0;
      position: relative;

      @include media(max, sm) {
        height: 48px;
        border-radius: 0;
      }

      .askme-link {
        position: absolute;
        top: 25px;
        right: 20px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 6px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #6C71D3;

        @include media(max, sm) {
          top: 8px;
        }
      }
    }

    .header-creator-avatar {
      position: absolute;
      top: 20px;
      left: 15px;
      width: 110px;
      height: 110px;
      padding: 5px;
      background: white;
      border-radius: var(--block-border-radius);

      @include media(max, sm) {
        width: 74px;
        height: 74px;
      }
    }

    .header-creator-info {
      height: 60px;
      background: white;
      display: flex;
      align-items: center;
      padding-left: 145px;
      padding-right: 15px;
      border-radius: 0 0 var(--block-border-radius) var(--block-border-radius);

      @include media(max, sm) {
        height: 55px;
        padding-left: 100px;
        border-radius: 0;
      }

      .creator-name {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;

        @include media(max, sm) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .donations-link {
        font-size: 40px;
        margin-left: auto;
        line-height: 1;

        @include media(max, sm) {
          font-size: 30px;
        }
      }
    }
  }
</style>

<template>
  <div class="page-header">
    <div class="header-wallpaper">
    </div>
    <img class="header-creator-avatar" :src="creatorInfo.avatar"/>
    <div class="header-creator-info">
      <div class="creator-name">{{ creatorInfo.name }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  computed: {
    ...mapState('creator', ['creatorInfo', 'awards', 'creatorPageSettings']),
    ...mapGetters('creator', ['getCreatorName']),

    donationLink() {
      return `/r/${this.$route.params.slug}`
    },
  },
}
</script>
