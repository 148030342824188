import axios from 'axios'
import chroma from 'chroma-js'
import style from '@style/_grid.scss'
import storage from '@utils/storage'
import {
  i18n,
  languages,
} from '@src/localization/config'
import cookies from '@utils/cookies'

const STORAGE_APP_LOCALE = 'fantalks.locale'
const STORAGE_APP_LOCALIZATION = 'fantalks.localization'

const calculateColors = (color) => {
  const newColor = chroma(color)
  const hue = newColor.get('hsl.h')
  const linkColor = newColor.set('hsl.h', hue - 2)
  const gradientFromColor = newColor
  const gradientToColor = newColor.set('hsl.h', hue + 10)

  return {
    '--theme-color': newColor.hex(),
    '--theme-link-color': linkColor.hex(),
    '--theme-color-rgb': newColor.rgb(),
    '--theme-gradient-color-from': gradientFromColor.hex(),
    '--theme-gradient-color-to': gradientToColor.hex(),
    '--theme-gradient-hover-color-from': gradientFromColor.brighten(.15).hex(),
    '--theme-gradient-hover-color-to': gradientToColor.brighten(.15).hex(),
  }
}

const appSizes = [
  'lg',
  'md',
  'sm',
]

const appContainers = appSizes.reduce((acc, size) => {
  acc[size] = Number(style[`--grid-${size}-breakpoint`].replace(/[^\d.-]/g, ''))

  return acc
}, {})

const locales = Object.keys(languages)

const defaultLocale = 'en_US'

let browserLocale = window.navigator.language.replace('-', '_')

if (browserLocale && !languages[browserLocale]) {
  const [userLanguage] = browserLocale.split('_')

  Object.keys(languages).forEach(language => {
    if (language.split('_')[0] === userLanguage) {
      browserLocale = language
    }
  })
}

let appLocale = storage.getItem(STORAGE_APP_LOCALE) ?? (browserLocale || defaultLocale)

export const state = {
  // Application
  appWidth: window.innerWidth,
  scrollTop: window.pageYOffset || document.documentElement.scrollTop,

  themeColor: null,
  themeColors: {},
  themeTextColor: null,
  themeBackgroundColor: null,

  // Time
  timestamp: dates.timestamp(),

  // Locale
  locales: locales.reduce((acc, localeCode, index) => {
    acc[localeCode] = {
      name: languages[localeCode],
      code: localeCode,
      order: index,
    }

    return acc
  }, {}),
  locale: appLocale,

  cookiesAcceptable: false,
}

export const getters = {
  getField,

  // App size
  appSize: (state) => {
    let appSize = 'xl'

    appSizes.forEach(size => {
      if (state.appWidth < appContainers[size]) {
        appSize = size
      }
    })

    return appSize
  },
  appIsSM: (state, getters) => getters.appSize === 'sm',
  appIsMD: (state, getters) => getters.appSize === 'md',
  appIsLG: (state, getters) => getters.appSize === 'lg',
  appIsXL: (state, getters) => getters.appSize === 'xl',

  // Locale
  getLocale: state => locale => state.locales[locale],
  getAppLocale: state => state.locales[state.locale],
  appLocale: state => state.locale,
  appLocalesList: state => _.sortBy(Object.values(state.locales), 'order'),
}

export const mutations = {
  updateField,

  SET_APP_WIDTH(state) {
    state.appWidth = window.screen.width
  },

  SET_SCROLL_TOP(state) {
    state.scrollTop = window.pageYOffset || document.documentElement.scrollTop
  },

  SET_TIME(state) {
    state.timestamp = dates.timestamp()
  },

  SET_THEME_COLORS(state, {
    themeColor,
    themeTextColor,
  }) {
    const colors = calculateColors(themeColor)

    state.themeColor = themeColor
    state.themeColors = colors

    Object.keys(colors).forEach(key => {
      document.documentElement.style.setProperty(key, colors[key])
    })

    state.themeTextColor = themeTextColor

    document.documentElement.style.setProperty('--theme-text-color', themeTextColor)
  },

  SET_LOCALE(state, locale) {
    state.locale = locale

    storage.setItem(STORAGE_APP_LOCALE, locale)
  },

  SET_COOKIES_ACCEPTABLE(state, value) {
    state.cookiesAcceptable = value
  },
}

export const actions = {
  init({ commit, dispatch, state }) {
    // setInterval(() => {
    //   commit('SET_TIME')
    // }, 1000)

    const debouncedWidthUpdate = () => {
      commit('SET_APP_WIDTH')
    }

    const debouncedScrollUpdate = () => {
      commit('SET_SCROLL_TOP')
    }

    window.addEventListener('resize', debouncedWidthUpdate)
    window.addEventListener('scroll', debouncedScrollUpdate)

    const style = getComputedStyle(document.body)

    const themeColor = style.getPropertyValue('--theme-color')
    const themeTextColor = style.getPropertyValue('--theme-text-color')

    if (themeColor && themeTextColor) {
      dispatch('setThemeColors', {
        themeColor: style.getPropertyValue('--theme-color').trim(),
        themeTextColor: style.getPropertyValue('--theme-text-color').trim(),
      })
    }

    dispatch('setLocale', defaultLocale)
    dispatch('setLocale', appLocale)

    if (appLocale !== 'ru_RU' && !cookies.getItem('cookies_warning')) {
      commit('SET_COOKIES_ACCEPTABLE', true)
    }
  },

  acceptCookies({ commit }) {
    cookies.setItem('cookies_warning', 1, Infinity)
    commit('SET_COOKIES_ACCEPTABLE', false)
  },

  setLocale({ commit, dispatch }, locale) {
    if (!languages[locale]) {
      return Promise.reject(`[App] No such locale ${locale}`)
    }

    const savedLocalization = storage.getItem(`${STORAGE_APP_LOCALIZATION}.${locale}`)

    if (savedLocalization) {
      i18n.setLocaleMessage(locale, savedLocalization)
    }

    i18n.locale = locale
    commit('SET_LOCALE', locale)
    return dispatch('fetchLocale', locale)
  },

  fetchLocale({ commit }, locale) {
    return axios.get(`${process.env.VUE_APP_LOCALIZATION_PATH}/${locale}.json?t=${dates.timestamp()}`)
      .then(({ data }) => {
        storage.setItem(`${STORAGE_APP_LOCALIZATION}.${locale}`, data)

        i18n.setLocaleMessage(locale, data)
      })
  },

  selectLocale({ dispatch }, locale) {
    storage.setItem(STORAGE_APP_LOCALE, locale)
    window.location.reload()
  },

  setThemeColors({ commit }, {
    themeColor,
    themeTextColor,
  }) {
    return commit('SET_THEME_COLORS', {
      themeColor,
      themeTextColor,
    })
  },
}
