<style scoped lang="scss">
  .amount-section {
    .amount-controls {
      position: relative;
      display: flex;
      align-items: center;

      .amount-input {
        flex-shrink: 0;
        width: 100%;
      }

      .currency-select {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 10px;
      }
    }

    .amount-helper {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media(max, sm) {
        //flex-direction: column;
        //align-items: flex-start;
      }

      .fast-amount {
        display: flex;
        align-items: center;
        font-size: 12px;

        .amount-link + .amount-link {
          margin-left: 8px;
        }
      }
    }

    .minimal-amount-text {
      font-size: 12px;
      line-height: 1.15;
      color: $warm-grey;
      white-space: nowrap;

      @include media(max, sm) {
        //margin-top: 10px;
      }
    }
  }
</style>

<template>
  <div class="amount-section">
    <div class="amount-controls">
      <amount-input/>
      <currency-select/>
    </div>
    <div class="amount-helper">
      <div class="fast-amount">
        <amount-link
          v-for="(option, index) in fastAmountOptions"
          :key="index"
          :amount="option.value"/>
      </div>
      <div class="minimal-amount-text">
        {{ minAmountText }}
      </div>
    </div>
  </div>
</template>

<script>
import AmountInput from './AmountInput'
import CurrencySelect from './CurrencySelect'

export default {
  name: 'AmountSection',
  components: {
    AmountInput,
    CurrencySelect,
  },
  computed: {
    ...mapGetters('donation', ['getDonationSettings', 'minimalAmounts']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    minAmountText() {
      return this.$t('askMe.questionForm.minimalAmountText', {
        amount: this.getAmountWithCurrency(this.getDonationSettings.minimalAmount),
      })
    },

    fastAmountOptions() {
      return this.getDonationSettings.fastAmounts.map(amount => ({
        label: this.getAmountWithCurrency(amount),
        value: amount,
      }))
    },
  },
}
</script>
