<style lang="scss" scoped>
  .payouts-page {

  }
</style>

<template>
  <div class="payouts-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'PayoutsPage',
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.payouts'),
    }
  },
  mounted() {
    this.loadingState.page = true

    loadLegacyPage('/dashboard/payouts', this.$refs.legacy)
      .finally(() => {
        this.loadingState.page = false
      })
  },
}
</script>
