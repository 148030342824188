<template>
  <div class="question" :class="{ '_disabled': disabled }">
    <div class="question__head">
      <div>
        <icon name="users" />
        <span
          class="question__head-title"
          v-if="questionData.inquirer && questionData.inquirer.name"
        >{{ $t('askMe.question.headTitlePublicInquirer') }}: <b>{{
          questionData.inquirer.name.length > 16 ? questionData.inquirer.name.slice(0, 16) + '...' : questionData.inquirer.name
          }}</b></span>
        <span
          class="question__head-title"
          v-else
        >{{ $t('askMe.question.headTitlePublicAnonym') }}</span>
      </div>
      <div>
        <span class="question__head-amount">{{ getAmountWithCurrency(questionData.cost, questionData.cost_currency) }}</span>
      </div>
    </div>
    <div v-if="questionData.category" class="tag">
      <span
        class="tag-color"
        :style="{ backgroundColor: '#' + questionData.category.color }"
      />
      <span
        class="tag-title"
      >{{ questionData.category.title }}</span>
    </div>
    <div class="question__body">
      <div class="question__text" v-if="questionData.content">
        <p>{{ questionData.content }}</p>
      </div>
      <div class="question__answer" v-if="questionData.status === 'answered' && questionData.answer">
        <div class="question__answer-title">{{ $t('askMe.question.answerTitle') }}:</div>
        <p>{{ questionData.answer.content }}</p>
      </div>
    </div>
    <div class="question__footer">
      <div class="question__btn-group" v-if="!activeBlock">
        <div  v-if="questionData.status === 'answered'">
          <button
            class="question__btn_accept"
            @click="() => showBlock('answer')"
          >{{ $t('askMe.question.btnEdit') }}</button>
        </div>

        <div  v-if="questionData.status === 'unanswered'">
          <button
            class="question__btn_accept"
            @click="() => showBlock('answer')"
          >{{ $t('askMe.question.btnAccept') }}</button>
        </div>

        <div v-if="questionData.status === 'unanswered'">
          <button
            v-if="!!questionData.is_manual"
            class="question__btn_reject"
            @click="deleteQuestion"
          >{{ $t('askMe.question.btnDelete') }}</button>

          <button
            v-else
            class="question__btn_reject"
            @click="() => showBlock('complaint')"
          >{{ $t('askMe.question.btnReject') }}</button>
        </div>
      </div>

      <div class="question__send-answer" v-if="activeBlock === 'answer'">
        <div>
          <base-textarea
            v-model="answerText"
            rows="3"
            ref="answerTextArea"
            :placeholder="$t('askMe.question.answerPlaceholder')"
          />
          <span
            v-if="answerText"
            class="ta-counter"
            :class="{ 'ta-counter_red': answerText.length > 2000 }"
          >{{ answerText.length }} / 2000</span>
        </div>

        <div class="question__send-button-group">
          <base-button
            variant="secondary"
            @click="() => showBlock()"
          >{{ $t('askMe.question.undo') }}</base-button>

          <base-button
            v-if="questionData.status === 'answered'"
            variant="primary-"
            @click="sendEditAnswer"
            :disabled="(answerText === questionData.answer.content) || (questionData.content && (answerText.length < 1 || answerText.length > 2000))"
          >{{ $t('askMe.question.saveEdit') }}</base-button>

          <base-button
            v-if="questionData.status === 'unanswered'"
            variant="primary"
            @click="sendAnswer"
            :disabled="questionData.content && (answerText.length < 1 || answerText.length > 2000)"
          >{{ $t('askMe.question.send') }}</base-button>
        </div>
      </div>

      <div class="question__send-complaint" v-if="activeBlock === 'complaint'">
        <form-group
          :label="$t('askMe.question.complaintLabel')"
          inline
        >
          <dropdown-select
            v-model="reason"
            :options="reasonOptions"
            variant="new"
          />
        </form-group>

        <div class="question__send-button-group">
          <base-button
            variant="secondary"
            @click="() => showBlock()"
          >{{ $t('askMe.question.undo') }}</base-button>

          <base-button
            variant="primary"
            @click="sendReport"
            :disabled="reason === null"
          >{{ $t('askMe.question.send') }}</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@components/_BaseComponents/Form/FormGroup'
import BaseTextarea from '@components/_BaseComponents/Form/BaseTextarea'
import BaseButton from '@components/_BaseComponents/BaseButton'

export default {
  name: 'Question',

  components: {
    FormGroup,
    BaseTextarea,
    BaseButton,
  },

  props: {
    questionData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      answerText: '',
      reason: null,
      activeBlock: null,
    }
  },

  methods: {
    ...mapActions('askMe/questions', ['toAnswer', 'toEditAnswer', 'toReport']),

    showBlock(id) {
      this.answerText = (this.questionData.status === 'answered' && this.questionData.answer) ? this.questionData.answer.content : ''
      this.reason = null

      this.activeBlock = id

      if (this.activeBlock === 'answer') {
        this.$nextTick(() => {
          this.$refs.answerTextArea.$el.focus()
        })
      }
    },

    sendAnswer() {
      this.toAnswer({
        id: this.questionData.id,
        type: 'askmetextanswer',
        content: this.answerText,
      })
    },

    async sendEditAnswer() {
      const res = await this.toEditAnswer({
        id: this.questionData.id,
        type: 'askmetextanswer',
        content: this.answerText,
      })

      if (res.id) this.activeBlock = ''
    },

    sendReport() {
      this.toReport({
        id: this.questionData.id,
        reason: this.reason,
      })
    },

    deleteQuestion() {
      this.toReport({
        id: this.questionData.id,
        reason: 'unknown',
      })
    },
  },

  computed: {
    ...mapGetters('askMe/categories', [
      'categories',
    ]),

    ...mapGetters('currencies', [
      'getAmountWithCurrency',
    ]),

    reasonOptions() {
      const translate = {
        'offensive_content': 'askMe.question.reason.offensiveContent',
        'confidential_content': 'askMe.question.reason.confidentialContent',
        'persecutive_content': 'askMe.question.reason.persecutiveContent',
        'hateful_content': 'askMe.question.reason.hatefulContent',
        'threatening_content': 'askMe.question.reason.threateningContent',
        'unknown': 'askMe.question.reason.unknown',
      }

      return [
        'offensive_content',
        'confidential_content',
        'persecutive_content',
        'hateful_content',
        'threatening_content',
        'unknown',
      ]
        .map(option => ({
          value: option,
          label: this.$t(translate[option]),
        }))
    },
  },
}
</script>

<style scoped lang="scss">
.question {
  background: rgba($color-space-gray, .5);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;

  &._disabled {
    opacity: .5;
    pointer-events: none;
  }

  &__head {
    display: flex;
    justify-content: space-between;

    svg {
      fill: $color-gray;
      width: 16px;
    }

    &-title {
      color: $color-gray;
      font-size: 12px;
      line-height: 16px;
      margin-left: 10px;
      word-break: break-all;
    }

    &-amount {
      display: block;
      background-color: white;
      border-radius: 8px;
      color: $color-dark;
      font-size: 14px;
      line-height: 16px;
      padding: 8px 12px;
      cursor: default;
      white-space: nowrap;
    }
  }

  &__text {
    color: $color-dark;
    font-weight: 500;
    font-size: 16px;
    overflow-wrap: break-word;
  }

  &__body {
    word-wrap: break-word;
  }

  &__answer {
    color: $color-dark;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    word-wrap: break-word;

    &-title {
      color: $color-gray;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 6px;
    }

    p {
      overflow-wrap: break-word;
    }
  }

  &__footer {
    padding-top: 25px;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
  }

  &__btn_accept {
    display: block;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    color: $color-purple;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__btn_reject {
    display: block;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    color: #D63A3A;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__send {
    &-button-group {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      button {
        border-radius: 8px;
        margin-left: 15px;
      }
    }
  }
}

.ta-counter {
  display: block;
  text-align: right;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
  padding-top: 5px;

  &_red {
    color: #D63A3A;
  }
}

.tag {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}

.tag-title {
  font-size: 14px;
  display: block;
}

.tag-color {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  margin-right: 10px;
}
</style>
