<template>
  <div>
    <loader
      size="md"
      v-if="isCategoriesLoading"
    />

    <div v-else>
      <stub v-if="categories.length === 0">
        <template v-slot:image>
          <div class="stub__image"></div>
        </template>

        <template v-slot:title>
          <Title type="2">{{ $t('askMe.categories.stubTitle') }}</Title>
        </template>

        <template v-slot:content>
          <p>{{ $t('askMe.categories.stubText') }}</p>
          <div style="margin-top: 20px;">
            <base-button
              variant="primary"
              iconLeft="plus"
              @click="toggleModal"
              class="add-button"
            >{{ $t('askMe.categories.addButton') }}</base-button>
          </div>
        </template>
      </stub>

      <div class="content-area" v-if="categories.length > 0">
        <div class="content-head">
          <base-button
            variant="primary"
            iconLeft="plus"
            @click="toggleModal"
            class="add-button"
            :disabled="categories.length >= 100"
          >{{ $t('askMe.categories.addButton') }}</base-button>

          <p v-if="categories.length >= 100" class="warning-message">
            <icon name="info-circle"/>
            <span>Достигнут лимит категорий</span>
          </p>
        </div>

        <div style="margin-top: 20px;">
          <table-container>
            <table-header>
              <table-cell size="20">{{ $t('askMe.categories.tableTitle') }}</table-cell>
              <table-cell size="40">{{ $t('askMe.categories.tableDescription') }}</table-cell>
              <table-cell size="10">{{ $t('askMe.categories.tableColor') }}</table-cell>
              <table-cell size="10">{{ $t('askMe.categories.minAmount') }}</table-cell>
              <table-cell size="10">{{ $t('askMe.categories.questionsCount') }}</table-cell>
              <table-cell size="10"></table-cell>
            </table-header>

            <table-row
              v-for="item in categories"
              :key="item.id"
              class="table-row"
            >
              <table-cell>{{ item.title }}</table-cell>
              <table-cell style="color: #838383;">{{ item.description }}</table-cell>
              <table-cell>
                <span class="tag-color" :style="{ backgroundColor: `#${ item.color }` }" />
              </table-cell>
              <table-cell style="color: #838383; white-space: nowrap;">{{ getAmountWithCurrency(item.min_amount, item.min_amount_currency) }}</table-cell>
              <table-cell style="color: #838383; white-space: nowrap;">{{ item.questions_num }}</table-cell>
              <table-cell class="ar" style="white-space: nowrap;">
                <button
                  class="action-btn"
                  @click="() => toggleModal(item.id)"
                ><icon name="pencil" /></button>

                <button
                  class="action-btn"
                  @click="() => toggleDeleteModal(item.id)"
                ><icon name="trash" /></button>
              </table-cell>
            </table-row>
          </table-container>
        </div>
      </div>

      <category-modal
        :isShow="showModal"
        :data="currentCategory"
        @toggleModal="toggleModal"
      />

      <modal v-model="showDeleteModal">
        <template slot="title">{{ $t('askMe.categories.deleteTitle') }}</template>

        <template slot="body-content">
          <div class="button-group" style="min-width: 300px;">
            <base-button
              variant="primary"
              @click="() => deleteCurrentCategory(currentId)"
            >{{ $t('askMe.categories.delete') }}</base-button>

            <base-button
              variant="secondary"
              @click="() => toggleDeleteModal()"
            >{{ $t('askMe.categories.undo') }}</base-button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from '@components/_BaseComponents/Modal'
import Stub from '@components/Dashboard/AskMe/Stub'
import BaseButton from '@components/_BaseComponents/BaseButton'
import Title from '@components/_BaseComponents/Title'
import CategoryModal from '@components/Dashboard/AskMe/CategoryModal'
import CategoryTable from '@components/Dashboard/AskMe/Table'
import currency from 'currency.js'

export default {
  name: 'AskMeCategoriesPage',

  components: {
    Stub,
    BaseButton,
    Title,
    CategoryModal,
    ...CategoryTable,
  },

  data() {
    return {
      showModal: false,
      currentCategory: null,

      showDeleteModal: false,
      currentId: null,
    }
  },

  watch: {},

  created() {
    this.getCategories()
  },

  computed: {
    ...mapGetters('askMe/questions', [
      'isCategoriesLoading',
      'categories',
    ]),

    ...mapGetters('currencies', [
      'getAmountWithCurrency',
    ]),
  },

  methods: {
    ...mapActions('askMe/questions', [
      'getCategories',
      'deleteCategory',
    ]),

    toggleModal(id) {
      if (id) {
        if (this.showModal) {
          this.currentCategory = null
          this.showModal = false
        } else {
          this.currentCategory = this.categories.filter(c => c.id === id)[0]
          this.showModal = true
        }
      } else {
        this.currentCategory = null
        this.showModal = !this.showModal
      }
    },

    toggleDeleteModal(id) {
      if (id) {
        this.currentId = id
        this.showDeleteModal = true
      } else {
        this.currentId = null
        this.showDeleteModal = false
      }
    },

    deleteCurrentCategory(id) {
      this.deleteCategory(id)
      this.toggleDeleteModal()
    },
  },
}
</script>

<style scoped lang="scss">
.stub__image {
  width: 100px;
  height: 100px;
  background-image: appAsset('/icons/askme_categories_stub.svg');
  background-position: center center;
  background-size: 100%;
  margin: 0 auto 15px auto;
}

.add-button {
  border-radius: 8px;
}

.action-btn {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0 8px;
  color: #838383;
  font-size: 18px;
  line-height: 1;

  &:hover {
    color: $color-purple;
  }
}

.table-row {
  margin-bottom: 10px;

  @media (min-width: 720px) {
    margin-bottom: 0;
  }
}

.ar {
   @media (min-width: 720px) {
     text-align: right;
   }
}

.tag-color {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.button-group {
  padding-bottom: 20px;
  margin-top: 40px;

  button {
    margin-right: 10px;
    border-radius: 8px;
  }
}

.content-head {
  // display: flex;

  p {
    color: #D63A3A;
    // margin-left: 15px;
    margin-top: 10px;
  }

  .warning-message {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }
  }
}
</style>
