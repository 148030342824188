<style scoped lang="scss">
  .top-questions {
    position: relative;

    .top-questions-controls {
      position: absolute;
      top: -40px;
      right: 0;
      display: flex;
      align-items: center;

      .top-questions-control + .top-questions-control {
        margin-left: 5px;
      }

      .top-questions-control {
        color: #838383;
        cursor: pointer;
        font-size: 18px;

        &:hover {
          color: #6C71D3;
        }
      }
    }

    .questions-slider-wrapper {
      overflow: hidden;
      @include margin-x(-30px);

      .questions-slider {
        overflow: auto;
        @include padding-x(30px);
        margin-bottom: -20px;
        padding-bottom: 20px;

        .questions-list {
          width: 1000px;
          display: flex;
          align-items: flex-start;

          .question + .question {
            margin-left: 20px;
          }

          .question {
            width: 320px;
            height: 204px;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="top-questions">
    <div class="top-questions-controls" v-if="!(topQuestionsIds.length < 2)">
      <div class="top-questions-control" @click="scrollSliderTo('start')">
        <icon name="angle-left"/>
      </div>
      <div class="top-questions-control" @click="scrollSliderTo('end')">
        <icon name="angle-right"/>
      </div>
    </div>
    <div class="questions-slider-wrapper">
      <div class="questions-slider" ref="slider">
        <div class="questions-list">
          <question
            v-for="(questionId, index) in topQuestionsIds"
            :key="questionId"
            :id="questionId"
            :position="index"
            top short
            @open="openQuestionModal(questionId, index)"/>
        </div>
      </div>
    </div>
    <question-modal v-model="questionModalOpened" :question-id="questionModalId"/>
  </div>
</template>

<script>

import Question from './Question'
import QuestionModal from '@components/AskMePage/Questions/QuestionModal'

export default {
  name: 'TopQuestion',
  components: { QuestionModal, Question },
  data() {
    return {
      questionModalOpened: false,
      questionModalId: null,
      questionModalPosition: null,
    }
  },
  computed: {
    ...mapState('askmePublic', ['topQuestionsIds']),
  },
  methods: {
    ...mapActions('askmePublic', ['fetchQuestions']),

    scrollSliderTo(position = 'start') {
      this.$refs.slider.scrollLeft = {
        start: 0,
        end: 1000,
      }[position]
    },

    openQuestionModal(id, position) {
      this.questionModalId = id
      this.questionModalPosition = position
      this.questionModalOpened = true
    },
  },
}
</script>
