<template>
  <div class="link">
    <input
      class="link__form"
      :value="src"
      readonly
    />

    <div class="link__button" @click="copyLink(src)">
      <icon name="copy" />
    </div>

    <transition name="fade">
      <div class="link__tooltip" v-if="tooltipVisible">
        <icon name="check" />
        <span>{{ $t('global.app.linkCopied') }}</span>
      </div>
    </transition>
  </div>
</template>


<script>
import { copyToClipboard } from '@utils/utils'

export default {

  name: 'Link',

  props: {
    src: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      tooltipVisible: false,
    }
  },

  methods: {
    copyLink(text) {
      copyToClipboard(text)

      if (!this.tooltipVisible) {
        this.tooltipVisible = true

        setTimeout(() => {
          this.tooltipVisible = false
        }, 2000)
      }
    },
  },
}
</script>


<style scoped lang="scss">
.link {
  background: $color-light-gray;
  border-radius: 8px;
  position: relative;

  &__form {
    position: relative;
    z-index: 1;
    display: block;
    border: 0;
    padding: 0;
    background: transparent;
    color: $color-purple;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 60px 12px 16px;
    width: 100%;
  }

  &__button {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 44px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $color-space-gray;
    background: $color-light-gray;
    border-radius: 0 8px 8px 0;
    cursor: pointer;

    &:hover {
      background: $color-space-gray;
    }

    & > svg {
      display: block;
      fill: $color-purple;
      height: 20px !important;
      width: 17px !important;
      cursor: pointer;
    }
  }

  &__tooltip {
    position: absolute;
    background: #191919;
    color: white;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 8px;
    top: -48px;
    white-space: nowrap;
    right: 0;
    user-select: none;
    pointer-events: none;

    &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: -16px;
      right: 12px;
      border-top: 8px solid #191919;
      border-bottom: 8px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    svg {
      width: 12px;
      margin-right: 10px;
    }

    span {
      vertical-align: middle;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
