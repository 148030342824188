import axios from 'axios'

class FrontConfig {
  constructor() {
    this.onConfigReady = new Promise((resolve, reject) => {
      this.resolveConfig = resolve

      console.log('[APP] Config resolved!')
    })
  }

  async fetchConfig() {
    const response = await axios.get('/api/v1/env/front')

    const {
      app_cdn,
      centrifugo,
      fonts_url,
      sentry,
      donationalerts,
      fantalks,
    } = response.data.data

    this.CDN = app_cdn
    this.centrifugoConfig = {
      endpoint: centrifugo.endpoint,
      subscribeEndpoint: centrifugo.subscribe_endpoint
    }
    this.fontsUrl = fonts_url
    this.sentryConfig = sentry
    this.DAHost = fantalks.app_url // donationalerts.app_url
    this.FTHost = fantalks.app_url

    this.resolveConfig()

    return this.onConfigReady
  }

  getFontsUrl() {
    return this.fontsUrl
  }

  getCDN() {
    return this.CDN
  }

  getCentrifugoConfig() {
    return this.centrifugoConfig
  }

  getSentryDsn(app) {
    return _.get(this.sentryConfig, `dsn.${app}`)
  }

  getDAHost() {
    return this.DAHost
  }

  getFTHost() {
    return this.FTHost
  }

  onReady() {
    return this.onConfigReady
  }
}

export default new FrontConfig()
