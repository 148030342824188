<style scoped lang="scss">
  .landing-guide {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-image: appAsset('/images/landing/guide/curve.svg');
    background-repeat: no-repeat;
    background-position: 40% 30%;

    &:before {
      content: "";
      position: absolute;
      left: -50%;
      z-index: -1;
      width: 750px;
      height: 750px;
      background: #C8BEF3;
      filter: blur(250px);
      border-radius: 100%;
      opacity: .4;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 664.33px;
      height: 535.43px;
      background: #E7C491;
      right: -50%;
      filter: blur(244px);
      transform: rotate(-15.06deg);
      border-radius: 100%;
      opacity: .4;
    }

    .guide-step {
      max-width: 310px;

      .step-image {
        height: 215px;
      }

      &.step-1 .step-image {
        padding-left: 48px;
      }

      &.step-2 .step-image {
        padding-left: 52px;
      }

      &.step-3 .step-image {
        padding-top: 40px;
        padding-left: 47px;
      }

      .step-title {
        display: flex;
        align-items: flex-start;

        .step-number {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          border-radius: 100%;
          color: $color-purple;
          background-color: rgba($color-purple, .2);
        }

        .step-title-text {
          max-width: 260px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          margin-left: 15px;
        }
      }

      .step-text {
        margin-left: 45px;
        max-width: 260px;
        margin-top: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $color-gray;
      }
    }

    @include media(max, lg) {
      flex-direction: column;
      align-items: center;
      background-image: none;
      @include padding-x(36px);

      &:before, &:after {
        display: none;
      }

      .guide-step + .guide-step {
        margin-top: 20px;
      }

      .guide-step {
        .step-image {
          height: auto;
          display: flex;
          justify-content: center;
        }

        .step-title {
          margin-top: 20px;
        }

        &.step-1 .step-image {
          padding-left: 0;
        }

        &.step-2 .step-image {
          padding-left: 0;
        }

        &.step-3 .step-image {
          padding-top: 0;
          padding-left: 0;
        }
      }
    }
  }
</style>

<template>
  <div class="landing-guide">
    <div class="guide-step step-1">
      <div class="step-image">
        <img :src="$asAppAsset('/images/landing/guide/step-1.svg')"/>
      </div>
      <div class="step-title">
        <div class="step-number">1</div>
        <div class="step-title-text">
          {{ $t('landing.guide.step1.title') }}
        </div>
      </div>
      <div class="step-text">
        {{ $t('landing.guide.step1.text') }}
      </div>
    </div>
    <div class="guide-step step-2">
      <div class="step-image">
        <img :src="$asAppAsset('/images/landing/guide/step-2.svg')"/>
      </div>
      <div class="step-title">
        <div class="step-number">2</div>
        <div class="step-title-text">
          {{ $t('landing.guide.step2.title') }}
        </div>
      </div>
      <div class="step-text">
        {{ $t('landing.guide.step2.text') }}
      </div>
    </div>
    <div class="guide-step step-3">
      <div class="step-image">
        <img :src="$asAppAsset('/images/landing/guide/step-3.svg')"/>
      </div>
      <div class="step-title">
        <div class="step-number">3</div>
        <div class="step-title-text">
          {{ $t('landing.guide.step3.title') }}
        </div>
      </div>
      <div class="step-text">
        {{ $t('landing.guide.step3.text') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LandingGuide',
}
</script>
