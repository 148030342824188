<template>
<div>
  <span
    class="event"
    v-if="text === 'initial'"
  >{{$t('balanceHistory.initial')}}</span>

  <span
    class="event"
    v-if="text === 'donation'"
  >{{$t('balanceHistory.donation')}}</span>

  <span
    class="event"
    v-if="text === 'payout'"
  >{{$t('balanceHistory.payout')}}</span>

  <span
    class="event"
    v-if="text === 'byadv'"
  >{{$t('balanceHistory.byadv')}}</span>

  <span
    class="event"
    v-if="text === 'bymerch'"
  >{{$t('balanceHistory.bymerch')}}</span>

  <span
    class="event"
    v-if="text === 'byonelink'"
  >{{$t('balanceHistory.byonelink')}}</span>

  <span
    class="event"
    v-if="text === 'partial'"
  >{{$t('balanceHistory.partial')}}</span>

  <span
    class="event"
    v-if="text === 'payoutreturn'"
  >{{$t('balanceHistory.payoutreturn')}}</span>

  <span
    class="event"
    v-if="text === 'cancel'"
  >{{$t('balanceHistory.cancel')}}</span>

  <span
    class="event"
    v-if="text === 'penalty'"
  >{{$t('balanceHistory.penalty')}}</span>

  <span
    class="event"
    v-if="text === 'alienvary'"
  >{{$t('balanceHistory.alienvary')}}</span>
</div>
</template>

<script>
export default {
  name: 'Event',

  props: {
    text: {
      type: String,
      require: true,
    },
  },
}

</script>

<style scoped lang="scss">
</style>
