export const state = {
  isQuestionsLoading: false,
  questions: [],
  questionsMeta: null,

  isQuestionLoading: null,

  isAnswersLoading: false,
  answers: [],
  answersMeta: null,

  isStatsLoading: false,
  stats: null,

  sendQuestionLoading: false,

  isCategoriesLoading: false,
  categories: [],

  addCategoryLoading: false,
}

export const getters = {
  isQuestionsLoading: ({ isQuestionsLoading }) => isQuestionsLoading,
  questions: ({ questions }) => questions,
  questionsMeta: ({ questionsMeta }) => questionsMeta,

  isQuestionLoading: ({ isQuestionLoading }) => isQuestionLoading,

  isAnswersLoading: ({ isAnswersLoading }) => isAnswersLoading,
  answers: ({ answers }) => answers,
  answersMeta: ({ answersMeta }) => answersMeta,

  isStatsLoading: ({ isStatsLoading }) => isStatsLoading,
  stats: ({ stats }) => stats,

  sendQuestionLoading: ({ sendQuestionLoading }) => sendQuestionLoading,

  isCategoriesLoading: ({ isCategoriesLoading }) => isCategoriesLoading,
  categories: ({ categories }) => categories,

  addCategoryLoading: ({ addCategoryLoading }) => addCategoryLoading,
}

export const mutations = {
  startQuestionsLoading(state) {
    state.isQuestionsLoading = true
  },

  endQuestionsLoading(state) {
    state.isQuestionsLoading = false
  },

  setQuestions(state, { data, meta }) {
    state.questions = [...data]
    state.questionsMeta = meta
  },

  addQuestion(state, { data, category_id }) {
    state.questions = [data, ...state.questions]
    state.stats.total_amount++

    if (category_id === null) {
      state.questionsMeta.filters.no_category_questions_num++
    } else {
      state.questionsMeta.filters.categories.filter(c => c.id === category_id)[0].questions_num++
    }
  },

  startAnswersLoading(state) {
    state.isAnswersLoading = true
  },

  endAnswersLoading(state) {
    state.isAnswersLoading = false
  },

  setAnswers(state, { data, meta }) {
    state.answers = [...data]
    state.answersMeta = meta
  },

  removeQuestion(state, { id }) {
    const category = state.questions.filter(el => el.id === id)[0].category

    state.questions = state.questions.filter(el => el.id !== id)
      
    if (category === null) {
      state.questionsMeta.filters.no_category_questions_num--
    } else {
      state.questionsMeta.filters.categories.filter(c => c.id === category.id)[0].questions_num--
    }
  },

  moveToArchive(state, { id }) {
    const category = state.questions.filter(el => el.id === id)[0].category

    state.questions = state.questions.filter(el => el.id !== id)
    state.stats.answered_amount++

    if (category === null) {
      state.questionsMeta.filters.no_category_questions_num--
    } else {
      state.questionsMeta.filters.categories.filter(c => c.id === category.id)[0].questions_num--
    }
  },

  updateArchiveAnswer(state, { id, content }) {
    state.answers.find(el => el.id === id).answer.content = content
  },

  startQuestionLoading(state, { id }) {
    state.isQuestionLoading = id
  },

  endQuestionLoading(state) {
    state.isQuestionLoading = null
  },

  startStatsLoading(state) {
    state.isStatsLoading = true
  },

  endStatsLoading(state) {
    state.isStatsLoading = false
  },

  setStats(state, { data }) {
    state.stats = data
  },

  startSendQuestionLoading(state) {
    state.sendQuestionLoading = true
  },

  endSendQuestionLoading(state) {
    state.sendQuestionLoading = false
  },

  startCategoriesLoading(state) {
    state.isCategoriesLoading = true
  },

  endCategoriesLoading(state) {
    state.isCategoriesLoading = false
  },

  setCategories(state, { data }) {
    state.categories = [...data]
  },

  startCategoryLoading(state) {
    state.addCategoryLoading = true
  },

  endCategoryLoading(state) {
    state.addCategoryLoading = false
  },

  addCategories(state, { data }) {
    state.categories.push(data)
  },

  removeCategories(state, { id }) {
    state.categories = state.categories.filter(item => item.id !== id)
  },

  updateCategories(state, { data }) {
    state.categories = state.categories.map(c => {
      if (c.id === data.id) {
        return data
      } else {
        return c
      }
    })
  },
}

export const actions = {
  async getQuestions({ commit }, { page, category } = {}) {
    try {
      commit('startQuestionsLoading')

      const params = {
        'statuses[]': 'unanswered',
        'page': page && page > 1 ? page : 1,
      }

      if (category !== null && category !== undefined && category >= 0) {
        params.category_id = +category
      }

      const { data, meta } = await api.get(`/askmequestion`, params)
      commit('setQuestions', { data, meta })

      commit('endQuestionsLoading')
    } catch (err) {
      commit('endQuestionsLoading')
    }
  },

  async getAnswers({ commit }, { page, category }) {
    try {
      commit('startAnswersLoading')

      const params = {
        'statuses[]': 'answered',
        'page': page && page > 1 ? page : 1,
      }

      if (category !== null && category !== undefined && category >= 0) {
        params.category_id = +category
      }

      const { data, meta } = await api.get(`/askmequestion`, params)
      commit('setAnswers', { data, meta })

      commit('endAnswersLoading')
    } catch (err) {
      commit('endAnswersLoading')
    }
  },

  async toAnswer({ commit }, { id, type, content }) {
    try {
      commit('startQuestionLoading', { id })
      const { data } = await api.post(`/askmequestion/${ id }/answer`, { type, content })

      commit('endQuestionLoading')
      commit('moveToArchive', { id: data.id })
    } catch (err) {
      commit('endQuestionLoading')
      window.addStatusMessage('error', 'Что-то пошло не так :(')
    }
  },

  async toEditAnswer({ commit }, { id, type, content }) {
    try {
      commit('startQuestionLoading', { id })
      const { data } = await api.put(`/askmequestion/${ id }/answer`, { type, content })

      commit('endQuestionLoading')
      commit('updateArchiveAnswer', { id, content })

      return data
    } catch (err) {
      commit('endQuestionLoading')
      window.addStatusMessage('error', 'Что-то пошло не так :(')

      return {}
    }
  },

  async toReport({ commit }, { id, reason }) {
    try {
      commit('startQuestionLoading', { id })
      await api.post(`/askmequestion/${ id }/complain`, { reason })
      commit('endQuestionLoading')
      commit('removeQuestion', { id })
    } catch (err) {
      commit('endQuestionLoading')
      window.addStatusMessage('error', 'Что-то пошло не так :(')
    }
  },

  async getStats({ commit }) {
    try {
      commit('startStatsLoading')

      const { data } = await api.get(`/askmequestion/stat`)
      commit('setStats', { data })

      commit('endStatsLoading')
    } catch (err) {
      commit('endStatsLoading')
    }
  },

  async sendQuestion({ commit }, { name, category, amount, amountCurrency, content, dateTime }) {
    const opt = {}
    if (name) opt.inquirer_name = name
    if (category) opt.category_id = category
    if (amount) opt.cost = amount
    if (amountCurrency) opt.cost_currency = amountCurrency
    if (content) opt.content = content
    if (dateTime) opt.created_at_ts = dateTime

    try {
      commit('startSendQuestionLoading')

      const { data } = await api.post(`/askmequestion`, opt)
      if (data.status === 'unanswered') commit('addQuestion', { data, category_id: category })

      commit('endSendQuestionLoading')
    } catch (err) {
      commit('endSendQuestionLoading')
    }
  },

  async getCategories({ commit }) {
    try {
      commit('startCategoriesLoading')

      const { data } = await api.get(`/askmequestioncategory`)

      commit('setCategories', { data })

      commit('endCategoriesLoading')
    } catch (err) {
      commit('endCategoriesLoading')
    }
  },

  async addCategory({ commit }, { title, description, color, min_amount, currency }) {
    const opt = {}
    if (title) opt.title = title
    if (description) opt.description = description
    if (color) opt.color = color
    if (min_amount) opt.min_amount = min_amount
    if (currency) opt.min_amount_currency = currency

    try {
      commit('startCategoryLoading')

      const { data } = await api.post(`/askmequestioncategory`, opt)

      commit('addCategories', { data })

      commit('endCategoryLoading')
    } catch (err) {
      commit('endCategoryLoading')
      window.addStatusMessage('error', 'Что-то пошло не так :(')
      throw new Error(err)
    }
  },

  async updateCategory({ commit }, { id, title, description, color, min_amount, currency }) {
    const opt = {}
    if (title) opt.title = title
    if (description) opt.description = description
    if (color) opt.color = color
    if (min_amount) opt.min_amount = min_amount
    if (currency) opt.min_amount_currency = currency

    try {
      commit('startCategoryLoading')

      const { data } = await api.put(`/askmequestioncategory/${ id }`, opt)

      commit('updateCategories', { data })

      commit('endCategoryLoading')
    } catch (err) {
      commit('endCategoryLoading')
      window.addStatusMessage('error', 'Что-то пошло не так :(')
      throw new Error(err)
    }
  },

  async deleteCategory({ commit }, id) {
    try {
      await api.delete(`/askmequestioncategory/${ id }`)

      commit('removeCategories', { id })
    } catch (err) {
      // ...
    }
  },
}