<style scoped lang="scss">
  .message-section {
    .question-helper {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="message-section">
    <text-editor/>
<!--    <helper-box variant="helper" class="question-helper">-->
<!--      {{ $t('askMe.question.textHelper', { name: getCreatorName }) }}-->
<!--    </helper-box>-->
  </div>
</template>

<script>

import TextEditor from './TextEditor'

export default {
  name: 'MessageSection',
  components: { TextEditor },
}
</script>
