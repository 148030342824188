<style lang="scss" scoped>
  .info-box {
    border-left: 3px solid var(--theme-color);
    padding: 15px 15px 15px 15px;
    box-shadow: 0 0 12px rgba(black, .06);
    border-radius: var(--block-border-radius);
    display: flex;
    align-items: flex-start;

    .box-icon {
      flex: 0 0 auto;
      font-size: 20px;
      line-height: 1;
      margin-right: 10px;
    }

    .box-content {
      flex: 0 1 auto;
      font-size: 15px;
    }

    &.box-error {
      border-left: 3px solid $pale-red;

      .box-icon {
        color: $pale-red;
      }
    }

    &.box-new-error {
      border-left: none;
      background: rgba(#CD3544, .1);
      color: #CD3544;

      .box-icon {
        color: #CD3544;
      }
    }
  }
</style>

<template>
  <div class="info-box" :class="classes">
    <icon
      v-if="icon"
      class="box-icon"
      :name="icon.name"
      :theme="icon.theme"
      :gradient="icon.gradient"/>
    <div class="box-content">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    variant: {
      type: String,
      default: 'info',
    },
  },
  data() {
    return {
      icons: {
        info: 'exclamation-circle',
        error: 'exclamation-triangle',
        'new-error': 'info',
      },
    }
  },
  computed: {
    classes() {
      return {
        [`box-${this.variant}`]: !!this.variant,
      }
    },

    iconName() {
      return this.icons[this.variant]
    },

    icon() {
      if (!this.iconName) {
        return null
      }

      return {
        name: this.iconName,
        theme: this.variant === 'info',
        gradient: this.variant === 'info',
      }
    },
  },
}
</script>
