<template>
  <div>
    <loader
      size="md"
      v-if="isGlobalLoading"
    />

    <div v-else>
      <div
        v-if="enabled"
        style="margin-top: 30px;"
      >
        <form-group
          :label="$t('askMe.settings.linkLabel')"
          inline
          v-if="profile.name"
        >
          <copy-link :src="publicPageLink" style="max-width: 420px;"/>
        </form-group>
        <p class="link-sign">{{ $t('askMe.settings.linkSign') }}</p>

        <Title style="margin-top: 40px;" type="2">{{ $t('askMe.settings.amountTitle') }}</Title>

        <form-group
          :label="$t('askMe.settings.minAmountLabel')"
          :description="$t('askMe.settings.minAmountSign')"
          inline
        >
          <input-addon v-model="minAmount" :addon="minAmountCurrency" style="max-width: 300px;"/>
        </form-group>

        <base-button
          style="margin-top: 30px;"
          variant="primary"
          :disabled="minAmount.length < 1 || minAmount === settings.public_question_min_amount"
          :isLoading="isButtonLoading"
          @click="changeSettings"
        >{{ $t('askMe.settings.save') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@components/_BaseComponents/Title'
import FormGroup from '@components/_BaseComponents/Form/FormGroup'
import BaseButton from '@components/_BaseComponents/BaseButton'
import BaseInput from '@components/_BaseComponents/Form/BaseInput'
import InputAddon from '@components/_BaseComponents/Form/InputAddon'
import BaseSwitch from '@components/_BaseComponents/BaseSwitch'

import CopyLink from '@components/Dashboard/AskMe/CopyLink'

export default {
  name: 'AskMeSettingsPage',

  components: {
    Title,
    FormGroup,
    BaseButton,
    BaseInput,
    InputAddon,
    BaseSwitch,
    CopyLink,
  },

  data() {
    return {
      enabled: null,
      minAmount: null,
      minAmountCurrency: null,
    }
  },

  metaInfo() {
    const title = [
      this.$t('dashboard.pages.askMe.settings'),
      this.$t('dashboard.pages.askMe.askMe'),
    ]

    return {
      title: title.join(' - '),
    }
  },

  watch: {
    settings(newVal, oldVal) {
      this.enabled = !!newVal.is_enabled
      this.minAmount = newVal.public_question_min_amount || 10
      this.minAmountCurrency = newVal.public_question_min_amount_currency || 'RUB'
    },

    enabled(newVal, oldVal) {
      if (oldVal !== null && this.settings.is_enabled !== newVal) {
        this.changeSettings()
      }
    },
  },

  created() {
    this.getSettings()
  },

  computed: {
    ...mapGetters('profile', {
      profile: 'getCurrentProfile',
    }),

    ...mapGetters('askMe/settings', [
      'isGlobalLoading',
      'settings',
      'isButtonLoading',
    ]),

    enabledOptions() {
      return [
        { value: false, label: this.$t(`askMe.settings.off`) },
        { value: true, label: this.$t(`askMe.settings.on`) },
      ]
    },

    publicPageLink() {
      return `${window.location.origin}/r/${this.profile.code}`
    },
  },

  methods: {
    ...mapActions('profile', ['fetchCurrentProfile']),

    ...mapActions('askMe/settings', ['getSettings', 'updateSettings']),

    changeSettings() {
      this.updateSettings({
        is_enabled: this.enabled,
        public_question_min_amount: this.minAmount,
        public_question_min_amount_currency: this.minAmountCurrency,
      })
    },
  },
}
</script>

<style scoped lang="scss">
  button {
    border-radius: 8px !important;
  }

  .link-sign {
    font-size: 13px;
    color: #838383;
    margin-top: 10px;
  }
</style>
