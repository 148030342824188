<style scoped lang="scss">
  .landing-action {
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #030521;
    overflow: hidden;

    @include media(max, lg) {
      height: 292px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 933px;
      height: 589px;
      left: 50%;
      top: 125px;
      transform: translateX(-50%);
      background: #772CF1;
      opacity: 0.3;
      filter: blur(792.87px);
    }

    .action-title {
      max-width: 700px;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-align: center;

      @include media(max, lg) {
        font-size: 30px;
        line-height: 36px;
        @include padding-x($gutter);
      }
    }

    .action-button {
      margin-top: 50px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: -45px;
        top: -33px;
        width: 45px;
        height: 37px;
        background-image: appAsset('/images/landing/sparks-2.svg');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
</style>

<template>
  <div class="landing-action">
    <action-title/>
    <div class="action-button">
      <btn variant="primary-new" size="big" @click="authorize">{{ $t('landing.startUsing') }}</btn>
    </div>
  </div>
</template>

<script>

import ActionTitle from '@components/LandingPage/ActionTitle'

export default {
  name: 'LandingAction',
  components: { ActionTitle },
  methods: {
    ...mapActions('viewer', ['showRegisterModal']),

    authorize() {
      this.$tmr.goal({ goal: 'startBtn_click' }, { url: '/' })
      this.$ga.view({ category: 'FTAuth', action: 'action_bt_getstarted_bttn' })

      this.$ga.event('action_bt_getstarted_bttn')

      this.showRegisterModal()
    },
  },
}
</script>
