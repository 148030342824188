<style scoped lang="scss">
  .terms-of-service {

  }
</style>

<script>

export default {
  name: 'TermsOfService',
  computed: {
    ...mapGetters('creator', ['getDonationButtonText']),
  },
  render: function (createElement) {
    const regex = /(<terms>.*<\/terms>)/gmu

    const elements = {
      terms: (contents) => createElement(
        'a',
        {
          domProps: {
            href: '/terms-of-service',
            target: '_blank',
          },
        },
        contents,
      ),
    }

    const contentString = this.$t('askMe.app.termsOfService', { buttonText: this.getDonationButtonText })
      .replaceAll(regex, '%$&%')

    const finalContents = contentString.split('%')

    finalContents.forEach((part, index) => {
      Object.keys(elements).forEach(key => {
        if (part.includes(`<${key}>`) && part.includes(`</${key}>`)) {
          const elementContents = part
            .replace(`<${key}>`, '')
            .replace(`</${key}>`, '')

          finalContents[index] = elements[key](elementContents)
        }
      })
    })

    return createElement(
      'div', {
        class: 'terms-of-service',
      },
      finalContents,
    )
  },
}
</script>
