import { mapFields } from '@utils/utils'

const menuSettingsFieldsMap = {
  dashboard: 'index',
  activityFeed: 'activity_feed',
  donationsSettings: 'donation_settings',
  donationPageSettings: 'donation_page',
  generalSettings: 'general_settings',
  payouts: 'payouts',
  offers: 'partnership',
  partnership: 'partnership_legacy',
  giveaways: 'giveaways',
  marketplace: 'marketplace',
  merchandise: 'merchandise',
  askMe: 'ask_me',
  sentDonations: 'sent_donations',
  widgetAlerts: 'widgets_alerts',
  widgetStreamStats: 'widgets_stream_stats',
  widgetDonationGoal: 'widgets_donation_goal',
  widgetMedia: 'widgets_media',
  widgetPoll: 'widgets_poll',
  widgetStickers: 'widgets_stickers',
  widgetCorona: 'widgets_corona',
  widgetRoulette: 'widgets_roulette',
  widgetNewYear: 'widgets_new_year',
  chatBotAccounts: 'chat_bot_accounts',
  chatBotMultichat: 'chat_bot_multichat',
  chatBotGames: 'chat_bot_games',
  chatBotTimers: 'chat_bot_timers',
  chatBotModerate: 'chat_bot_moderate',
  chatBotCommands: 'chat_bot_commands',
  chatBotPolls: 'chat_bot_polls',
  chatBotKappagen: 'chat_bot_kappagen',
  chatBotTicker: 'chat_bot_ticker',
  help: 'help',
}

export const state = {
  menuSettings: {},
}

export const getters = {}

export const mutations = {
  SET_MENU_SETTINGS(state, menuSettings) {
    state.menuSettings = menuSettings
  },
}

export const actions = {
  initDashboard({ state, commit, dispatch }) {
    dispatch('fetchDashboardInfo')
    dispatch('currencies/fetchCurrencies', null, { root: true })
  },

  fetchDashboardInfo({ commit }) {
    return api.get('/dashboardinfo')
      .then(({ data }) => {
        const { main_menu } = data
        const menuSettings = mapFields(main_menu, menuSettingsFieldsMap, true)

        commit('SET_MENU_SETTINGS', Object.keys(menuSettings).reduce((acc, key) => {
          acc[key] = true
          return acc
        }, {}))
      })
  },
}
