<template>
  <div class="data-grid">
    <slot />
  </div>
</template>

<script>
export default {
  // ...
}
</script>

<style scoped lang="scss">
.data-grid {
  width: 100%;
  font-size: 14px;
  line-height: 20px;

  @media (min-width: 720px) {
    display: table;
    border-collapse: separate;
    border-spacing: 0 0;
  }

  ::v-deep {
    .table-row:nth-child(even) {
      background-color: $color-light-gray;
      border-radius: 8px 0px 0px 8px;
    }
  }
}
</style>
