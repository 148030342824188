<template>
  <div class="stub">
    <slot name="image"></slot>

    <slot name="title"></slot>

    <div class="stub__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {

  name: 'Stub',

  props: {},

  data() {
    return {}
  },

}

</script>

<style scoped lang="scss">
.stub {
  max-width: 520px;
  text-align: center;
  margin: 40px auto 0;

  // &__image {
  //   width: 100px;
  //   height: 100px;
  //   background-image: appAsset('/icons/askme_stub.svg');
  //   background-position: center center;
  //   background-size: 100%;
  //   margin: 0 auto 15px auto;
  // }

  &__content {
    color: #838383;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 15px;

    a {
      white-space: nowrap;
      color: $color-purple;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
