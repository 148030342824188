var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{model:{value:(_vm.modalOpened),callback:function ($$v) {_vm.modalOpened=$$v},expression:"modalOpened"}},[_c('template',{slot:"title"},[(!!_vm.data)?[_vm._v(_vm._s(_vm.$t('askMe.categories.modalTitleEdit')))]:[_vm._v(_vm._s(_vm.$t('askMe.categories.modalTitleNew')))]],2),_c('template',{slot:"body-content"},[_c('div',[_c('form-group',{attrs:{"label":_vm.$t('askMe.categories.nameLabel') + '*',"inline":""}},[_c('base-input',{attrs:{"placeholder":_vm.$t('askMe.categories.namePlaceholder')},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('p',{staticClass:"input-description"},[_vm._v("Например: "),_vm._l((['о работе', 'личное', 'про деньги', 'детство']),function(word,i){return _c('span',{key:i,staticClass:"short-word",on:{"click":() => _vm.fillName(word)}},[_vm._v(_vm._s(word))])})],2)],1),_c('form-group',{attrs:{"label":_vm.$t('askMe.categories.descriptionLabel') + '*',"inline":""}},[_c('base-textarea',{attrs:{"rows":"3","placeholder":_vm.$t('askMe.categories.descriptionPlaceholder')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),(_vm.description)?_c('span',{staticClass:"ta-counter",class:{ 'ta-counter_red': _vm.description.length > 1000 }},[_vm._v(_vm._s(_vm.description.length)+" / 1000")]):_vm._e()],1),_c('form-group',{attrs:{"label":_vm.$t('askMe.categories.colorLabel') + '*',"inline":""}},[_c('div',{staticClass:"tag-list"},_vm._l(([
              'D72F18',
              'EE9033',
              'EDB01B',
              '90BF53',
              '559440',
              '94CFCA',
              '3D9FB4',
              '80C2E9',
              'B664BF',
              'DC3283',
              '60449A',
              '4853CA',
              'E0B285',
              '794A28',
              'C1C1C1',
              '464646',
            ]),function(tag,i){return _c('span',{key:i,staticClass:"tag-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.color),expression:"color"}],attrs:{"type":"radio","id":i},domProps:{"value":tag,"checked":_vm._q(_vm.color,tag)},on:{"change":function($event){_vm.color=tag}}}),_c('label',{style:({ backgroundColor: `#${ tag }` }),attrs:{"for":i}})])}),0)]),_c('form-group',{attrs:{"label":_vm.$t('askMe.categories.minAmountLabel'),"inline":""}},[_c('input-addon',{attrs:{"addon":(`${ _vm.minAmount }`.length) ? _vm.currency : ''},model:{value:(_vm.minAmount),callback:function ($$v) {_vm.minAmount=$$v},expression:"minAmount"}})],1),(false)?_c('div',{staticClass:"debug"},[_c('div',[_vm._v("title: "),_c('span',[_vm._v(_vm._s(_vm.title))])]),_c('div',[_vm._v("description: "),_c('span',[_vm._v(_vm._s(_vm.description))])]),_c('div',[_vm._v("color: "),_c('span',[_vm._v(_vm._s(_vm.color))])]),_c('div',[_vm._v("minAmount: "),_c('span',[_vm._v(_vm._s(_vm.minAmount))])]),_c('div',[_vm._v("currency: "),_c('span',[_vm._v(_vm._s(_vm.currency))])])]):_vm._e(),_c('div',{staticClass:"button-group"},[_c('base-button',{attrs:{"variant":"primary","iconLeft":"check","disabled":_vm.title.length < 1 || _vm.description.length < 1 || _vm.color.length < 1,"isLoading":_vm.addCategoryLoading},on:{"click":function($event){!!_vm.data ? _vm.saveHandler() : _vm.addHandler()}}},[_vm._v(_vm._s(!!_vm.data ? _vm.$t('askMe.categories.save') : _vm.$t('askMe.categories.add')))]),_c('base-button',{attrs:{"variant":"secondary"},on:{"click":_vm.undoClickHandler}},[_vm._v(_vm._s(_vm.$t('askMe.categories.undo')))])],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }