<style lang="scss" scoped>
  .amount-link {
    &.inline {
      display: inline;
    }

    span {
      border-bottom: 1px dashed rgba(var(--theme-color-rgb), .2);
      cursor: pointer;
      color: var(--theme-color);
      white-space: nowrap;

      &:hover {
        border-bottom: 1px dashed currentColor;
      }
    }
  }
</style>

<script>

export default {
  name: 'AmountLink',
  props: {
    inline: {
      type: Boolean,
      default: true,
    },
    amount: {
      type: [Number, String],
      required: true,
    },
    additive: {
      type: Boolean,
      default: false,
    },
    template: {
      type: String,
      default: null,
    },
  },
  render: function (createElement) {
    const linkContent = []

    const span = createElement(
      'span',
      {
        on: {
          click: this.onAmountClicked,
        },
      },
      this.linkText,
    )

    if (this.template && this.template.length) {
      const [prefix, postfix] = this.template.split('{amount}')

      linkContent.push(prefix, span, postfix)
    } else {
      linkContent.push(span)
    }

    return createElement(
      'div', {
        class: {
          'amount-link': true,
          inline: this.inline,
        },
      },
      linkContent,
    )
  },
  computed: {
    ...mapFields('donation', ['donationAmount']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    linkText() {
      let text = [this.getAmountWithCurrency(this.amount)]

      if (this.additive) {
        text.unshift('+')
      }

      return text.join(' ')
    },
  },
  methods: {
    ...mapMutations('donation', {
      addDonationAmount: 'ADD_DONATION_AMOUNT',
    }),

    onAmountClicked() {
      if (this.additive) {
        this.addDonationAmount(Number(this.amount))
      } else {
        this.donationAmount = Number(this.amount)
      }
    },
  },
}
</script>
