<style lang="scss" scoped>
  .date-picker {
    position: relative;

    .calendar-icon {
      margin-left: 15px;
      @include position(absolute);
      top: 13px;
      z-index: 4;
      font-size: 16px;
      pointer-events: none;
      color: $color-purple;
    }

    .base-input {
      padding-left: 43px;
      width: 150px;
    }

    .calendar {
      position: absolute;
      z-index: 51;
      bottom: 50px;
    }
  }
</style>

<template>
  <div class="date-picker" v-click-outside="onCalendarBlurred">
    <icon name="calendar" class="calendar-icon gradient"/>
    <base-input
      :class="inputClasses"
      :placeholder="placeholder"
      v-model="dateInput"
      v-mask="'##.##.####'"
      @mousedown="onInputFocused"
      @focus="onInputFocused"/>
    <transition name="fade-up">
      <calendar
        v-show="calendarFocused"
        :value="date"
        @mousedown="onCalendarFocused"
        @input="onCalendarPick"
        :years-range="yearsRange"
        :allowed="allowed"/>
    </transition>
  </div>
</template>

<script>
import Calendar from './Calendar'

export default {
  name: 'DatePicker',
  components: { Calendar },
  props: {
    value: {
      type: Number,
      default: null,
    },
    yearsRange: {
      type: Array,
      default: () => [1900, 2100],
    },
    placeholder: {
      type: String,
      default: '12.12.2020'
    },
    allowed: {
      type: Object,
      default: () => ({from: null, to: null}),
    },

  },
  data() {
    return {
      date: null,
      dateInput: null,

      calendarFocused: false,
    }
  },
  watch: {
    dateInput(input) {
      if (!input || input.length === 0) {
        this.date = null
        return
      } else if (input.length !== 10) {
        return
      }

      const [day, month, year] = input.split('.').map(Number)
      this.date = dates.timestamp(new Date(year, month - 1, day))
    },

    date(date) {
      this.dateInput = date ? dates.unixToFormat(date, 'dd.MM.yyyy') : ''
      this.$emit('input', date)
    },

    value: {
      handler(value) {
        this.date = value
      },
      immediate: true,
    },
  },
  computed: {
    inputClasses() {
      return {
        focus: this.calendarFocused,
      }
    },
  },
  methods: {
    onCalendarPick(date) {
      this.date = date

      this.calendarFocused = false
    },

    onInputFocused() {
      this.calendarFocused = true
    },

    onCalendarFocused() {
      this.calendarFocused = true
    },

    onCalendarBlurred() {
      this.calendarFocused = false
    },
  },
}
</script>
