<style scoped lang="scss">
  .category-select {
    position: relative;
    user-select: none;

    .filter-button {
      display: flex;
      align-items: center;
      align-self: start;
      cursor: pointer;

      .button-color {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        border-radius: 4px;
      }

      .button-color + .button-text {
        margin-left: 10px;
      }

      .button-text {
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .button-price {
        margin-left: auto;
        flex-shrink: 0;
        border-radius: calc(var(--control-border-radius) - 2px);
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--theme-color);
        background-color: rgba(var(--theme-color-rgb), .2);
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
      }

      .button-icon {
        flex-shrink: 0;
        margin-left: auto;
        font-size: 10px;
      }

      .button-price + .button-icon {
        margin-left: 10px;
      }
    }

    .filter-dropdown {
      width: 100%;
      margin-top: 15px;
      position: absolute;
      right: 0;
      z-index: 5;
      background: #FFFFFF;
      border: $control-border-width solid $control-border-color;
      border-radius: var(--control-border-radius);
      max-height: 400px;
      display: flex;
      flex-direction: column;

      .categories-list {
        padding: 12px 16px;
        overflow-y: auto;

        .category + .category {
          margin-top: 12px;
        }

        .category {
          display: flex;
          align-items: flex-start;
          cursor: pointer;

          .category-color {
            flex-shrink: 0;
            width: 13px;
            height: 13px;
            border-radius: 4px;
            margin-top: 4px;
          }

          .category-color + .category-title {
            margin-left: 10px;
          }

          .category-title {
            margin-right: 10px;
            font-size: 14px;
          }

          .category-price {
            margin-top: 1px;
            margin-left: auto;
            flex-shrink: 0;
            border-radius: calc(var(--control-border-radius) - 2px);
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--theme-color);
            background-color: rgba(var(--theme-color-rgb), .2);
            padding: 2px 5px;
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
          }

          &:hover .category-title {
            color: #6C71D3;
          }

          &.selected .category-title {
            color: #6C71D3;
          }
        }
      }
    }
  }
</style>

<template>
  <div v-if="getCategoriesList.length" class="category-select" v-click-outside="hideDropdown">
    <div class="filter-button form-control" @click="toggleDropdown">
      <div v-if="button.color" class="button-color" :style="{backgroundColor: button.color}"/>
      <div class="button-text">{{ button.text }}</div>
      <div v-if="button.price" class="button-price">{{ button.price }}</div>
      <icon name="angle-solid-down" theme gradient class="button-icon"/>
    </div>
    <transition name="fade">
      <div v-if="dropdownVisible" class="filter-dropdown">
        <div class="categories-list">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="category"
            :class="{selected: questionCategoryId === category.id}"
            @click="selectCategory(category.id)">
            <div v-if="category.color" class="category-color" :style="{backgroundColor: category.color}"/>
            <div class="category-title">{{ category.title }}</div>
            <div v-if="category.price" class="category-price">{{ category.price }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'CategorySelect',
  data() {
    return {
      dropdownVisible: false,
    }
  },
  computed: {
    ...mapGetters('askmePublic', ['getCategoriesList', 'getCategory']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),
    ...mapFields('askmePublic', ['questionCategoryId']),
    ...mapState('askmePublic', ['filterCategoryId']),
    ...mapState('currencies', ['currentCurrency']),

    categories() {
      return [
        {
          id: null,
          title: this.$t('askMe.questions.uncategorized'),
        },
        ..._.sortBy(this.getCategoriesList, c => -c.questionsNumber)
          .map(category => {
            return {
              ...category,
              price: this.getAmountWithCurrency(category.minimalAmounts[this.currentCurrency]),
            }
          }),
      ]
    },

    currentCategory() {
      const category = this.getCategory(this.questionCategoryId)

      if (!category) {
        return null
      }

      return {
        ...category,
        price: this.getAmountWithCurrency(category.minimalAmounts[this.currentCurrency]),
      }
    },

    buttonText() {
      if (this.questionCategoryId === null) {
        return this.$t('askMe.questions.uncategorized')
      }

      return this.currentCategory.title
    },

    button() {
      return {
        color: this.currentCategory ? this.currentCategory.color : null,
        text: this.buttonText,
        price: this.currentCategory ? this.currentCategory.price : null,
      }
    },
  },
  methods: {
    selectCategory(id) {
      this.questionCategoryId = id

      this.hideDropdown()
    },

    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible

      this.$tmr.goal({ goal: 'event_chng_category' })
    },

    hideDropdown() {
      this.dropdownVisible = false
    },
  },
  watch: {
    filterCategoryId(id) {
      this.questionCategoryId = id === 'all' ? null : id
    },
  },
}
</script>
