<style scoped lang="scss">
  .landing-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include media(max, lg) {
      @include padding-x($gutter);
    }

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 562px;
      height: 524px;
      background: #C5CFF5;
      filter: blur(250px);
      transform: rotate(43.61deg);
      border-radius: 100%;
      opacity: .4;
    }

    .hero-title {
      max-width: 620px;
      font-size: 45px;
      line-height: 54px;
      text-align: center;

      @include media(max, lg) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .hero-text {
      margin-top: 15px;
      max-width: 520px;
      text-align: center;
      color: $color-gray;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .hero-button {
      margin-top: 42px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: calc(100% + 15px);
        top: calc(50% - 10px);
        width: 86px;
        height: 51px;
        background-image: appAsset('/images/landing/arrow.svg');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
</style>

<template>
  <div class="landing-hero">
    <hero-title/>
    <div class="hero-text">
      {{ $t('landing.hero.text') }}
    </div>
    <div class="hero-button">
      <btn variant="primary-new" size="big" @click="authorize">{{ $t('landing.startUsing') }}</btn>
    </div>
  </div>
</template>

<script>

import HeroTitle from '@components/LandingPage/HeroTitle'

export default {
  name: 'LandingHero',
  components: { HeroTitle },
  methods: {
    ...mapActions('viewer', ['showRegisterModal']),

    authorize() {
      this.$tmr.goal({ goal: 'startBtn_click' }, { url: '/' })
      this.$ga.view({ category: 'FTAuth', action: 'action_bt_getstarted' })

      this.$ga.event('action_bt_getstarted')

      this.showRegisterModal()
    },
  },
}
</script>
