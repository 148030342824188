<style lang="scss" scoped>
  .currency-select {

  }
</style>

<template>
  <dropdown-select
    v-model="currentCurrency"
    :options="currencyOptions"
    @open="onCurrencySelectOpen"
    @select="onCurrencyOptionSelect"
    class="currency-select left"/>
</template>

<script>

export default {
  name: 'CurrencySelect',
  computed: {
    ...mapFields('currencies', ['currentCurrency']),
    ...mapGetters('currencies', ['payInCurrenciesList']),

    currencyOptions() {
      return this.payInCurrenciesList.map(({
        symbol,
        code,
      }) => ({
        label: `${symbol} (${code})`,
        value: code,
      }))
    },
  },
  methods: {
    onCurrencySelectOpen() {
      this.$ga.action({
        category: 'AskMeForm',
        action: 'btn_currency',
      })

      this.$tmr.goal({ goal: 'action_btn_currency' })
    },

    onCurrencyOptionSelect(value) {
      this.$ga.event({
        category: 'AskMeForm',
        action: 'chng_currency',
        label: value,
      })

      this.$tmr.goal({ goal: 'event_chng_currency' }, {
        currency: value,
      })
    },
  },
}
</script>
