<template>
  <div>
    <button
      class="list-item"
      :class="{ active: null === value || undefined === value }"
      @click="() => changeCategory(null)"
    >
      <span>{{ $t('askMe.questions.allCategories') }}</span>
    </button>

    <button
      class="list-item"
      :class="{
        active: '0' === `${ value }`,
        disabled: totalCount < 1,
      }"
      @click="() => changeCategory(0)"
    >
      <span>{{ $t('askMe.questions.uncategorized') }}</span>
      <span class="list-num">{{ totalCount }}</span>
    </button>

    <button
      v-for="item in list"
      :key="item.id"
      class="list-item"
      :class="{
        active: (`${ item.id }` === `${ value }`),
        disabled: item.questions_num < 1,
      }"
      @click="() => changeCategory(item.id)"
    >
      <span v-if="item.color" class="list-color" :style="{ backgroundColor: `#${ item.color }` }" />
      <span>{{ `${ item.id }` === '0' ? $t('askMe.questions.uncategorized') : item.title }}</span>
      <span v-if="item.questions_num" class="list-num">{{ item.questions_num }}</span>
    </button>
  </div>
</template>

<script>
export default {

  props: [
    'value',
    'list',
    'totalCount',
  ],

  data() {
    return {}
  },

  methods: {
    changeCategory(category) {
      this.$emit('input', category)
    },
  },

}

</script>

<style scoped lang="scss">

.list-item {
  margin-bottom: 15px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  color: $color-dark;
  position: relative;
  z-index: 1;
  border: 2px solid transparent;
  border-radius: 16px;
  background-color: $color-space-gray;

  &.active {
    border-color: $color-purple;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.list-color {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 10px;
}

.list-num {
  color: #838383;
  margin-left: auto;
}
</style>
