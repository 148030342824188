<style scoped lang="scss">
  .question-form {
    .viewer-section {
      margin-top: 15px;
    }

    .category-select + .message-section {
      margin-top: 15px;
    }

    .form-controls {
      margin-top: 30px;

      .control-buttons {
        display: flex;
        align-items: center;

        .button {
          width: 100%;
        }

        @include media(max, sm) {
          flex-direction: column;

          .button {
            width: 100%;
          }

          .button + .button {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }

      .controls-helper {
        margin-top: 15px;
      }
    }

    .question-error {
      margin-top: 20px;

      span {
        display: block;
        font-size: 13px;
      }
    }
  }
</style>

<template>
  <div class="question-form content-block short">
    <div class="content-block-title">
      {{ $t('askMe.questionForm.title') }}
    </div>
    <div class="content-block-body">
      <category-select/>
      <message-section/>
      <viewer-section/>
    </div>
    <div class="content-block-title">
      {{ $t('askMe.questionForm.amount') }}
    </div>
    <div class="content-block-body">
      <amount-section/>

      <info-box v-if="errors.length" class="question-error" variant="error">
        <span
          v-for="e in errors"
          :key="e.param"
        >{{ e.message }}</span>
      </info-box>
      <div class="form-controls">
        <div class="control-buttons">
          <btn
            :disabled="!(questionText.length && viewerAlias.length)"
            variant="primary"
            icon-left="gift"
            :is-loading="loadingState.send"
            @click="sendQuestion">
            {{ $t('askMe.questionForm.askButton') }}
          </btn>
        </div>
        <helper-box class="controls-helper">
          <terms-of-service/>
        </helper-box>
      </div>
    </div>
  </div>
</template>

<script>

import ViewerSection from './ViewerSection/ViewerSection'
import AmountSection from './AmountSection/AmountSection'
import MessageSection from './MessageSection/MessageSection'
import TermsOfService from './TermsOfService'
import CategorySelect from '@components/AskMePage/QuestionForm/CategorySelect'

export default {
  name: 'QuestionForm',
  components: {
    CategorySelect,
    TermsOfService,
    ViewerSection,
    AmountSection,
    MessageSection,
  },
  data() {
    return {
      loadingState: {
        send: false,
      },

      errors: [],
    }
  },
  computed: {
    ...mapGetters('donation', ['missingAmountsActual']),
    ...mapState('askmePublic', ['questionText']),
    ...mapFields('viewer', ['viewerAlias']),
  },
  methods: {
    ...mapActions('payment', ['fetchPaymentMethods']),
    ...mapActions('invoice', ['validateAskmeInvoicePayload']),

    onDonationLinkClick() {
      this.$ga.action({
        category: 'AskMeForm',
        action: 'btn_donation',
      })
    },

    sendQuestion() {
      this.$ga.action({
        category: 'AskMeForm',
        action: 'btn_question_send',
      })

      this.$tmr.goal({ goal: 'action_btn_question_send' })

      // if (!this.donationIsValid) {
      //   return this.validationModalOpened = true
      // }

      this.loadingState.send = true

      this.validateAskmeInvoicePayload()
        .then((data) => {
          return this.fetchPaymentMethods()
            .then(() => {
              this.loadingState.send = false

              this.$router.push({
                name: 'payment-sub-page',
              })
            })
        })
        .catch(({ errors }) => {
          if (errors && errors.length) {
            this.errors = [...errors]
          }
        })
        .finally(() => {
          this.loadingState.send = false
        })
    },
  },
  watch: {
    questionText() {
      this.$delete(this.errors, 'question')
    },
  },

  mounted() {
    this.$tmr.goal({ goal: 'view_form' })
  },
}
</script>
