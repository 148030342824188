<style scoped lang="scss">
  .payment-methods {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;

    @include media(max, lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media(max, sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    .methods-additional-button, .payment-method {
      height: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $line-color;
      border-radius: 10px;

      background-color: white;
      cursor: pointer;
      user-select: none;
      transition: $control-transition;

      &:hover {
        border-color: $line-hover-color;
      }
    }

    .methods-additional-button {
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      @include padding-x(30px);
    }

    .payment-method {
      &.active {
        border-color: var(--theme-color);
        background-color: rgba(var(--theme-color-rgb), .05);
      }

      .method-icon {
        .icon {
          height: 32px;
          width: 100%;
        }
      }

      .method-description {
        margin-top: 10px;
        font-size: 11px;
        text-align: center;
        @include padding-x(12px);
      }

      .method-min-amount {
        font-size: 11px;
        color: rgba(black, .5);
        text-align: center;
      }
    }
  }
</style>

<template>
  <div class="payment-methods">
    <div
      v-for="method in paymentMethods"
      :key="method.code"
      class="payment-method"
      :class="{active: paymentMethod === method.code}"
      @click="selectPaymentMethod(method.code)">
      <div class="method-icon">
        <icon :name="method.icon" :inline="false"/>
      </div>
      <div v-if="method.title" class="method-description">
        {{ method.title }}
      </div>
      <div v-if="method.displayMinAmount" class="method-min-amount">
        {{ method.minAmountText }}
      </div>
    </div>
    <div
      v-if="paymentMethod"
      class="methods-additional-button"
      @click="clearMethodSelection">
      {{ $t('payment.otherPaymentMethods') }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentMethods',
  computed: {
    ...mapGetters('currencies', ['getAmountWithCurrency']),
    ...mapState('donation', ['donationAmount']),
    ...mapFields('payment', ['paymentMethod']),
    ...mapGetters('payment', ['getPaymentMethod', 'paymentMethodsList', 'selectedPaymentMethod']),

    paymentMethodsFiltered() {
      if (this.paymentMethod && this.paymentMethodsList.length) {
        const additionalMethodsListSize = {
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
        }

        return [
          this.selectedPaymentMethod,
          ...this.paymentMethodsList.filter(method => method.code !== this.paymentMethod),
        ].slice(0, additionalMethodsListSize[this.$appSize])
      }

      return this.paymentMethodsList
    },

    paymentMethods() {
      return this.paymentMethodsFiltered.map(method => {

        const minAmount = this.getAmountWithCurrency(method.minAmount)

        return {
          ...method,
          displayMinAmount: this.donationAmount < method.minAmount,
          minAmountText: this.$t('payment.methodMinimalAmount', { amount: minAmount }),
        }
      })
    },
  },
  methods: {
    selectPaymentMethod(method) {
      this.paymentMethod = method

      this.$ga.action({
        category: 'DonationPage-PaymentMethods',
        action: `btn_payment_${method}`,
      })
    },

    clearMethodSelection() {
      this.paymentMethod = null

      this.$ga.action({
        category: 'DonationPage-PaymentMethods',
        action: 'btn_more',
      })
    },
  },
}
</script>
