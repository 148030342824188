import debounce from 'lodash/debounce'
import range from 'lodash/range'
import random from 'lodash/random'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import isObject from 'lodash/isObject'
import head from 'lodash/head'
import last from 'lodash/last'
import map from 'lodash/map'
import sample from 'lodash/sample'
import max from 'lodash/max'
import min from 'lodash/min'
import forEach from 'lodash/forEach'
import throttle from 'lodash/throttle'
import isNumber from 'lodash/isNumber'
import round from 'lodash/round'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import findLastIndex from 'lodash/findLastIndex'
import sum from 'lodash/sum'
import intersection from 'lodash/intersection'

export default {
  debounce,
  range,
  random,
  keyBy,
  groupBy,
  isObject,
  head,
  last,
  map,
  sample,
  max,
  min,
  forEach,
  throttle,
  isNumber,
  round,
  uniq,
  sortBy,
  get,
  set,
  cloneDeep,
  isNil,
  findLastIndex,
  sum,
  intersection,
}
