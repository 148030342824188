<style lang="scss" scoped>
  .dashboard {
    font-size: 14px;
    color: $color-dark;

    .shutdown-message {
      z-index: 100;
      width: 100%;
    }
  }

  .dashboard-header {
    width: 100%;
    position: fixed;
    z-index: 49;
  }

  .dashboard-content {
    @include margin-x(auto);
    padding-top: $dashboard-header-height;
    display: flex;
    align-items: flex-start;

    .menu-wrapper {
      align-self: stretch;
      position: relative;
      min-width: $menu-width;

      @include media(max, lg) {
        display: none;
      }

      .dashboard-menu {
        width: $menu-width;
        position: fixed;
        flex-grow: 0;
        z-index: 48;
        padding-top: 10px;
        padding-bottom: 25px;
      }
    }

    .page-content {
      flex-grow: 1;
      width: 100%;
      margin-top: 10px;
      min-width: 0;
      padding-bottom: 50px;

      @include media(max, lg) {
        margin-left: 0;
      }
    }

    .menu-wrapper + .page-content {
      margin-left: $dashboard-content-gap;
    }
  }
</style>

<template>
  <div class="dashboard">
    <shutdown-message/>

    <dashboard-header/>

    <container>
      <div class="dashboard-content">
        <div v-if="$appIsXL" class="menu-wrapper">
          <dashboard-menu/>
        </div>

        <div class="page-content">
          <div class="legacy-notifications" ref="legacy"/>
          <router-view :key="$router.path"/>
        </div>
      </div>
    </container>

    <container>
      <landing-footer/>
    </container>
  </div>
</template>

<script>

import DashboardMenu from '@components/Dashboard/DashboardMenu'
import DashboardHeader from '@components/Dashboard/DashboardHeader/DashboardHeader'
import LandingFooter from '@components/LandingPage/LandingFooter'
import axios from 'axios'
import $ from 'jquery'
import ShutdownMessage from '@components/ShutdownMessage'

export default {
  name: 'Dashboard',
  components: {
    ShutdownMessage,
    DashboardHeader,
    DashboardMenu,
    LandingFooter,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('profile', ['getUserIsStreamer']),
  },
  methods: {
    ...mapActions('dashboard', ['initDashboard']),
  },
  created() {
    this.initDashboard()
  },
}
</script>
