<style scoped lang="scss">
  .questions-placeholder {
    padding-top: 80px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar-wrapper {
      position: relative;

      .icon {
        position: absolute;
        font-size: 66px;

        &.right-icon {
          top: -20px;
          right: -56px;
        }

        &.left-icon {
          bottom: 0;
          left: -56px;
        }
      }

      .creator-avatar {
        width: 95px;
        height: 95px;
        border-radius: 100%;
      }
    }

    .questions-empty-text {
      margin-top: 20px;
      font-size: 13px;
      line-height: 18px;
      color: #464646;
    }
  }
</style>

<template>
  <div class="questions-placeholder">
    <div class="avatar-wrapper">
      <icon name="askme-question-bubble" class="left-icon"/>
      <icon name="askme-exclamation-bubble" class="right-icon"/>
      <img class="creator-avatar" :src="creatorInfo.avatar"/>
    </div>
<!--    <div class="questions-empty-text">-->
<!--      {{ $t('askMe.placeholder.text', { name: getCreatorName }) }}-->
<!--    </div>-->
  </div>
</template>

<script>

export default {
  name: 'QuestionsPlaceholder',
  computed: {
    ...mapGetters('creator', ['getCreatorName']),
    ...mapState('creator', ['creatorInfo']),
  },
}
</script>
