<template>
  <span
    class="dashboard__title"
    :class="'dashboard__title_' + type"
  >
    <slot />
  </span>
</template>

<script>

export default {
  name: 'Title',

  props: {
    type: {
      type: [String, Number],
      default: 1,
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard__title {
  font-family: inherit;
  display: inline-block;
  vertical-align: middle;
  cursor: default;

  &_1 {
    color: $color-dark;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px !important;
  }

  &_2 {
    color: $color-dark;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  &_3 {
    color: $color-dark;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  &_4 {}

  &_5 {
    color: #838383;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 15px !important;
  }
}
</style>
