<style scoped lang="scss">
  .payouts-title {
    .title-highlight {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -6px;
        width: 145px;
        height: 6px;
        background-image: appAsset('/images/landing/underline.svg');
      }
    }
  }
</style>

<script>

export default {
  name: 'PayoutsTitle',
  render: function (createElement) {
    const regex = /(<highlight>.*<\/highlight>)/gmu

    const elements = {
      highlight: (contents) => createElement(
        'span',
        {
          class: 'title-highlight',
        },
        contents,
      ),
    }

    const contentString = this.$t('landing.payouts.title').replaceAll(regex, '%$&%')

    const finalContents = contentString.split('%')

    finalContents.forEach((part, index) => {
      Object.keys(elements).forEach(key => {
        if (part.includes(`<${key}>`) && part.includes(`</${key}>`)) {
          const elementContents = part
            .replace(`<${key}>`, '')
            .replace(`</${key}>`, '')

          finalContents[index] = elements[key](elementContents)
        }
      })
    })

    return createElement(
      'div', {
        class: 'payouts-title',
      },
      finalContents,
    )
  },
}
</script>
