<template>
  <div
    class="data-grid__col"
    :style="{width: size === 'auto' ? 'auto' : size + '%'}"
    :data-title="title"
    ref="tttest"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataGridCol',
  props: {
    size: {
      type: [Number, String],
      default: 'auto',
    },
  },
  data() {
    return {
      title: '',
    }
  },
  mounted() {
    const currentEl = this.$refs.tttest
    const index = [...currentEl.parentNode.children].indexOf(currentEl)
    const cell = [...currentEl.parentNode.parentNode.firstChild.firstChild.children][index]

    if (!cell) return null

    this.title = cell.textContent
  },
}

</script>

<style scoped lang="scss">
.data-grid {
  &__col {
    padding: 10px;
    vertical-align: top;

    &:before {
      content: attr(data-title);
      display: block;
      color: #838383;
      cursor: default;
      user-select: none;
      margin-bottom: 5px;

      @media (min-width: 720px) {
        display: none;
      }
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    @media (min-width: 720px) {
      // vertical-align: middle;
      padding: 20px 10px;
      display: table-cell;

      &:first-child {
        padding-left: 20px;
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        padding-right: 20px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
}
</style>
