import VueRouter from 'vue-router'

import AskmePublicPage from '@pages/AskmePublic/AskmePublicPage'
import QuestionsPage from '@pages/AskmePublic/QuestionsPage'
import LatestQuestionsPage from '@pages/AskmePublic/LatestQuestionsPage'
import PaymentSubPage from '@pages/AskmePublic/PaymentSubPage'
import SuccessSubPage from '@pages/AskmePublic/SuccessSubPage'
import LandingPage from '@pages/AskmePublic/LandingPage'

import ShutdownPage from '@pages/AskmePublic/ShutdownPage'

//// NEW DASHBOARD
import RenderView from '@pages/_RenderView'

// General pages
import Dashboard from '@pages/Dashboard/Dashboard'
import PayoutsPage from '@pages/Dashboard/PayoutsPage'
import GeneralSettingsPage from '@pages/Dashboard/GeneralSettingsPage'
import BalanceHistoryPage from '@pages/Dashboard/BalanceHistoryPage'

// AskMe pages
import AskMePage from '@pages/Dashboard/AskMe/AskMePage'
import AskMeQuestionsPage from '@pages/Dashboard/AskMe/AskMeQuestionsPage'
import AskMeArchivePage from '@pages/Dashboard/AskMe/AskMeArchivePage'
import AskMeSettingsPage from '@pages/Dashboard/AskMe/AskMeSettingsPage'
import AskMeCategoriesPage from '@pages/Dashboard/AskMe/AskMeCategoriesPage'
import store from '@state/store'
import cookies from '@utils/cookies'

const shutdownRoutes = [
  {
    path: '*',
    component: ShutdownPage,
  },
]

const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: LandingPage,
  },
  {
    path: '/r/:slug',
    props: true,
    component: AskmePublicPage,
    children: [
      {
        path: '/r/:slug',
        name: 'askme-page',
        meta: {
          questionForm: true,
          tutorial: true,
        },
        component: QuestionsPage,
      },
      {
        path: '/r/:slug/questions',
        name: 'latest-questions-page',
        props: (route) => ({ page: route.query.p }),
        meta: {
          questionForm: true,
          tutorial: true,
        },
        component: LatestQuestionsPage,
      },
      // {
      //   path: '/r/:slug/payment',
      //   name: 'payment-sub-page',
      //   component: PaymentSubPage,
      // },
      // {
      //   path: '/r/:slug/success',
      //   name: 'success-sub-page',
      //   component: SuccessSubPage,
      // },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      authRequired: true,
      profileRequired: true,
    },
    redirect: { name: 'dashboard-askme' },
    component: Dashboard,
    children: [
      {
        name: 'dashboard-askme',
        path: '',
        meta: { allowedRoles: ['askme-responder'] },
        component: AskMePage,
        redirect: { name: 'dashboard-askme-questions' },
        children: [
          {
            name: 'dashboard-askme-questions',
            path: 'questions',
            component: AskMeQuestionsPage,
          },
          {
            name: 'dashboard-askme-archive',
            path: 'archive',
            component: AskMeArchivePage,
          },
          {
            name: 'dashboard-askme-categories',
            path: 'categories',
            component: AskMeCategoriesPage,
          },
          {
            name: 'dashboard-askme-settings',
            path: 'settings',
            component: AskMeSettingsPage,
          },
        ],
      },

      {
        name: 'dashboard-payments',
        path: 'payments',
        meta: { allowedRoles: ['askme-responder'] },
        component: PayoutsPage,
      },
      {
        name: 'dashboard-balance-history',
        path: 'balance-history',
        meta: { allowedRoles: ['askme-responder'] },
        component: BalanceHistoryPage,
      },
      {
        name: 'dashboard-account-settings',
        path: 'account-settings',
        meta: { allowedRoles: ['askme-responder', 'viewer'] },
        component: GeneralSettingsPage,
      },

      {
        name: 'dashboard-404',
        path: '*',
        component: RenderView,
        redirect: { name: 'dashboard' },
      },
    ],
  },
]

const createRouter = () => {
  const router = new VueRouter({
    routes: shutdownRoutes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return {
          x: 0,
          y: 0,
        }
      }
    },
  })

  router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
      await store.dispatch('profile/authenticate')
    }

    if (to.matched.some(record => record.meta.profileRequired)) {
      await store.dispatch('profile/fetchCurrentProfile')
    }

    const routeWithRoles = to.matched.find(r => r.meta.allowedRoles && r.meta.allowedRoles.length)

    const userRoles = store.getters['profile/getUserRoles'] ?? []

    if (routeWithRoles) {
      const { allowedRoles } = routeWithRoles.meta

      if (allowedRoles.length) {
        if (!_.intersection(allowedRoles, userRoles).length) {
          return next({ name: 'landing-page' })
        }
      }
    }

    next()
  })

  router.onError(error => {
    console.log(error)
  })

  return router
}

export default createRouter
