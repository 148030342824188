<style scoped lang="scss">
  .question {
    background-color: #F1F4F6;
    border-radius: 16px;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: flex-start;

    .inquirer-avatar {
      flex: 0 0 auto;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .question-body {
      min-width: 0;

      .question-inquired {
        padding-left: 12px;
        display: flex;
        align-items: flex-start;
        font-size: 13px;
        line-height: 18px;

        .question-inquired-by {
          font-weight: 500;
        }

        .question-inquired-at {
          white-space: nowrap;
          margin-left: 10px;
          color: #838383;
        }

        @include media(max, sm) {
          padding-right: 80px;
          flex-direction: column;

          .question-inquired-at {
            margin-left: 0;
            margin-top: 2px;
          }
        }
      }

      .question-category {
        margin-top: 6px;
        padding-left: 12px;
        display: flex;
        align-items: center;

        .category-color {
          width: 14px;
          height: 14px;
          border-radius: 4px;
        }

        .category-title {
          margin-left: 10px;
        }
      }

      .question-text {
        padding-right: 80px;
        margin-top: 6px;
        padding-left: 12px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: #191919;
        word-break: break-word;

        @include media(max, sm) {
          padding-right: 0;
        }
      }

      .question-empty {
        margin-top: 6px;
        padding-left: 12px;
        font-size: 14px;
        line-height: 20px;
        color: #838383;
      }

      .question-answer {
        margin-top: 16px;
        display: flex;
        align-items: flex-start;

        @include media(max, sm) {
          margin-left: -36px;
        }

        .creator-avatar {
          flex: 0 0 auto;
          width: 36px;
          height: 36px;
          border-radius: 100%;
          object-fit: cover;
        }

        .question-answer-body {
          min-width: 0;
          margin-left: 12px;

          .question-answered {
            font-size: 14px;
            color: #838383;

            .creator-name {
              color: #191919;
            }
          }

          .question-answer-text {
            font-size: 14px;
            line-height: 20px;
            word-break: break-word;
          }
        }
      }
    }

    .question-price {
      margin-left: auto;
      border-radius: 8px;
      padding: 6px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #151515;
      white-space: nowrap;
      background: #FFFFFF;

      position: absolute;
      right: 20px;
      top: 20px;

      &.top-1 {
        background: linear-gradient(260.29deg, #FFC450 4.22%, #FBDA31 124.49%);
      }

      &.top-2 {
        background: linear-gradient(260.29deg, #BBC6D9 4.22%, #E0EAF5 124.49%);
      }

      &.top-3 {
        background: linear-gradient(260.29deg, #F9AE56 4.22%, #FFD6BF 124.49%);
      }
    }

    .clamped-text {
      max-height: 60px;
      overflow: hidden;
      position: relative;

      &.clamped {
        .text-clamp {
          display: block;
          color: #F57D07;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: right;
          white-space: nowrap;
          background-image: linear-gradient(to left, rgba(247, 247, 247, 1) 50px, rgba(247, 247, 247, 0) 220px);
          cursor: pointer;
        }
      }
    }

    &.top.short {
      .clamped-text {
        max-height: 40px;

        &.clamped {
          .text-clamp {
            padding-left: 140px;
          }
        }
      }
    }

    &.short {
      .question-body {
        .question-inquired {
          flex-direction: column;

          .question-inquired-at {
            margin-left: 0;
            margin-top: 2px;
          }
        }

        .question-text {
          padding-right: 0;
        }

        .question-answer {
          margin-left: -36px;

          .question-answer-body {
            .question-answer-text {

            }
          }
        }
      }
    }
  }
</style>

<template>
  <div v-if="question" class="question" :class="{short, top}">
    <img
      v-if="question.inquirer.avatar"
      class="inquirer-avatar"
      :src="question.inquirer.avatar"/>
    <div
      v-else
      class="inquirer-avatar"
      :style="{backgroundColor: question.inquirer.color}">
      {{ question.inquirer.shortName }}
    </div>
    <div class="question-body">
      <div class="question-inquired">
        <div class="question-inquired-by">
          {{ question.inquirer.name }}
        </div>
        <div class="question-inquired-at">
          {{ question.date }}
        </div>
      </div>
      <div v-if="!top && category" class="question-category">
        <div class="category-color" :style="{backgroundColor: category.color}"/>
        <div class="category-title">{{ category.title }}</div>
      </div>
      <div
        v-if="question.text"
        class="question-text"
        :class="{clamped: qtClamped, 'clamped-text': clamped}">
        <div ref="questionText">{{ question.text }}</div>
        <div v-if="qtClamped" class="text-clamp" @click="readMore">{{
            $t('askMe.questionList.more')
          }}
        </div>
      </div>
      <div v-else class="question-empty">
        {{ $t('askMe.questionList.questionEmpty') }}
      </div>
      <div v-if="question.answer" class="question-answer">
        <img class="creator-avatar" :src="creatorInfo.avatar"/>
        <div class="question-answer-body">
          <div class="question-answered">
            {{$t('askMe.question.answerFrom')}} <span class="creator-name">{{ getCreatorName }}</span>
          </div>
          <div
            class="question-answer-text"
            :class="{clamped: qaClamped, 'clamped-text': clamped}">
            <div ref="questionAnswer">{{ question.answer }}</div>
            <div v-if="qaClamped" class="text-clamp" @click="readMore">
              {{ $t('askMe.questionList.more') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="question-price" :class="question.priceClass">
      {{ question.price }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'Question',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    position: {
      type: [String, Number],
      default: null,
    },
    top: {
      type: Boolean,
      default: false,
    },
    short: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clamped: true,
      qaClamped: false,
      qtClamped: false,
    }
  },
  created() {
    if (this.full) {
      this.clamped = false
    }
  },
  mounted() {
    this.calculateTextHeight()
  },
  computed: {
    ...mapGetters('askmePublic', ['getQuestion', 'getCategory']),
    ...mapGetters('creator', ['getCreatorName']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),
    ...mapState('creator', ['creatorInfo']),

    question() {
      const question = this.getQuestion(this.id)

      return {
        ...question,
        price: this.getAmountWithCurrency(question.amount, question.currency),
        priceClass: {
          [`top-${this.position + 1}`]: this.top,
        },
      }
    },

    category() {
      return this.getCategory(this.question.categoryId)
    },
  },
  methods: {
    readMore() {
      if (this.short) {
        this.$emit('open')
      } else {
        this.clamped = false
        this.qaClamped = false
        this.qtClamped = false
      }
    },

    calculateTextHeight() {
      if (!this.clamped) {
        return
      }

      const questionText = this.$refs.questionText

      if (questionText) {
        const height = questionText.getBoundingClientRect().height
        const parentHeight = questionText.parentNode.getBoundingClientRect().height

        if (height > parentHeight) {
          this.qtClamped = true
          this.clamped = true
        }
      }

      const questionAnswer = this.$refs.questionAnswer

      if (questionAnswer) {
        const height = questionAnswer.getBoundingClientRect().height
        const parentHeight = questionAnswer.parentNode.getBoundingClientRect().height

        if (height > parentHeight) {
          this.qaClamped = true
          this.clamped = true
        }
      }
    },
  },
  watch: {
    $appWidth: 'calculateTextHeight',
  },
}
</script>
