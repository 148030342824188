<style lang="scss" scoped>
  .general-settings-page {

  }
</style>

<template>
  <div class="general-settings-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>

import $ from 'jquery'

import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'GeneralSettingsPage',
  data() {
    return {
      loadingState: {
        page: false,
      },

      currentTab: 'account',
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.generalSettings.generalSettings'),
    }
  },
  computed: {
    ...mapGetters('profile', ['getUserIsStreamer', 'getUserIsViewer']),
  },
  methods: {
    loadContent() {
      this.$nextTick(() => {
        this.loadingState.page = true

        if (this.currentTab === 'account') {
          loadLegacyPage('/dashboard/general', this.$refs.legacy, () => {
            $('#_1337block-authorized-apps').remove()
          })
            .then(() => {
              $('#_1337block-connections').hide()
              $('#_1337block-black-list').hide()
              $('#_1337block-token-container').hide()
              $('#delete-account').hide()
            })
            .finally(() => {
              this.loadingState.page = false
            })
        }
      })
    },
  },
  mounted() {
    this.loadContent()
  },
  watch: {
    currentTab: 'loadContent',
  },
}
</script>
