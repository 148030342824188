<style scoped lang="scss">
  .action-title {
    color: #FFFFFF;

    .title-highlight {
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 155px;
        height: 89px;
        margin-top: 5px;
        margin-left: 5px;
        background-image: appAsset('/images/landing/speech-bubble.svg');
        background-repeat: no-repeat;
        background-position: center center;

        @include media(max, lg) {
          display: none;
        }
      }
    }
  }
</style>

<script>

export default {
  name: 'ActionTitle',
  render: function (createElement) {
    const regex = /(<highlight>.*<\/highlight>)/gmu

    const elements = {
      highlight: (contents) => createElement(
        'span',
        {
          class: 'title-highlight',
        },
        contents,
      ),
    }

    const contentString = this.$t('landing.action').replaceAll(regex, '%$&%')

    const finalContents = contentString.split('%')

    finalContents.forEach((part, index) => {
      Object.keys(elements).forEach(key => {
        if (part.includes(`<${key}>`) && part.includes(`</${key}>`)) {
          const elementContents = part
            .replace(`<${key}>`, '')
            .replace(`</${key}>`, '')

          finalContents[index] = elements[key](elementContents)
        }
      })
    })

    return createElement(
      'div', {
        class: 'action-title',
      },
      finalContents,
    )
  },
}
</script>
