<style scoped lang="scss">
  .shutdown-message {
    @include padding-y(20px);
    background-color: #030521;
    color: white;

    .message-content {
      padding-right: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .message-title {
        font-size: 18px;
        font-weight: 500;
      }

      .message-text {
        margin-top: 10px;
        font-size: 16px;
      }

      .close-button {
        line-height: 12px;
        position: absolute;
        z-index: 3;
        top: 10px;
        right: 10px;
        font-size: 14px;
        opacity: .7;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      .close-link {
        margin-top: 10px;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        opacity: .7;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
</style>

<template>
  <div class="shutdown-message">
    <container>
      <div class="message-content">
        <div class="message-title">FanTalks прощается с вами</div>
        <div class="message-text">
          С 1 августа 2023 FanTalks будет закрыт<br>
          Мы рекомендуем вам вывести средства до этой даты. Если вы еще не выбрали способ вывода средств, пожалуйста, сделайте это сейчас. Если у вас возникнут вопросы, пожалуйста обратитесь в службу поддержки.
        </div>
      </div>
    </container>
  </div>
</template>

<script>

import cookies from '@utils/cookies'

export default {
  name: 'ShutdownMessage',
  data() {
    return {
      messageClosed: !!cookies.getItem('fantalks.shutdownMessage.closed'),
    }
  },
  methods: {
    closeMessage() {
      cookies.setItem('fantalks.shutdownMessage.closed', 1, Infinity, '/')
      this.messageClosed = true
    },
  },
}
</script>
