<style scoped lang="scss">
  .header-balance-section {
    .balance-link {
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      @include padding-x(14px);
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      .icon {
        font-size: 16px;
        color: $color-gray;
      }

      .balance-text {
        margin-left: 10px;
        color: $color-gray;

        @include media(max, md) {
          display: none;
        }
      }

      .balance-amount {
        color: $color-dark;

        @include media(max, md) {
          margin-left: 10px;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>

<template>
  <div v-if="getUserBalance" class="header-balance-section">
    <component v-bind="linkComponent" class="balance-link" @click.native="onBalanceLinkClick">
      <icon name="fantalks/wallet"/>
      <span class="balance-text">{{ $t('dashboard.user.balance') }}&nbsp;</span>
      <span class="balance-amount">{{ getUserBalance }}</span>
    </component>
  </div>
</template>

<script>

export default {
  name: 'HeaderBalanceSection',
  props: {
    legacy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['getUserBalance']),

    linkComponent() {
      return {
        is: this.legacy ? 'a' : 'router-link',
        ...[
          { href: '/dashboard/payouts' },
          { to: { name: 'dashboard-balance-history' } },
        ][this.legacy ? 0 : 1],
      }
    },
  },
  methods: {
    onBalanceLinkClick() {
      this.$ga.event({
        eventCategory: 'header',
        eventAction: 'header_balance_button_click',
      })

      this.$tmr.goal({ goal: 'header_balance_button_click' })
    },
  },
}
</script>
