/* eslint-disable */

import storage from '@utils/storage'


const appendGoogleAnalytics = () => {
  (function (w, d, l, i) {
    w[l] = w[l] || []

    const f = d.getElementsByTagName('script')[0]
    const j = d.createElement('script')

    j.async = true
    j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i
    f.parentNode.insertBefore(j, f)

    w['gtag'] = function() { dataLayer.push(arguments) }

    w['gtag']('js', new Date())
    w['gtag']('config', i)

  })(window, document, 'dataLayer', 'G-RKW9QWCY90')
}

const append1LinkAnalytics = (user_id = 0) => {
    (function () {
      var partnerScript = document.createElement('script');
      partnerScript.type = 'text/javascript';
      partnerScript.async = true;
      partnerScript.src = '//1l-hit.my.games/v1/hit/118972.js?r=' + encodeURIComponent(document.referrer) + '&l=' + encodeURIComponent(window.location.href) + '&u=' + encodeURIComponent(user_id) + '&rnd=' + Math.random();
      var firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(partnerScript, firstScript);
  })()
}

const appendConsentManager = () => {
  (new Image()).src = "https://consentmanager.mgr.consensu.org/delivery/addurl.php?id=40973&h="+encodeURIComponent(location.href);
}

if (process.env.NODE_ENV === 'production' || storage.getItem('da.donation.debug.ga')) {
  appendGoogleAnalytics()
} else {
  window.gtag = () => null
}

if (process.env.NODE_ENV === 'production' || storage.getItem('da.donation.debug.1link')) {
    window.append1LinkAnalytics = append1LinkAnalytics
}

if (process.env.NODE_ENV === 'production' || storage.getItem('da.donation.debug.cm')) {
  appendConsentManager()
}
