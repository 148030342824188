<style lang="scss" scoped>
  .text-editor {
    position: relative;

    .editor {
      min-height: 140px;
      padding-bottom: 55px;
      line-height: 1.7;
    }

    .message-length-count {
      position: absolute;
      right: 15px;
      bottom: 15px;
      font-size: 13px;
      line-height: 1.25;
      color: $warm-grey;
      pointer-events: none;
      text-align: right;
    }
  }
</style>

<template>
  <div class="text-editor">
    <base-textarea
      ref="editor"
      :placeholder="$t('askMe.questionForm.placeholder') + '*'"
      v-model="questionText"
      class="editor"/>
  </div>
</template>

<script>

export default {
  name: 'TextEditor',
  computed: {
    ...mapState('askmePublic', ['textQuestionsMaxLength']),
    ...mapFields('askmePublic', ['questionText']),
  },
}
</script>
