export const state = {
  isGlobalLoading: false,
  settings: null,
  isButtonLoading: false,
}

export const getters = {
  isGlobalLoading: ({ isGlobalLoading }) => isGlobalLoading,
  settings: ({ settings }) => settings,
  isButtonLoading: ({ isButtonLoading }) => isButtonLoading,
}

export const mutations = {
  startGlobalLoading(state) {
    state.isGlobalLoading = true
  },

  endGlobalLoading(state) {
    state.isGlobalLoading = false
  },

  setSettings(state, { data }) {
    state.settings = data
  },

  startButtonLoading(state) {
    state.isButtonLoading = true
  },

  endButtonLoading(state) {
    state.isButtonLoading = false
  },
}

export const actions = {
  async getSettings({ commit }) {
    try {
      commit('startGlobalLoading')

      const { data } = await api.get(`/askmesetting`)
      commit('setSettings', { data })

      commit('endGlobalLoading')
    } catch (err) {
      commit('endGlobalLoading')
    }
  },

  async updateSettings({ commit }, opt) {
    try {
      commit('startButtonLoading')

      const { data } = await api.put(`/askmesetting`, opt)
      commit('setSettings', { data })

      commit('endButtonLoading')
    } catch (err) {
      commit('endButtonLoading')

      if (err.message === 'The public question min amount must be greater than 0.') {
        window.addStatusMessage('error', 'Минимальная сумма вопроса должна быть больше 0.')
      } else {
        window.addStatusMessage('error', 'Что-то пошло не так :(')
      }
    }
  },
}