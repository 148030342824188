<style scoped lang="scss">
  .content-block-footer {
    border-top: 1px solid $line-color;
    display: flex;
    align-items: center;
    @include padding-x(20px);
    @include padding-y(12px);

    .info-box ::v-deep {
      .icon {
        font-size: 22px;
      }
    }

    .locale-select {
      margin-left: auto;
      display: flex;
      align-items: center;

      .icon {
        color: $gray;
      }

      .language-picker {
        margin-left: 5px;
      }
    }

    @include media(max, sm) {
      @include padding-x(15px);
      flex-direction: column;
      align-items: flex-start;

      .helper-box {
        order: 2;
      }

      .locale-select {
        margin-left: 0;
        order: 1;
      }
    }

    .locale-select {
      margin-left: auto;
      display: flex;
      align-items: center;

      .icon {
        color: $gray;
      }

      .language-picker {
        margin-left: 5px;
      }
    }
  }
</style>

<template>
  <div class="content-block-footer">
    <!-- TODO: -->
    <helper-box icon="social-ft">
      <div v-html="technologyText"/>
    </helper-box>

    <div class="locale-select">
      <icon name="globe"/>
      <dropdown-select
        v-model="localeModel"
        :options="localeOptions"
        class="language-picker"
        :class="{left: $appIsSM}"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentBlockFooter',
  computed: {
    ...mapState('application', ['locale']),

    localeOptions() {
      return this.$appLocalesList
        .map(locale => ({
          value: locale.code,
          label: locale.name,
        }))
    },

    localeModel: {
      get() {
        return this.locale
      },
      set(value) {
        this.selectLocale(value)
      },
    },

    technologyText() {
      return this.$t('global.app.technology', {
        link: '<a target="_blank" href="https://fantalks.io/">FanTalks.io</a>',
      })
    },
  },
  methods: {
    ...mapActions('application', ['selectLocale']),
  },
}
</script>
