<style scoped lang="scss">
  .landing-demo {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;

    .curve-2 {
      position: absolute;
      z-index: -1;
      top: 50px;

      @include media(max, lg) {
        display: none;
      }
    }

    .ask {
      position: absolute;
      margin-left: -677px;
      margin-top: -7px;

      @include media(max, lg) {
        display: none;
      }
    }

    .answer {
      position: absolute;
      bottom: 18px;
      margin-right: -716px;

      @include media(max, lg) {
        display: none;
      }
    }

    .full {
      margin-right: -110px;
      width: 516px;
      height: 463px;
      background-image: appAsset('/images/landing/demo-full.png');
      background-size: cover;

      @include media(max, lg) {
        width: 336px;
        height: 300px;
      }
    }

    .mobile {
      width: 156px;
      height: 271px;
      position: absolute;
      bottom: 0;
      margin-left: -514px;
      background-image: appAsset('/images/landing/demo-mobile.png');
      background-size: cover;

      @include media(max, lg) {
        margin-left: -232px;
        width: 98px;
        height: 171px;
      }
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
      .full {
        background-image: appAsset('/images/landing/demo-full@2x.png');
      }

      .mobile {
        background-image: appAsset('/images/landing/demo-mobile@2x.png');
      }
    }
  }
</style>

<template>
  <div class="landing-demo">
    <img class="curve-2" :src="$asAppAsset('/images/landing/curve-2.svg')"/>
    <div class="full"/>
    <div class="mobile"/>
    <img class="ask" :src="$asAppAsset('/images/landing/demo-ask.svg')"/>
    <img class="answer" :src="$asAppAsset('/images/landing/demo-answer.svg')"/>
  </div>
</template>

<script>

export default {
  name: 'LandingDemo',
}
</script>
