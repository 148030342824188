<style scoped lang="scss">
  .header-profile-section {
    position: relative;
    user-select: none;

    .profile-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      .profile-avatar {
        flex-shrink: 0;
        background: #F1F4F6;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 25%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .profile-name {
        margin-left: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @include media(max, sm) {
          display: none;
        }
      }

      .profile-dropdown-button {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }

      &:hover {
        .profile-name {
          color: $color-purple;
        }
      }
    }

    .profile-dropdown {
      margin-top: 20px;
      position: absolute;
      z-index: 20;
      right: 0;
      padding: 8px 16px;
      min-width: 240px;
      background: #FFFFFF;
      border: 1px solid #D3DCE2;
      box-shadow: 0 14px 20px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      @include media(max, sm) {
        position: fixed;
        bottom: 0;
        top: 0;
        margin-top: 0;
        filter: none;
        border-radius: 0;
        border: none;
        padding-top: 15px;
      }

      .mobile-profile-wrapper {
        @include media(min, sm) {
          display: none;
        }
      }

      .mobile-profile-block {
        display: flex;
        align-items: center;

        .mobile-profile-avatar {
          flex-shrink: 0;

          img {
            width: 50px;
            height: 50px;
            border-radius: 16px;
          }
        }

        .mobile-profile-info {
          margin-left: 17px;

          .mobile-profile-name {
            font-weight: 500;
            color: $color-dark;
          }

          .mobile-profile-balance {
            margin-top: 8px;

            .balance-text {
              color: $color-gray;
            }

            .balance-amount {
              color: $color-dark;
            }
          }
        }
      }

      .dropdown-list {
        .dropdown-link {
          display: flex;
          align-items: center;
          color: $color-dark;
          cursor: pointer;
          white-space: nowrap;
          @include padding-y(5px);

          .icon {
            font-size: 18px;
            color: $color-gray;
          }

          .link-text {
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
          }

          &:hover {
            text-decoration: none;
            color: $color-purple;

            .icon {
              color: $color-gray;
            }
          }

          &.router-link-active {
            color: $color-dark-purple;

            .icon {
              color: $color-dark-purple;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div
    v-if="profile"
    class="header-profile-section"
    v-click-outside="closeProfileDropdown">
    <div class="profile-button" @click="toggleProfileDropdown">
      <div class="profile-avatar">
        <img :src="profile.avatar"/>
      </div>
      <div class="profile-name">
        {{ profile.name }}
      </div>
      <div class="profile-dropdown-button">
        <icon name="angle-down"/>
      </div>
    </div>
    <transition name="fade-up">
      <div v-show="profileDropdownOpened" class="profile-dropdown">
        <div class="mobile-profile-wrapper">
          <div class="mobile-profile-block">
            <div class="mobile-profile-avatar"><img :src="profile.avatar"/></div>
            <div class="mobile-profile-info">
              <div class="mobile-profile-name">{{ profile.name }}</div>
              <div class="mobile-profile-balance">
                <span class="balance-text">Баланс:&nbsp;</span><span class="balance-amount">{{ getUserBalance }}</span>
              </div>
            </div>
          </div>
          <hr class="small">
        </div>
        <div class="dropdown-list">
          <template v-for="(link, index) in dropdownLinks">
            <router-link
              :key="index"
              :to="{name: link.route}"
              class="dropdown-link"
              @click.native="onMenuLinkClick({name: link.route})">
              <icon :name="link.icon"/>
              <div class="link-text">{{ link.text }}</div>
            </router-link>
          </template>
          <div class="dropdown-link" @click="logout">
            <icon name="fantalks/logout"/>
            <div class="link-text">{{ $t('dashboard.user.menu.logout') }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'HeaderProfileSection',
  data() {
    return {
      profileDropdownOpened: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('profile', ['getUserBalance']),

    dropdownLinks() {
      const links = []

      links.push({
        key: 'payouts',
        icon: 'fantalks/wallet',
        route: 'dashboard-payments',
      })

      links.push({
        key: 'generalSettings',
        icon: 'fantalks/settings',
        route: 'dashboard-account-settings',
      })

      return links.map(link => ({
        ...link,
        text: this.$t(`dashboard.user.menu.${link.key}`),
      }))
    },
  },
  methods: {
    logout() {
      window.location.href = '/auth/logout'
    },

    toggleProfileDropdown() {
      this.profileDropdownOpened = !this.profileDropdownOpened

      if (this.profileDropdownOpened) {
        this.$ga.event({
          eventCategory: 'header',
          eventAction: 'header_profile_menu_open_event',
        })

        this.$tmr.goal({ goal: 'header_profile_menu_open_event' })
      }
    },

    onMenuLinkClick(route) {
      const nextRoute = this.$router.resolve(route)

      this.$ga.event({
        eventCategory: 'header',
        eventAction: 'header_profile_menu_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })

      this.$tmr.goal({
        goal: 'header_profile_menu_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })
    },

    closeProfileDropdown() {
      this.profileDropdownOpened = false
    },
  },
  watch: {
    $route: 'closeProfileDropdown',
  },
}
</script>
