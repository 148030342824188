import storage from '@utils/storage'

window.dataLayer = window.dataLayer || []

const debug = storage.getItem('da.donation.debug.ga')

// export default {
//   install: Vue => {
//     Vue.ga = {
//       send(prefix = 'event', {
//         category = undefined,
//         action = undefined,
//         label = undefined,
//         value = undefined,
//       } = {}) {
//         const payload = {
//           eventCategory: category,
//           eventAction: `askme_${prefix}_${action}`,
//           eventLabel: label,
//           eventValue: value,
//         }

//         if (window.ga) {
//           window.ga('send', 'event', payload)
//         }

//         if (debug) {
//           console.log('send', 'event', payload)
//         }
//       },
//       event(data) {
//         this.send('event', data)
//       },
//       action(data) {
//         this.send('action', data)
//       },
//       view(data) {
//         this.send('view', data)
//       },
//     }
//     Vue.prototype.$ga = Vue.ga
//   },
// }

export default {
  install: Vue => {
    Vue.ga = {
      event(name, parameters = {}) {
        if (!name) return

        window.gtag('event', name, parameters)

        // console.info('[GTag] - event: ' + name)
      },

      // stubs
      send() {},
      action() {},
      view() {},
    }
    Vue.prototype.$ga = Vue.ga
  },
}