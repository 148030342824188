<style scoped lang="scss">
  .shutdown-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .message-box {
      max-width: 600px;
      border-radius: 14px;
      border: 1px solid #D3DCE2;
      background: #FFF;
      box-shadow: 0px 4px 24px 0px rgba(126, 137, 153, 0.12);
      padding: 40px;
      text-align: center;

      .message-title {
        margin-top: 30px;
        color: var(--dark, #282C32);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .message-text {
        margin-top: 16px;
        color: var(--gray, #7E8999);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
</style>

<template>
  <div class="shutdown-page">
    <div class="message-box">
      <img :src="$asAppAsset('/images/logo_FanTalks.svg')" class="logo"/>
      <div class="message-title">
        {{ $t('askMe.app.shutdownTitle') }}
      </div>
      <div class="message-text" v-html="$t('askMe.app.shutdownMessage')"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShutdownPage',
}
</script>
