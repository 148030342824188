<style scoped lang="scss">
  .dashboard-header {
    height: $dashboard-header-height;
    background-color: white;

    .header-content {
      @include margin-x(auto);
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .mobile-menu {
        @include media(min, lg) {
          display: none;
        }

        .mobile-menu-button {
          margin-left: -10px;
          height: 40px;
          width: 40px;
          font-size: 20px;
          color: $color-purple;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          cursor: pointer;
          user-select: none;
        }

        .mobile-menu-wrapper {
          position: fixed;
          z-index: 20;
          top: $dashboard-header-height;
          left: 0;
          bottom: 0;
          background-color: white;
          padding: 10px;
          width: 280px;
          overflow-y: auto;
          margin-top: 1px;
        }
      }

      .logo {
        color: #282C32;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;

        @include media(max, lg) {
          margin-left: 10px;
        }

        img {
          display: block;
          margin-right: 5px;
        }

        .highlight {
          color: $color-purple;
        }
      }

      .header-panel {
        margin-left: $dashboard-content-gap;
        display: flex;
        align-items: center;
        flex-grow: 1;

        @include media(max, sm) {
          margin-left: 12px;
        }

        .boosty-link {
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          font-size: 16px;
          color: $gray;
          @include padding-x(15px);
          background-color: $additional-2;

          &:hover {
            background-color: $additional-1;
          }

          .icon {
            font-size: 20px;
          }

          .button-text {
            margin-left: 10px;
          }

          @include media(max, sm) {
            display: none;
          }
        }

        .header-controls {
          margin-left: auto;
          display: flex;
          align-items: center;

          .header-section + .header-section {
            margin-left: 20px;
          }

          .header-balance-section {
            @include media(max, sm) {
              display: none;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="dashboard-header">
    <div class="header-content container">
      <div class="mobile-menu" v-click-outside="hideMenu">
        <div class="mobile-menu-button" @click="toggleMenu">
          <icon v-if="menuOpened" name="cross"/>
          <icon v-else name="bars"/>
        </div>
        <transition name="fade-up">
          <div v-if="menuOpened" class="mobile-menu-wrapper">
            <dashboard-menu/>
          </div>
        </transition>
      </div>

      <router-link :to="{name: 'dashboard'}" class="logo" @click.native="onLogoClick">
        <img :src="$asAppAsset('/images/logo.svg')"/>
        <span class="highlight">Fan</span>Talks
      </router-link>

      <div class="header-panel">
        <div class="header-controls">
          <header-balance-section class="header-section"/>
          <header-profile-section class="header-section"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HeaderBalanceSection from './HeaderBalanceSection'
import HeaderProfileSection from './HeaderProfileSection'
import DashboardMenu from '@components/Dashboard/DashboardMenu'

export default {
  name: 'DashboardHeader',
  components: {
    DashboardMenu,
    HeaderProfileSection,
    HeaderBalanceSection,
  },
  data() {
    return {
      menuOpened: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('profile', ['getUserIsStreamer', 'getUserIsViewer']),
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
    },

    hideMenu() {
      this.menuOpened = false
    },

    onLogoClick() {
      this.$ga.event({
        eventCategory: 'header',
        eventAction: 'header_logo_click',
      })

      this.$tmr.goal({ goal: 'header_logo_click' })
    },
  },
  watch: {
    $route: 'hideMenu',
  },
}
</script>
