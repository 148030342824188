import axios from 'axios'
import storage from '@utils/storage'

const STORAGE_USER_ALIAS = 'da.donation.viewerAlias'
const STORAGE_USER_PHONE = 'da.donation.viewerPhone'
const STORAGE_USER_QIWI_WALLET = 'da.donation.viewerQiwiWallet'
const STORAGE_USER_EMAIL = 'da.donation.viewerEmail'

export const state = {
  loginModalOpened: false,
  registerModalOpened: false,

  userToken: null,

  viewerInfo: null,

  viewerAlias: storage.getItem(STORAGE_USER_ALIAS),
  viewerQiwiWallet: storage.getItem(STORAGE_USER_QIWI_WALLET),
  viewerPhone: storage.getItem(STORAGE_USER_PHONE),
  viewerEmail: storage.getItem(STORAGE_USER_EMAIL),

  isLandingPage: false,
}

export const getters = {
  getField,

  token: state => state.userToken,
  viewerAuthorized: state => !!state.userToken,
}

export const mutations = {
  updateField,

  SET_VIEWER_ALIAS(state, alias) {
    state.viewerAlias = alias
  },

  SET_VIEWER_EMAIL(state, email) {
    state.viewerEmail = email
  },

  SET_LOGIN_MODAL_OPENED(state, value) {
    state.loginModalOpened = value
  },

  SET_REGISTER_MODAL_OPENED(state, value) {
    state.registerModalOpened = value
  },

  SET_USER_TOKEN(state, token) {
    state.userToken = token
  },

  SET_VIEWER_INFO(state, data) {
    state.viewerInfo = data
  },
}

export const actions = {
  showLoginModal({ commit }) {
    commit('SET_LOGIN_MODAL_OPENED', true)
  },

  showRegisterModal({ commit }) {
    commit('SET_REGISTER_MODAL_OPENED', true)
  },

  logout({ commit }) {
    return axios
      .get('/auth/logout')
      .then(() => {
        commit('SET_USER_TOKEN', null)
        commit('SET_VIEWER_INFO', null)
      })
  },

  login({
    commit,
    dispatch,
  }) {
    return dispatch('authenticate')
      .then(() => {
        return dispatch('fetchCurrentUser')
      })
      .catch(() => {
        commit('SET_USER_TOKEN', null)
        commit('SET_VIEWER_INFO', null)

        return false
      })
  },

  authenticate({ commit }) {
    return api
      .get('/session/token', {
        spa_page: 'askme',
      }, { proxyRequest: false })
      .then(({ data }) => {
        return commit('SET_USER_TOKEN', data.api_token)
      })
  },

  fetchCurrentUser({
    commit,
    state,
  }) {
    return api
      .get('/user')
      .then(({ data }) => {
        if (!state.viewerAlias || !state.viewerAlias.length) {
          commit('SET_VIEWER_ALIAS', data.name)
        }

        if (!state.viewerEmail || !state.viewerEmail.length) {
          commit('SET_VIEWER_EMAIL', data.email)
        }

        return commit('SET_VIEWER_INFO', data)
      })
  },

  storeViewerFields({ state }) {
    storage.setItem(STORAGE_USER_ALIAS, state.viewerAlias)
    storage.setItem(STORAGE_USER_PHONE, state.viewerPhone)
    storage.setItem(STORAGE_USER_QIWI_WALLET, state.viewerQiwiWallet)
    storage.setItem(STORAGE_USER_EMAIL, state.viewerEmail)
  },
}
