<style lang="scss" scoped>
  .amount-input {
    position: relative;
    background-color: white;

    line-height: 1.2;

    font-size: 32px;

    .base-input {
      background-color: transparent;
      border-color: transparent;
      border-bottom-color: $line-color;
      padding: 5px 0;
      border-radius: 0;
      font-size: inherit;
      line-height: inherit;
    }

    &:hover .base-input {
      border-bottom-color: $line-hover-color;
    }

    &.focused .base-input {
      border-bottom-color: var(--theme-color);
    }

    .addon {
      @include position(absolute, 0, 0, 0, 0);
      display: flex;
      align-items: center;
      padding: 5px 0;
      font-size: inherit;
      line-height: inherit;
      pointer-events: none;

      .input-text {
        opacity: 0;

        &.visible {
          color: $control-color;
          opacity: .5;
        }
      }

      .addon-text {
        margin-left: .2em;
        color: $placeholder-color;
      }
    }
  }
</style>

<template>
  <div class="amount-input" :class="rootClasses">
    <div class="addon">
      <div class="input-text" :class="backTextClasses">{{ backText }}</div>
      <div class="addon-text">{{ getCurrentCurrency.symbol }}
      </div>
    </div>
    <base-input
      v-model="input"
      @change="onChange"
      @focus="onInputFocus"
      @blur="onInputBlur"/>
  </div>
</template>

<script>

export default {
  name: 'DonationAmountInput',
  data() {
    return {
      input: '',
      inputFocused: false,
    }
  },
  computed: {
    ...mapFields('donation', ['donationAmount']),
    ...mapGetters('donation', ['minimalAmounts', 'getDonationSettings']),
    ...mapGetters('currencies', ['getCurrentCurrency']),

    inputNotEmpty() {
      return !!(this.input).toString().length
    },

    backText() {
      return this.inputNotEmpty ? this.input : this.placeholder
    },

    backTextClasses() {
      return {
        visible: !this.inputNotEmpty,
      }
    },

    rootClasses() {
      return {
        focused: this.inputFocused,
      }
    },
  },
  methods: {
    onChange() {
      const amount = Number(this.input)
      const input = String(amount)

      if (input.length && input.includes('.')) {
        const [number, digits] = input.split('.')

        if (Number(digits)) {
          this.input = amount.toFixed(2)
        } else {
          this.input = amount
        }
      }

      if (!input.length || !amount || amount < this.getDonationSettings.minimalAmount) {
        this.input = this.getDonationSettings.minimalAmount
        this.donationAmount = this.getDonationSettings.minimalAmount
      }
    },

    onInputFocus() {
      this.inputFocused = true
    },

    onInputBlur() {
      this.inputFocused = false
    },
  },
  watch: {
    input(value) {
      if (value !== Number(value)) {
        const strValue = (value).toString()
        const numericRegExp = /[^\d.]/g

        if (numericRegExp.test(strValue)) {
          return this.input = strValue
            .replace(/,/g, '.')
            .replace(numericRegExp, '')
        }

        const [head, tail] = strValue.split('.')

        if (head.length > 6 || (tail || '').length > 2) {
          return this.input = [
            Number(head.slice(0, 6)).toFixed(0),
            (tail || '').slice(0, 2),
          ].join('.')
        }

        const dotMatch = strValue.match(/\./g)

        if (dotMatch && dotMatch.length > 1) {
          const strValueArray = strValue.split('.')
          return this.input = strValueArray.shift() + '.' + strValueArray.join('')
        }

        this.donationAmount = Number(strValue)
      }
    },

    donationAmount: {
      handler(value) {
        this.input = value
      },
      immediate: true,
    },

    getCurrentCurrency() {
      this.donationAmount = this.minimalAmounts.donation

      this.onChange()
    },

    'getDonationSettings.minimalAmount': 'onChange',
  },
}
</script>
