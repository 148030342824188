import cookies from '@utils/cookies'

export const state = {
  currencies: {},

  currentCurrency: 'RUB',
}

export const getters = {
  getField,

  currenciesList: state => Object.values(state.currencies),

  payInCurrenciesList: state => Object.values(state.currencies).filter(c => c.payIn),

  getCurrency: state => code => state.currencies[code],

  getCurrentCurrency: (state, getters) => getters.getCurrency(state.currentCurrency),

  getAmountWithCurrency: (state, getters) => (amount, currencyCode = null) => {
    const currency = getters.getCurrency(currencyCode ?? state.currentCurrency)

    if (!currency) {
      return null
    }

    let text = [amount, currency.symbol]

    if (currency.symbolFirst) {
      text = text.reverse()
    }

    return text.join(' ')
  },
}

export const mutations = {
  updateField,

  SET_CURRENT_CURRENCY(state, currency) {
    state.currentCurrency = currency
  },

  SET_CURRENCIES(state, currencies) {
    state.currencies = _.keyBy(currencies, 'code')
  },
}

export const actions = {
  setup({ commit }, { preferredCurrency }) {
    commit('SET_CURRENT_CURRENCY', preferredCurrency)
  },

  saveCurrency({ state }) {
    cookies.setItem('preferred_currency', state.currentCurrency, Infinity, '/')
  },

  fetchCurrencies({ commit }) {
    return api.get('/currencies')
      .then(({ data }) => {
        return commit('SET_CURRENCIES', data.map(currency => ({
          name: currency.title,
          code: currency.key,
          symbol: currency.symbol,
          symbolFirst: currency.is_symbol_first,
          payIn: currency.is_payable,
        })))
      })
  },
}
