<template>
  <div>
    <loader
      size="md"
      v-if="isQuestionsLoading"
    />

    <div v-else>
      <stub v-if="questions.length === 0">
        <template v-slot:image>
          <div class="stub__image"></div>
        </template>

        <template v-slot:title>
          <Title type="2">{{ $t('askMe.questions.stubTitle') }}</Title>
        </template>

        <template v-slot:content>
          <p>Для получения вопросов разместите ссылку на страницу отправки в своих медиа.</p>

          <copy-link :src="publicPageLink" style="max-width: 420px; margin: 15px auto;"/>

          <div style="margin-top: 20px;">
            <base-button
              variant="primary"
              iconLeft="plus"
              @click="toggleModal"
              class="add-button"
            >{{ $t('askMe.question.addButton') }}</base-button>
          </div>
        </template>
      </stub>

      <div class="content-area" v-if="questions.length > 0">
        <div class="content-area__side">
          <base-button
            variant="primary"
            iconLeft="plus"
            @click="toggleModal"
            class="add-button"
          >{{ $t('askMe.question.addButton') }}</base-button>

          <div style="padding-top: 20px;">
            <Title type="3">{{ $t('askMe.questions.categoriesTitle') }}</Title>

            <category-filter
              :list="questionsMeta.filters.categories"
              :totalCount="questionsMeta.filters.no_category_questions_num"
              v-model="category"
            />
          </div>
        </div>
        <div class="wall content-area__main">
          <question
            v-for="question in questions"
            :key="question.id"
            :questionData="question"
            :disabled="isQuestionLoading === question.id"
          />

          <div class="pagination" v-if="questions.length > 0">
            <pagination
              v-if="questionsMeta.last_page && questionsMeta.last_page > 1"
              v-model="page"
              :current-page="questionsMeta.current_page"
              :pages-count="questionsMeta.last_page"
            />
          </div>
        </div>
      </div>

      <question-modal
        :isShow="showModal"
        v-if="showModal"
        @toggleModal="toggleModal"
      />
    </div>
  </div>
</template>

<script>
import Title from '@components/_BaseComponents/Title'
import BaseButton from '@components/_BaseComponents/BaseButton'

import Stub from '@components/Dashboard/AskMe/Stub'
import Question from '@components/Dashboard/AskMe/Question'
import QuestionModal from '@components/Dashboard/AskMe/QuestionModal'
import CategoryFilter from '@components/Dashboard/AskMe/CategoryFilter'
import CopyLink from '@components/Dashboard/AskMe/CopyLink'

import Pagination from '@components/_BaseComponents/Pagination'

export default {
  name: 'AskMeQuestionsPage',

  components: {
    Title,
    BaseButton,
    Stub,
    Question,
    Pagination,
    QuestionModal,
    CategoryFilter,
    CopyLink,
  },

  data() {
    return {
      page: undefined,
      category: undefined,
      showModal: false,
    }
  },

  watch: {
    queryParams: {
      handler(newVal, oldVal) {
        if (oldVal === undefined) return

        if (newVal.page !== oldVal.page && newVal.category !== oldVal.category) {
          // init page
          const page = newVal.page
          const category = newVal.category

          this.getQuestions({ page, category })
        } else if (newVal.page === oldVal.page && newVal.category !== oldVal.category) {
          // switch category
          this.page = 1

          const page = 1
          const category = newVal.category

          const query = { ...this.$route.query }
          query.page = page
          query.category = category

          this.$router.push({ query }).catch()

          this.getQuestions({ page, category })
        } else if (newVal.page !== oldVal.page && newVal.category === oldVal.category) {
          // pagination
          const page = newVal.page
          const category = newVal.category

          const query = { ...this.$route.query }
          query.page = page
          this.$router.push({ query }).catch()

          this.getQuestions({ page, category })
        }
      },
      immediate: true,
    },
  },

  created() {
    const currentPage = +this.$route.query.page || 1
    const currentCategory = this.$route.query.category

    this.page = currentPage

    if (currentCategory === undefined || currentCategory === null) {
      this.category = null
    } else {
      this.category = +currentCategory
    }
  },

  computed: {
    ...mapState('dashboard', ['newDashboard']),

    ...mapGetters('profile', {
      profile: 'getCurrentProfile',
    }),

    ...mapGetters('askMe/questions', [
      'isQuestionsLoading',
      'questions',
      'questionsMeta',
      'isQuestionLoading',
      'isCategoriesLoading',
      'categories',
    ]),

    settingsLink() {
      return this.newDashboard ? {
        name: 'dashboard-askme-settings',
      } : {
        path: '/dashboard/askme/settings',
      }
    },

    queryParams() {
      return { page: this.page, category: this.category}
    },

    publicPageLink() {
      return `${window.location.origin}/r/${this.profile.code}`
    },
  },

  methods: {
    ...mapActions('askMe/questions', ['getQuestions', 'getCategories']),

    toggleModal() {
      this.showModal = !this.showModal
    },
  },
}
</script>

<style scoped lang="scss">
.content-area {
  @media (min-width: 720px) {
    display: flex;
    flex-direction: row-reverse;
  }

  &__main {
    width: 100%;
    @media (min-width: 720px) {
      width: 585px;
    }
  }

  &__side {
    max-width: 100%;
    padding: 10px 0;

    @media (min-width: 720px) {
      margin-left: 30px;
      flex: 1;
    }
  }
}

.wall {
  padding: 10px 0;
}
.pagination {
  max-width: 585px;
  display: flex;
  justify-content: center;
}
.add-button {
  border-radius: 8px;
}

.stub__image {
  width: 100px;
  height: 100px;
  background-image: appAsset('/icons/askme_stub.svg');
  background-position: center center;
  background-size: 100%;
  margin: 0 auto 15px auto;
}
</style>
