<style scoped lang="scss">
  .landing-page {
    overflow: hidden;

    .landing-header {
      margin-top: 12px;
    }

    .landing-hero {
      margin-top: 62px;
    }

    .landing-guide {
      margin-top: 92px;

      @include media(max, lg) {
        margin-top: 50px;
      }
    }

    .landing-payouts {
      margin-top: 90px;

      @include media(max, lg) {
        margin-top: 60px;
      }
    }

    .landing-demo {
      margin-top: 70px;

      @include media(max, lg) {
        margin-top: 50px;
      }
    }

    .landing-action {

    }

    .landing-footer {

    }
  }
</style>

<template>
  <div class="landing-page">
    <shutdown-message/>
    <container>
      <landing-header/>
      <landing-hero/>
      <landing-guide/>
    </container>
    <landing-payouts/>
    <landing-demo/>
    <landing-action/>
    <container>
      <landing-footer/>
    </container>
    <login-modal/>
    <register-modal/>
  </div>
</template>

<script>

import LandingHeader from '@components/LandingPage/LandingHeader'
import LandingHero from '@components/LandingPage/LandingHero'
import LandingGuide from '@components/LandingPage/LandingGuide'
import LandingPayouts from '@components/LandingPage/LandingPayouts'
import LandingDemo from '@components/LandingPage/LandingDemo'
import LandingAction from '@components/LandingPage/LandingAction'
import LandingFooter from '@components/LandingPage/LandingFooter'
import LoginModal from '@components/LoginModal'
import RegisterModal from '@components/RegisterModal'
import ShutdownMessage from '@components/ShutdownMessage'

export default {
  name: 'LandingPage',
  components: {
    ShutdownMessage,
    RegisterModal,
    LoginModal,
    LandingFooter,
    LandingAction,
    LandingDemo,
    LandingPayouts,
    LandingGuide,
    LandingHero,
    LandingHeader,
  },
  computed: {
    ...mapFields('viewer', ['isLandingPage']),
  },
  created() {
    this.isLandingPage = true

    if (!!window.append1LinkAnalytics) {
      window.append1LinkAnalytics()
    }
  },
}
</script>
