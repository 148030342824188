<style scoped lang="scss">
  .landing-footer {
    display: flex;
    align-items: center;
    @include padding-y(15px);

    .copyright {
      font-size: 13px;
      line-height: 16px;
      color: #7E8999;
    }

    .social-links {
      display: flex;
      gap: 10px;
      margin-bottom: 30px;

      @include media(min, lg) {
        margin-bottom: 0;
        margin-left: 30px
      }

      a {
        display: block;
      }
    }

    .footer-links-list {
      margin-left: auto;
      display: flex;
      align-items: center;

      .footer-link + .footer-link {
        margin-left: 30px;
      }

      .footer-link {
        font-size: 13px;
        line-height: 16px;
        color: #7E8999;
        text-decoration: none;
      }
    }

    @include media(max, lg) {
      @include padding-x($gutter);
      flex-direction: column;
      align-items: flex-start;

      .copyright {
        margin-top: 30px;
        order: 2;
      }

      .footer-links-list {
        order: 1;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;

        .footer-link + .footer-link {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
</style>

<template>
  <div class="landing-footer">
    <div class="copyright">© {{ currentYear }} MY.GAMES B.V All rights reserved.</div>
    <div class="footer-links-list">
      <a href="/terms-of-service" target="_blank" class="footer-link">
        {{ $t('global.app.termsOfServices') }}
      </a>
      <a href="/privacy-policy" class="footer-link" target="_blank">
        {{ $t('global.app.privacyPolicy') }}
      </a>
      <a href="/support" class="footer-link" target="_blank">
        {{ $t('global.app.support') }}
      </a>
    </div>
    <div class="social-links">
      <a href="https://vk.com/fantalks" target="_blank">
        <img :src="$asAppAsset('/images/landing/socials/vk.svg')"/>
      </a>
      <a href="https://t.me/+WIWrv4_eTXg5NzBi" target="_blank">
        <img :src="$asAppAsset('/images/landing/socials/tg.svg')"/>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LandingFooter',

  computed: {
    currentYear: () => ((new Date).getFullYear())
  }
}
</script>
