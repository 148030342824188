<template>
  <modal v-model="modalOpened">
    <template slot="title">
      <template>{{$t('askMe.questions.modalTitle')}}</template>
    </template>
    <template slot="body-content">
      <loader
        size="md"
        v-if="isCategoriesLoading"
      />

      <div>
        <form-group
          :label="$t('askMe.questions.nameLabel') + '*'"
          inline
        >
          <base-input v-model="name" :placeholder="$t('askMe.questions.namePlaceholder')" />
        </form-group>

        <form-group
          :label="$t('askMe.questions.amountLabel') + '*'"
          inline
        >
          <input-addon v-model="amount" :addon="amountCurrency" />
        </form-group>

        <form-group
          :label="$t('askMe.questions.categoryLabel')"
          inline
        >
          <dropdown-select
            v-model="category"
            :options="categoriesOptions"
            variant="new"
          />
        </form-group>

        <form-group
          :label="$t('askMe.questions.contentLabel')"
          inline
        >
          <base-textarea
            v-model="content"
            rows="3"
            :placeholder="$t('askMe.questions.contentPlaceholder')"
          />
          <span
            v-if="content"
            class="ta-counter"
            :class="{ 'ta-counter_red': content.length > 1000 }"
          >{{ content.length }} / 1000</span>
        </form-group>

        <form-group
          :label="$t('askMe.questions.dateTimeLabel')"
          inline
        >
          <date-time-picker
            v-model="dateTime"
          />
        </form-group>

        <div class="debug" v-if="false">
          <div>name: <span>{{ name }}</span></div>
          <div>category: <span>{{ category }}</span></div>
          <div>amount: <span>{{ amount }}</span></div>
          <div>amountCurrency: <span>{{ amountCurrency }}</span></div>
          <div>content: <span>{{ content }}</span></div>
          <div>dateTime: <span>{{ dateTime }}</span></div>
        </div>

        <div class="button-group">
          <base-button
            variant="primary"
            @click="sendHandler"
            :disabled="name.length < 1 || amount.length < 1"
            :isLoading="sendQuestionLoading"
          >{{ $t('askMe.questions.send') }}</base-button>

          <base-button
            variant="secondary"
            @click="undoClickHandler"
          >{{$t('askMe.questions.undo')}}</base-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/_BaseComponents/Modal'
import FormGroup from '@components/_BaseComponents/Form/FormGroup'
import BaseButton from '@components/_BaseComponents/BaseButton'
import BaseInput from '@components/_BaseComponents/Form/BaseInput'
import InputAddon from '@components/_BaseComponents/Form/InputAddon'
import BaseTextarea from '@components/_BaseComponents/Form/BaseTextarea'

export default {
  components: {
    Modal,
    FormGroup,
    BaseButton,
    BaseInput,
    InputAddon,
    BaseTextarea,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: '',
      category: null,
      amount: '10',
      amountCurrency: 'RUB',
      content: '',
      dateTime: null,
    }
  },

  watch: {},

  created() {
    this.getCategories()
  },

  computed: {
    ...mapGetters('askMe/questions', [
      'isCategoriesLoading',
      'categories',
      'sendQuestionLoading',
    ]),

    categoriesOptions() {
      const options = this.categories || []

      const values = [{
        value: null,
        label: this.$t('askMe.questions.uncategorized'),
      }, ...options.map(option => ({
        value: option.id,
        label: option.title,
      }))]

      return values
    },

    modalOpened: {
      get() { return this.isShow },
      set(value) { return !value && this.undoClickHandler() },
    },
  },

  methods: {
    ...mapActions('askMe/questions', ['getCategories', 'sendQuestion']),

    undoClickHandler() {
      this.name = ''
      this.category = null
      this.amount = '10'
      this.amountCurrency = 'RUB'
      this.content = ''
      this.dateTime = null

      this.$emit('toggleModal') ;
    },

    sendHandler() {
      const { name, category, amount, amountCurrency, content, dateTime } = this
      this.sendQuestion({ name, category, amount, amountCurrency, content, dateTime })

      this.undoClickHandler()
    }
  },
}
</script>

<style scoped lang="scss">
.button-group {
  padding-bottom: 20px;
  margin-top: 40px;

  button {
    margin-right: 10px;
    border-radius: 8px;
  }
}
.ta-counter {
  display: block;
  text-align: right;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
  padding-top: 5px;

  &_red {
    color: #D63A3A;
  }
}
.debug {
  margin: 20px 0 10px 0;
  padding: 10px;
  border-radius: 8px;
  border: 1px dashed #838383;
  color: #838383;

  & > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: #f1f1f1;
    font-weight: bold;
  }
}
</style>
