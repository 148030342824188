<style scoped lang="scss">
  .landing-payouts {
    display: flex;
    flex-direction: column;
    align-items: center;

    .payout-systems {
      display: flex;
      align-items: center;
      justify-content: center;

      .payout-system + .payout-system {
        margin-left: 15px;
      }

      .payout-system {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0 4px 24px rgba(126, 137, 153, 0.12);
        border-radius: 20px;
      }
    }

    .payouts-title {
      margin-top: 40px;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      text-align: center;

      @include media(max, lg) {
        margin-top: 30px;
        @include padding-x($gutter);
      }
    }

    .payouts-text {
      margin-top: 20px;
      max-width: 534px;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: $color-gray;
      text-align: center;

      @include media(max, lg) {
        @include padding-x($gutter);
      }
    }
  }
</style>

<template>
  <div class="landing-payouts">
    <div class="payout-systems">
      <div
        v-for="system in systems"
        :key="system.code"
        class="payout-system">
        <img :src="system.img"/>
      </div>
    </div>
    <payouts-title/>
    <div class="payouts-text">
      {{ $t('landing.payouts.text') }}
    </div>
  </div>
</template>

<script>

import PayoutsTitle from '@components/LandingPage/PayoutsTitle'

export default {
  name: 'LandingPayouts',
  components: { PayoutsTitle },
  computed: {
    systems() {
      return [
        'vk',
        'qiwi',
        'sber',
        'yu-money',
        'webmoney',
        'paypal',
        'cards',
      ].map(system => ({
        code: system,
        img: this.$asAppAsset(`/images/landing/payments/${system}.svg`),
      }))
    },
  },
}
</script>
