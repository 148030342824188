export const state = {
  invoiceId: null,
  invoiceHash: null,

  payloadValidated: false,
}

export const getters = {
  getField,
}

export const mutations = {
  updateField,

  SET_INVOICE_ID(state, id) {
    state.invoiceId = id
  },

  SET_INVOICE_HASH(state, hash) {
    state.invoiceHash = hash
  },

  SET_PAYLOAD_VALIDATED(state, value) {
    state.payloadValidated = value
  },
}

export const actions = {
  prepareAskmeInvoicePayload({ rootState, rootGetters }, extra) {
    const { questionText, questionCategoryId } = rootState['askmePublic']

    const payload = {
      type: 'askme_question',
      category_id: questionCategoryId,
      amount: rootState['donation'].donationAmount,
      currency: rootState['currencies'].currentCurrency,
      user_id: rootGetters['creator/getCreatorId'],
      answer_type: 'askmetextanswer',
    }

    if (questionText && questionText.length) {
      payload.question = questionText
    }

    const {
      paymentMethod,
      commissionCovered,
    } = rootState['payment']

    const {
      viewerEmail,
      viewerQiwiWallet,
      viewerPhone,
      viewerAlias,
    } = rootState['viewer']

    if (viewerAlias && viewerAlias.length) {
      payload.name = viewerAlias
    }

    payload.email = viewerEmail

    if (['fakeMobile', 'qiwiMyCom', 'sberPay'].includes(paymentMethod)) {
      payload.phone = paymentMethod === 'qiwiMyCom' ? viewerQiwiWallet : viewerPhone
    }

    payload.system = paymentMethod
    payload.commission_covered = commissionCovered

    if (extra && extra.paymentToken) {
      payload.extra = {
        payment_token: extra.paymentToken,
      }
    }

    return payload
  },

  async validateAskmeInvoicePayload({
    dispatch,
    commit,
    rootState,
  }) {
    const payload = await dispatch('prepareAskmeInvoicePayload')

    return api.post('/payin/invoice/askme/validate', payload)
      .then(res => {
        commit('SET_PAYLOAD_VALIDATED', true)

        return res
      })
  },

  async createAskmeInvoice({
    commit,
    dispatch,
    rootGetters,
    rootState,
  }, extra) {
    dispatch('viewer/storeViewerFields', null, { root: true })

    const payload = await dispatch('prepareAskmeInvoicePayload', extra)

    commit('payment/SAVE_PAYMENT_SETTINGS', null, { root: true })
    dispatch('donation/saveDonationAmount', null, { root: true })
    dispatch('currencies/saveCurrency', null, { root: true })

    const url = rootGetters['viewer/viewerAuthorized'] ? '/payin/invoice/askme/auth' : '/payin/invoice/askme'

    return api.post(url, payload)
      .then(({ data }) => {
        commit('SET_INVOICE_ID', data.invoice_id)
        commit('SET_INVOICE_HASH', data.hash)

        return data
      })
  },

  checkAskmeInvoice({}, {
    id,
    hash,
  }) {
    return api
      .get('/payin/invoice/askme', {
        type: 'askme_question',
        id: id,
        hash: hash,
      })
      .then(({ data }) => data)
  },
}
