import axios from 'axios'
import $ from 'jquery'

window.activityHeight = () => {
  if ($('.s-last-events-block').length > 0) {
    const $activity = $('.s-last-events-block')
    const $target_top = $('.s-last-events-top')
    const $target_bot = $('.s-last-events-bot')

    if (window.innerWidth >= 1366) {
      const height = $target_bot.offset().top + $target_bot.height() - $target_top.offset().top
      $activity.css('height', height)
    } else {
      $activity.removeAttr('style')
    }
  }
}

export const loadLegacyPage = (url, el = null, preInitCb) => {
  return axios.post(url, {
    ajax: true,
  }, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(({ data }) => {
      const html = data.html ?? data

      if (el) {
        $(el).html(html)
      } else {
        $('#page-html').html(html)
      }

      if (preInitCb) {
        preInitCb()
      }

      window.initPage()
      window.binarySelectInit()
      window.clipboardCopyInit()
      window.initMiscPageItems()
    })
}
