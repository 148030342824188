<style scoped lang="scss">
  .latest-questions-page {

  }
</style>

<template>
  <div class="latest-questions-page content-block">
    <content-block-controls/>
    <template v-if="questionsTotal">
      <div class="content-block-title">
        {{ $t('askMe.questionList.latestQuestions') }}
        <div class="content-block-addon">
          <questions-category-filter/>
        </div>
      </div>
      <div class="content-block-body">
        <questions :initial-page="page" @pageChange="onQuestionsPageChange"/>
      </div>
    </template>
    <template v-else>
      <questions-placeholder/>
    </template>
    <content-block-footer/>
  </div>
</template>

<script>

import ContentBlockFooter from '@components/AskMePage/ContentBlockFooter'
import Questions from '@components/AskMePage/Questions/Questions'
import QuestionsPlaceholder from '@components/AskMePage/Questions/QuestionsPlaceholder'
import ContentBlockControls from '@components/AskMePage/ContentBlockControls'
import QuestionsCategoryFilter from '@components/AskMePage/Questions/QuestionsCategoryFilter'

export default {
  name: 'LatestQuestionsPage',
  props: {
    page: {
      type: [Number, String],
      default: 1,
    },
  },
  components: {
    QuestionsCategoryFilter,
    ContentBlockControls,
    QuestionsPlaceholder,
    Questions,
    ContentBlockFooter,
  },
  computed: {
    ...mapState('askmePublic', ['questionsTotal', 'filterCategoryId']),
    ...mapGetters('creator', ['getCreatorName']),
  },
  methods: {
    onQuestionsPageChange(page) {
      console.log(page)

      this.$router.push({
        name: 'latest-questions-page',
        query: { p: page },
      })
    },
  },
  mounted() {
    this.$ga.view({
      category: 'AskMeQuestions',
      action: 'questions',
    })
  },
  watch: {
    page: {
      handler(page) {
        if (Number(page) === 1 && this.$route.query.p) {
          this.$router.replace({
            name: 'askme-page',
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
