import { i18n } from '@src/localization/config'

export const state = {
  creatorInfo: {},

  creatorDescription: null,

  creatorAwards: [],
  creatorLinks: [],

  creatorPageSettings: {
    pageBackgroundColor: null,
    pageBackgroundImage: null,
    pageBackgroundRepeat: false,

    headerBackgroundImage: null,
    displayAvatar: true,

    donationButtonText: null,
  },
}

export const getters = {
  isShortVariant: (state, getters, rootState, rootGetters) => {
    return !rootGetters['products/getProductsList'].length
  },

  getCreatorId: state => state.creatorInfo.id,
  getCreatorName: state => state.creatorInfo.name,
  getDonationButtonText: state => state.creatorPageSettings.donationButtonText ?? i18n.t('askMe.questionForm.askButton'),
}

export const mutations = {
  SET_CREATOR_INFO(state, { id, name, avatar, verified }) {
    state.creatorInfo = { id, name, avatar, verified }
  },

  SET_CREATOR_DESCRIPTION(state, description) {
    state.creatorDescription = description
  },

  SET_CREATOR_AWARDS(state, awards) {
    state.creatorAwards = awards
  },

  SET_CREATOR_LINKS(state, links) {
    state.creatorLinks = links
  },

  SET_CREATOR_PAGE_SETTINGS(state, settings) {
    state.creatorPageSettings = settings
  },
}

export const actions = {
  applyPageSettings({ commit, dispatch }, data) {
    commit('SET_CREATOR_PAGE_SETTINGS', {
      pageBackgroundColor: data.background_color,
      pageBackgroundImage: data.background_image_url,
      pageBackgroundRepeat: !!data.is_background_repeat_enabled,

      headerBackgroundImage: data.header_image_url,
      displayAvatar: data.is_avatar_enabled,

      donationButtonText: data.donation_send_button_text,
    })

    commit('SET_CREATOR_INFO', {
      id: data.id,
      name: data.name,
      avatar: data.avatar,
      verified: !!data.is_verified,
      online: !!data.is_online,
      isDobro: !!data.is_dobro,
    })

    commit('SET_CREATOR_DESCRIPTION', data.note)

    commit('SET_CREATOR_AWARDS', data.badges.map(badge => ({
      title: badge.text,
      text: badge.sub_text,
      type: badge.type,
      image: badge.url,
    })))

    commit('SET_CREATOR_LINKS', data.user_links)

    commit('application/SET_THEME_COLORS', {
      themeColor: '#6C71D3', // data.highlight_color,
      themeTextColor: '#ffffff', // data.highlight_text_color,
    }, { root: true })

    dispatch('currencies/setup', {
      preferredCurrency: data.preferred_currency
    }, { root: true })
  },

  fetchAskMePageSettings({ commit, dispatch }, slug) {
    return api.get(`/user/${slug}/askmepagesettings`)
      .then(async ({ data }) => {
        await dispatch('applyPageSettings', data)

        await dispatch('askmePublic/setup', {
          isAvailable: data.is_available,
          textQuestions: data.public_question.text,
        }, { root: true })

        commit('donation/SET_DONATION_SETTINGS', {
          minimalAmount: data.public_question.text.min_amounts,
        }, { root: true })

        if (!!window.append1LinkAnalytics && data && data.id) {
          window.append1LinkAnalytics(data.id)
        }

        return dispatch('donation/initDonationAmount', null, { root: true })
      })
  },
}
