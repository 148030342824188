export const state = {
  questions: {},
  topQuestionsIds: [],

  questionsTotal: 0,

  textQuestionsEnabled: false,
  textQuestionsMaxLength: 1000,
  textQuestionPrices: {},

  questionText: '',
  questionCategoryId: null,

  isAvailable: false,

  categories: {},
  filterCategoryId: 'all',
  noCategoryQuestionsNumber: 0,
}

export const getters = {
  getField,

  getQuestionsList: state => Object.values(state.questions),

  getQuestion: state => id => state.questions[id],

  topQuestions: (state, getters) => state.topQuestionsIds.map(getters.getQuestion),

  getCategoriesList: state => Object.values(state.categories),

  getCategory: state => id => state.categories[id],

  currentQuestionCategory: (state, getters) => getters.getCategory(state.questionCategoryId),
}

export const mutations = {
  updateField,

  SET_TOP_QUESTIONS_IDS(state, ids) {
    state.topQuestionsIds = ids
  },

  ADD_QUESTIONS(state, questions) {
    state.questions = {
      ...state.questions,
      ..._.keyBy(questions, 'id'),
    }
  },

  SET_QUESTIONS_TOTAL(state, total) {
    state.questionsTotal = total
  },

  SET_ASKME_SETTINGS(state, {
    isAvailable,
    textQuestionsEnabled,
    textQuestionsMaxLength,
    textQuestionPrices,
  }) {
    state.isAvailable = isAvailable
    state.textQuestionsEnabled = textQuestionsEnabled
    state.textQuestionsMaxLength = textQuestionsMaxLength
    state.textQuestionPrices = textQuestionPrices
  },

  SET_CATEGORIES(state, categories) {
    state.categories = _.keyBy(categories, 'id')
  },

  SET_NO_CATEGORY_QUESTIONS_NUMBER(state, questionsNumber) {
    state.noCategoryQuestionsNumber = Number(questionsNumber)
  },
}

const questionColors = [
  '#D66E34',
  '#4A2DDA',
  '#00579F',
  '#8B3FFD',
  '#59A840',
  '#D45124',
  '#DE6489',
  '#20BBA1',
  '#F8B301',
  '#0099BB',
  '#1D90FF',
  '#E542FF',
  '#A36C59',
  '#8BA259',
  '#002F47',
  '#7008AF',
]

export const actions = {
  setup({ commit }, { textQuestions, isAvailable }) {
    commit('SET_ASKME_SETTINGS', {
      isAvailable: isAvailable,
      textQuestionsEnabled: textQuestions.is_enabled,
      textQuestionsMaxLength: textQuestions.max_length,
      textQuestionPrices: textQuestions.min_amounts,
    })
  },

  fetchTopQuestions({ commit, dispatch }) {
    return dispatch('fetchQuestions', { perPage: 3, sort: ['cost,desc'] })
      .then(({ questions }) => {
        commit('SET_TOP_QUESTIONS_IDS', questions)
      })
  },

  fetchQuestions({ commit, rootGetters, state }, { page = 1, perPage = 10, sort } = {}) {
    return api.get('/askmequestion/list', {
      user_id: rootGetters['creator/getCreatorId'],
      category_id: state.filterCategoryId === 'all' ? undefined : (state.filterCategoryId ?? 0),
      page,
      per_page: perPage,
      sort,
    })
      .then(({ data, meta }) => {
        commit('ADD_QUESTIONS', data.map(question => {
          const inquirerName = question.inquirer.name ?? `Анонимный Вопрошатель ${question.id}`

          const color = questionColors[(1337 + _.sum([...inquirerName].map(l => l.charCodeAt(0)))) % questionColors.length]

          const answerContent = _.get(question, 'answer.content', '')

          return {
            id: question.id,
            categoryId: question.category_id,
            text: question.content && question.content.length ? question.content : null,
            inquirer: {
              name: inquirerName,
              shortName: inquirerName.split(' ').map(p => p[0]).join('').slice(0, 2),
              color,
              avatar: question.inquirer.avatar,
            },
            answer: answerContent.length ? answerContent : null,
            amount: question.cost,
            currency: question.cost_currency,
            date: question.created_at_ts ? dates.formatTs(question.created_at_ts, 'dd MMMM yyyy', rootGetters['application/appLocale']) : '',
          }
        }))

        commit('SET_QUESTIONS_TOTAL', meta.total)

        return {
          questions: data.map(q => q.id),
          pagination: {
            page: meta.current_page,
            pagesCount: meta.last_page,
            perPage: meta.per_page,
            total: meta.total,
          },
        }
      })
      .catch(() => {
        return false
      })
  },

  fetchCategories({ commit, rootGetters }) {
    return api.get('/askmequestioncategory/list', {
      user_id: rootGetters['creator/getCreatorId'],
    })
      .then(({ data, meta }) => {
        commit('SET_CATEGORIES', data.map(category => {
          return {
            id: category.id,
            title: category.title,
            color: `#${category.color}`,
            description: category.description,
            minimalAmounts: category.min_amounts,
            questionsNumber: category.questions_num,
          }
        }))

        commit('SET_NO_CATEGORY_QUESTIONS_NUMBER', meta.no_category_questions_num)
      })
  },
}
