// Register global components

import Vue from 'vue'

import { VPopover } from 'v-tooltip'

Vue.component('v-popover', VPopover)

// Grid
import Container from '@components/_BaseComponents/Grid/Container'

Vue.component('container', Container)

// Form
import FormGroup from '@components/_BaseComponents/Form/FormGroup'
import BaseInput from '@components/_BaseComponents/Form/BaseInput'
import BaseRadio from '@components/_BaseComponents/Form/BaseRadio'
import AdvancedInput from '@components/_BaseComponents/Form/AdvancedInput'
import BaseTextarea from '@components/_BaseComponents/Form/BaseTextarea'
import BaseSelect from '@components/_BaseComponents/Form/BaseSelect'
import DropdownSelect from '@components/_BaseComponents/Select/DropdownSelect'

Vue.component('form-group', FormGroup)
Vue.component('base-input', BaseInput)
Vue.component('base-radio', BaseRadio)
Vue.component('advanced-input', AdvancedInput)
Vue.component('base-textarea', BaseTextarea)
Vue.component('base-select', BaseSelect)
Vue.component('dropdown-select', DropdownSelect)

import DateTimePicker from '@components/_BaseComponents/DateTimePicker/DateTimePicker'

Vue.component('date-time-picker', DateTimePicker)

// Misc
import Icon from '@components/_BaseComponents/Icon'
import BaseButton from '@components/_BaseComponents/BaseButton'
import BaseSwitch from '@components/_BaseComponents/BaseSwitch'
import BaseRange from '@components/_BaseComponents/BaseRange'
import Modal from '@components/_BaseComponents/Modal'
import Loader from '@components/_BaseComponents/Loader'
import InfoBox from '@components/_BaseComponents/InfoBox'
import HelperBox from '@components/_BaseComponents/HelperBox'
import Tabs from '@components/_BaseComponents/Tabs/Tabs'
import Pagination from '@components/_BaseComponents/Pagination'

Vue.component('btn', BaseButton)
Vue.component('icon', Icon)
Vue.component('base-switch', BaseSwitch)
Vue.component('base-range', BaseRange)
Vue.component('tabs', Tabs)

Vue.component('modal', Modal)

Vue.component('info-box', InfoBox)
Vue.component('helper-box', HelperBox)
Vue.component('pagination', Pagination)

Vue.component('loader', Loader)

import ProgressCircle from '@components/_BaseComponents/ProgressCircle'

Vue.component('progress-circle', ProgressCircle)

import AmountLink from '@components/AskMePage/AmountLink'

Vue.component('amount-link', AmountLink)
