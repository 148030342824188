<style lang="scss" scoped>
  .advanced-input {
    position: relative;

    .icon {
      position: absolute;
      top: 15px;
      font-size: 15px;
      cursor: text;
      pointer-events: none;

      &.icon-left {
        left: 15px;
      }

      &.icon-right {
        right: 15px;
      }
    }

    .base-input {
      &.padded-left {
        padding-left: 40px;
      }

      &.padded-right {
        padding-right: 40px;
      }
    }
  }
</style>

<template>
  <div class="advanced-input">
    <base-input
      ref="input"
      v-bind="$attrs"
      v-on="$listeners"
      :class="inputClasses"/>
    <icon
      v-if="iconLeft"
      class="icon-left"
      :name="iconLeft" theme gradient/>
    <icon
      v-if="iconRight"
      class="icon-right"
      :name="iconRight" theme gradient/>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
  },
  computed: {
    inputClasses() {
      return {
        'padded-left': !!this.iconLeft,
        'padded-right': !!this.iconRight,
      }
    },
  },
}
</script>
