<style scoped lang="scss">
  .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(max, md) {
      width: 100%;
      height: auto;
    }

    .form-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    .platform-text {
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-gray;
    }

    .login-section {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      .login-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;

        .login-button {
          width: 56px;
          height: 56px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 22px;
          margin: 0 10px 20px;

          &.social-youtube-official {
            border: 1px solid #D3DCE2;
          }
        }
      }
    }

    .terms-and-policy {
      max-width: 560px;
      margin-top: 20px;
      text-align: center;
      @include padding-x(10px);
    }

    .loading-container {
      @include position-all(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(white, .5);
    }

    .info-box-closed {
      align-self: stretch;
      margin: 20px;

      .closed-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 8px;
      }
    }
  }
</style>

<template>
  <div class="auth-form">
    <div v-if="loadingState.auth" class="loading-container">
      <loader size="lg"/>
    </div>
    <info-box variant="new-error" class="info-box-closed">
      <div class="closed-title">{{ $t('closed.title') }}.</div>
      <div>{{ $t('closed.text') }}.</div>
    </info-box>
    <div class="form-title">{{ $t('global.login.authTitle') }}</div>
    <div class="platform-text">{{ $t('global.login.choosePlatform') }}</div>
    <div class="login-section">
      <div class="login-buttons">
        <a
          v-for="(link, index) in socialLinks"
          :key="index"
          :href="link.url"
          class="login-button"
          :class="link.classes"
          @click="() => onAuthLinkClick(link.platform)">
          <icon :name="link.icon"/>
        </a>
      </div>
    </div>
    <terms-and-policy/>
  </div>
</template>

<script>

import TermsAndPolicy from './TermsAndPolicy'

export default {
  name: 'AuthForm',
  components: { TermsAndPolicy },
  data() {
    return {
      loadingState: {
        auth: false,
      },
    }
  },
  computed: {
    ...mapGetters('creator', ['getCreatorName']),
    ...mapState('viewer', ['isLandingPage']),

    socialLinks() {
      const redirectUrl = `${window.location.origin}/dashboard`

      return [
        {
          url: 'twitch',
          platform: 'twitch',
        },
        {
          url: 'youtube',
          platform: 'youtube-official',
        },
        {
          url: 'vk-user',
          platform: 'vk',
        },
        {
          url: 'ok',
          platform: 'ok',
        },
        // {
        //   url: 'facebook',
        //   platform: 'facebook',
        // },
        {
          url: 'dailymotion',
          platform: 'dailymotion',
        },
        {
          url: 'discord',
          platform: 'discord',
        },
        // {
        //   url: 'trovo',
        //   platform: 'trovo',
        // },
        // {
        //   url: 'tiktok',
        //   platform: 'tiktok',
        // },
        {
          url: 'telegram',
          platform: 'telegram',
        },
        // {
        //   url: 'goodgame',
        //   platform: 'goodgame',
        // },
        // {
        //   url: 'wasd',
        //   platform: 'wasd',
        // },
      ].map(link => ({
        url: `/auth/${link.url}?redirectTo=${redirectUrl}`,
        icon: `social-${link.platform}`,
        classes: [`social-${link.platform}`],
        platform: link.platform,
      }))
    },
  },
  methods: {
    onAuthLinkClick(platform = '') {
      switch (platform) {
        case 'youtube-official':
          this.$ga.event('action_bt_YT')
          break
        case 'twitch':
          this.$ga.event('action_bt_Twitch')
          break
        case 'vk':
          this.$ga.event('action_bt_VK')
          break
        case 'telegram':
          this.$ga.event('action_bt_TG')
          break
        case 'ok':
          this.$ga.event('action_bt_OK')
          break
        case 'dailymotion':
          this.$ga.event('action_bt_DM')
          break
        case 'discord':
          this.$ga.event('action_bt_DS')
          break
      }

      this.loadingState.auth = true
    },
  },
}
</script>
