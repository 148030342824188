<style scoped lang="scss">
  .ask-me-page {
    .layout {

      &__header {
        margin-bottom: 20px;

        @media (min-width: 720px) {
          display: flex;
          justify-content: space-between;
        }
      }

      &__stats {
        margin-top: 20px;

        @media (min-width: 720px) {
          margin-top: 0;
          margin-left: 20px;
        }
      }

      &__title {
        margin-bottom: 10px !important;
      }

      &__description {
        color: $color-gray;
        font-size: 14px;
        line-height: 1.5;
      }

    }

    .page {
      padding-bottom: 40px;
    }
  }
</style>

<template>
  <div class="ask-me-page">
    <div class="layout__header">
      <div>
        <Title class="layout__title">{{ pageTitle }}</Title>

        <!-- <p class="layout__description">{{ $t('askMe.description') }}</p> -->
      </div>
      <div class="layout__stats">
        <stats/>
      </div>
    </div>

    <div class="page">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Title from '@components/_BaseComponents/Title'

import Stats from '@components/Dashboard/AskMe/Stats'

export default {
  name: 'AskMePage',

  components: {
    Title,
    Stats,
  },
  metaInfo() {
    return { title: this.$t('dashboard.pages.askMe.askMe') }
  },

  data() {
    return {}
  },

  computed: {
    pageTitle() {
      if (this.$route.path.includes('questions')) return this.$t(`dashboard.pages.askMe.questions`)
      if (this.$route.path.includes('archive')) return this.$t(`dashboard.pages.askMe.archive`)
      if (this.$route.path.includes('categories')) return this.$t(`dashboard.pages.askMe.categories`)
      if (this.$route.path.includes('settings')) return this.$t(`dashboard.pages.askMe.settings`)

      return this.$t('askMe.title')
    },
  },
}
</script>

