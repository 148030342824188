import storage from '@utils/storage'

const trackID = '3256732'

window._tmr = window._tmr || []

const debug = storage.getItem('da.donation.debug.tmr')

export default {
  install: Vue => {
    Vue.tmr = {
      goal({ goal, value }, params = {}) {
        const payload = {
          id: trackID,
          type: 'reachGoal',
          goal,
          value,
          params,
        }

        if (window._tmr) {
          window._tmr.push(payload)
        }

        if (debug) {
          console.log('[TMR]', payload)
        }
      },
    }
    Vue.prototype.$tmr = Vue.tmr
  },
}
