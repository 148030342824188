import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/// Global components & directive
import './registerComponents'
import './directives'
import './setup'
import './plugins/CookieBanner'

import store from '@state/store'
import { i18n } from '@src/localization/config'

import createRouter from '@router'

import App from './App'

// Front ENV & Sentry setup
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import frontConfig from '@services/frontConfig'



function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


const preloadDashboardDependencies = async () => {
  if (!window.location.pathname.includes('/dashboard')) return 

  try {
    const { data } = await axios.post('/dashboard/old-dependencies', {
      ajax: true,
    }, { 
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })

    const html = data.html ?? data

    // document.getElementById('legacy-dependencies').innerHTML = html

    await $('#legacy-dependencies').html(html)
  } catch(err) {
    // ...
  }
}

const setupApp = async () => {
  await frontConfig.fetchConfig()

  const dsn = frontConfig.getSentryDsn('askme')

  if (PRODUCTION_MODE) {
    if (dsn) {
      Sentry.init({
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        release: `vue-askme@${PACKAGE_VERSION}`,
        environment: process.env.NODE_ENV,
        dsn,
        autoSessionTracking: false,
      })
    }
  }

  new Vue({
    router: createRouter(),
    store,
    i18n,
    render: h => h(App),
  }).$mount('#fantalks-app')
}


(async () => {
  await preloadDashboardDependencies()
  await sleep(500)
  await setupApp()
})()