<style scoped lang="scss">
  .question-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #191919;
    text-transform: uppercase;
  }

  .question-modal-content {
    width: 690px;
    margin: 0 20px 20px 20px;
  }
</style>

<template>
  <modal v-model="modalOpened">
    <template slot="header-content">
      <div class="question-modal-title">
        {{$t('askMe.questionList.topQuestions')}}
      </div>
    </template>
    <div slot="body" class="question-modal-content">
      <question
        :id="questionId"
        :position="position"
        top full/>
    </div>
  </modal>
</template>

<script>

import Question from '@components/AskMePage/Questions/Question'

export default {
  name: 'QuestionModal',
  components: { Question },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    questionId: {
      type: [String, Number],
      default: null,
    },
    position: {
      type: [String, Number],
      default: 0,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalOpened: false,
    }
  },
  computed: {},
  methods: {},
  watch: {
    value: {
      handler(value) {
        this.modalOpened = value
      },
      immediate: true,
    },
    modalOpened(value) {
      this.$emit('input', value)
    },
  },
}
</script>
