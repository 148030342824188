var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"list-item",class:{ active: null === _vm.value || undefined === _vm.value },on:{"click":() => _vm.changeCategory(null)}},[_c('span',[_vm._v(_vm._s(_vm.$t('askMe.questions.allCategories')))])]),_c('button',{staticClass:"list-item",class:{
      active: '0' === `${ _vm.value }`,
      disabled: _vm.totalCount < 1,
    },on:{"click":() => _vm.changeCategory(0)}},[_c('span',[_vm._v(_vm._s(_vm.$t('askMe.questions.uncategorized')))]),_c('span',{staticClass:"list-num"},[_vm._v(_vm._s(_vm.totalCount))])]),_vm._l((_vm.list),function(item){return _c('button',{key:item.id,staticClass:"list-item",class:{
      active: (`${ item.id }` === `${ _vm.value }`),
      disabled: item.questions_num < 1,
    },on:{"click":() => _vm.changeCategory(item.id)}},[(item.color)?_c('span',{staticClass:"list-color",style:({ backgroundColor: `#${ item.color }` })}):_vm._e(),_c('span',[_vm._v(_vm._s(`${ item.id }` === '0' ? _vm.$t('askMe.questions.uncategorized') : item.title))]),(item.questions_num)?_c('span',{staticClass:"list-num"},[_vm._v(_vm._s(item.questions_num))]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }