/* eslint-disable */
import {
  format,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz'
import fromUnixTime from 'date-fns/fromUnixTime'
import getDay from 'date-fns/getDay'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import startOfDay from 'date-fns/startOfDay'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'

import ru_RU from 'date-fns/locale/ru'
import en_US from 'date-fns/locale/en-US'

const locales = {
  ru_RU,
  en_US,
}

const DEFAULT_TIME_ZONE = 'Europe/Moscow'
const DEFAULT_TIME_FORMAT = 'dd.MM.yyyy HH:mm'

const parseDate = (_date) => ({
  day: _date.getDate(),
  month: _date.getMonth(),
  year: _date.getFullYear(),

  hour: _date.getHours(),
  minute: _date.getMinutes(),
  second: _date.getSeconds(),
})

const parseTs = (_ts) => parseDate(fromUnixTime(_ts))

export default {
  // Const
  DEFAULT_TIME_ZONE,
  DEFAULT_TIME_FORMAT,

  // Functions
  isAfter,
  isBefore,
  utcToZonedTime,
  zonedTimeToUtc,
  startOfDay,
  fromUnixTime,
  getDaysInMonth,
  getWeekDay: (_date) => getDay(_date),
  timestamp: (_date = null) => +(_date ? new Date(_date) : Date.now()) / 1000,
  parseTs,
  getTimeLeft: (tsStart, tsEnd) => {
    const diff = {
      sec: tsEnd - tsStart,
    }
    const seconds = diff.sec % 60

    diff.min = (diff.sec - seconds) / 60
    const minutes = diff.min % 60

    diff.hours = (diff.min - minutes) / 60
    const hours = diff.hours % 24

    const days = (diff.hours - hours) / 24

    return {
      seconds,
      minutes,
      hours,
      days,
    }
  },
  getDifference: (tsStart, tsEnd) => {
    const end = fromUnixTime(tsStart)
    const start = fromUnixTime(tsEnd)

    return {
      second: differenceInSeconds(start, end),
      minute: differenceInMinutes(start, end),
      hour: differenceInHours(start, end),
      day: differenceInDays(start, end),
      week: differenceInWeeks(start, end),
      month: differenceInMonths(start, end),
      year: differenceInYears(start, end),
    }
  },
  // Formatting
  unixToFormat: (_ts, _format = DEFAULT_TIME_FORMAT) => format(fromUnixTime(_ts), _format),
  format: (_date, _format, _locale = 'en_US') => format(_date, _format, { locale: locales[_locale] }),
  formatTs: (_ts, _format, _locale = 'en_US') => format(fromUnixTime(_ts), _format, { locale: locales[_locale] }),
}
