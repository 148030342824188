<style scoped lang="scss">
  .ask-me-tutorial {
    .content-block-title {
      position: relative;
      display: flex;
      align-items: center;

      .close-button {
        margin-left: auto;
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        color: #838383;

        @include media(max, sm) {
          display: none;
        }
      }
    }

    .tutorial-steps {
      display: flex;
      align-items: flex-start;

      @include media(max, md) {
        flex-direction: column;
      }

      .arrow-icon {
        flex: 0 0 auto;
        font-size: 58px;
        margin-top: 18px;
        @include margin-x(43px);

        @include media(max, xl) {
          @include margin-x(30px);
        }

        @include media(max, lg) {
          @include margin-x(43px);
        }

        @include media(max, md) {
          display: none;
        }
      }

      .tutorial-step {
        width: 100px;
        flex: 0 0 auto;
        position: relative;

        @include media(max, md) {
          display: flex;
          align-items: center;
          width: auto;
        }

        .step-number {
          flex: 0 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 24px;
          line-height: 30px;
        }

        .step-image {
          flex: 0 0 auto;

          img {
            width: 94px;
            height: 94px;

            @include media(max, xl) {
              width: 80px;
              height: 80px;
            }

            @include media(max, lg) {
              width: 94px;
              height: 94px;
            }

            @include media(max, md) {
              width: 60px;
              height: 60px;
            }
          }
        }

        .step-text {
          width: 180px;
          margin-top: 10px;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;

          @include media(max, xl) {
            width: 160px;
          }

          @include media(max, lg) {
            width: 180px;
          }

          @include media(max, md) {
            margin-top: 0;
            margin-left: 10px;
            width: auto;
          }
        }
      }

      @include media(max, md) {
        .tutorial-step + .tutorial-step {
          margin-top: 15px;
        }
      }
    }
  }
</style>

<template>
  <div class="ask-me-tutorial content-block">
    <div class="content-block-title">
      {{ $t('askMe.tutorial.title') }}
      <div class="close-button" @click="closeTutorial">
        <icon name="cross"/>
      </div>
    </div>
    <div class="content-block-body">
      <div class="tutorial-steps">
        <div class="tutorial-step">
          <div class="step-number">1.</div>
          <div class="step-image">
            <img :src="$asAppAsset('/images/Step1.svg')"/>
          </div>
          <div class="step-text">
            {{ $t('askMe.tutorial.step1', { name: getCreatorName }) }}
          </div>
        </div>
        <icon v-if="!($appIsSM || $appIsMD)" name="curve-arrow-2" class="arrow-icon"/>
        <div class="tutorial-step">
          <div class="step-number">2.</div>
          <div class="step-image">
            <img :src="$asAppAsset('/images/Step2.svg')"/>
          </div>
          <div class="step-text">
            {{ $t('askMe.tutorial.step2', { name: getCreatorName }) }}
          </div>
        </div>
        <icon v-if="!($appIsSM || $appIsMD)" name="curve-arrow" class="arrow-icon"/>
        <div class="tutorial-step">
          <div class="step-number">3.</div>
          <div class="step-image">
            <img :src="$asAppAsset('/images/Step3.svg')"/>
          </div>
          <div class="step-text">
            {{ $t('askMe.tutorial.step3', { name: getCreatorName }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AskMeTutorial',
  computed: {
    ...mapGetters('creator', ['getCreatorName']),
  },
  methods: {
    closeTutorial() {
      this.$emit('close')
    },
  },
}
</script>
